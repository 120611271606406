// ? hooks
import { useEffect } from 'react'
import { Add } from '@mui/icons-material'
import useAsyncWrapper from '@/hooks/useAsyncWrapper'
import { Grid, Button, TableContainer, Table, TableHead, TableBody, Box } from '@mui/material'

// ? store
import { countryStore } from '@/store/countryStore'

// ? components
import TablePaginationCustom from '@/components/UI/TablePaginationCustom/TablePaginationCustom'
import CreateDeliveryCountryModal from './modals/CreateDeliveryCountryModal'
import EditCountryModal from './modals/EditCountryModal'
import DeleteCountryModal from './modals/DeleteCountryModal'

// ? icons
import DeliveryCountryTabelHeader from './DeliveryCountryTabelHeader'
import { deliveryCountryStore } from '@/store/deliveryCountryStore'
import { IDeliveryCountry } from '@/interfaces/country'
import DeliveryCountryTabelRow from './DeliveryCountryTabelRow'
import LoadingTableData from '@/components/UI/LoadingTableData/LoadingTableData'

const DeliveryCountryTabel = () => {
  const {
    getAllCountries,
    countries,
    countryMeta,
    createDeliveryCountry,
    editDeliveryCountry,
    deleteDeliveryCountry,
    isLoading,
  } = deliveryCountryStore()

  const getCountriesApiHandler = useAsyncWrapper(getAllCountries, 'getCountriesApiHandler')

  const handleOpenCreateModal = () => {
    createDeliveryCountry.handleOpenModal()
  }

  useEffect(() => {
    const fetchData = async () => {
      await getCountriesApiHandler([countryMeta])
    }

    fetchData()
  }, [])

  return (
    <Grid container spacing={1} sx={{ pr: 1 }}>
      {createDeliveryCountry.isOpenModal && <CreateDeliveryCountryModal />}
      {editDeliveryCountry.isOpenModal && <EditCountryModal />}
      {deleteDeliveryCountry.isOpenModal && <DeleteCountryModal />}

      <Grid item xs={12}>
        <Box>
          <Button
            variant="contained"
            color="primary"
            size="small"
            endIcon={<Add className="addIcon" />}
            onClick={handleOpenCreateModal}>
            Створити нову країну
          </Button>
        </Box>
        {countries?.length > 0 ? (
          <TableContainer>
            <Table
              stickyHeader
              sx={{
                maxHeight: 'calc(100vh - 250px)',
                width: '100%',
                maxWidth: '100%',
                overflow: 'scroll',
                mt: 1,
              }}>
              <TableHead>
                <DeliveryCountryTabelHeader />
              </TableHead>
              <TableBody>
                {!isLoading ? (
                  countries.map((country: IDeliveryCountry) => (
                    <DeliveryCountryTabelRow country={country} key={country.country_id} />
                  ))
                ) : (
                  <LoadingTableData columns={7} rows={5} />
                )}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: 'calc(100vh - 250px)',
              gap: 2,
            }}>
            <p>Дані відсутні</p>
            <Button
              variant="contained"
              color="primary"
              size="small"
              className="createUserBtn"
              endIcon={<Add className="addIcon" />}
              onClick={handleOpenCreateModal}>
              Створити нову країну
            </Button>
          </Box>
        )}
        {countries && countries?.length !== 0 && (
          <TablePaginationCustom getData={getCountriesApiHandler} meta={countryMeta} />
        )}
      </Grid>
    </Grid>
  )
}
export default DeliveryCountryTabel
