import { create } from 'zustand'
import { REQ } from '@/api'
import { IMeta } from '@/interfaces/general'
import { IDepartment, IDepartmentCreateDTO } from '@/interfaces/department'

const { department_api } = REQ

interface IStore {
  departments: IDepartment[]
  departmentMeta: IMeta
  isLoading: boolean
  createDepartment: {
    isOpenModal: boolean
    handleOpenModal: () => void
    handleCloseModal: () => void
    createDepartmentApiHandler: (body: IDepartmentCreateDTO) => Promise<any>
  }
  editDepartment: {
    isOpenModal: boolean
    editedItem: IDepartment | null
    handleOpenModal: (item: IDepartment) => void
    handleCloseModal: () => void
    editDepartmentApiHandler: (id: string, body: IDepartmentCreateDTO) => Promise<any>
  }
  deleteDepartment: {
    isOpenModal: boolean
    deletedItem: IDepartment | null
    handleOpenModal: (item: IDepartment) => void
    handleCloseModal: () => void
    deleteDepartmentApiHandler: (id: string) => Promise<any>
  }
  getAllDepartments: () => Promise<any>
}

export const departmentStore = create<IStore>((set) => ({
  departments: [],
  isLoading: false,
  departmentMeta: {
    count: 0,
    limit: 0,
    page: 0,
    total: 10,
  },
  createDepartment: {
    isOpenModal: false,
    handleOpenModal: () => set((state) => ({ createDepartment: { ...state.createDepartment, isOpenModal: true } })),
    handleCloseModal: () => set((state) => ({ createDepartment: { ...state.createDepartment, isOpenModal: false } })),
    createDepartmentApiHandler: async (body) => {
      const { data, status } = await department_api.createDepartment(body)

      if (status !== 201) {
        return false
      }

      set((state) => ({ createDepartment: { ...state.createDepartment, isOpenModal: false } }))

      return true
    },
  },
  deleteDepartment: {
    isOpenModal: false,
    deletedItem: null,
    handleOpenModal: (item) =>
      set((state) => ({ deleteDepartment: { ...state.deleteDepartment, isOpenModal: true, deletedItem: item } })),
    handleCloseModal: () =>
      set((state) => ({ deleteDepartment: { ...state.deleteDepartment, isOpenModal: false, deletedItem: null } })),
    deleteDepartmentApiHandler: async (id) => {
      const { data, status } = await department_api.deleteDepartment(id)

      if (status !== 200) {
        return false
      }

      set((state) => ({ deleteDepartment: { ...state.deleteDepartment, isOpenModal: false, deletedItem: null } }))
      return true
    },
  },

  editDepartment: {
    isOpenModal: false,
    editedItem: null,
    handleOpenModal: (item) =>
      set((state) => ({ editDepartment: { ...state.editDepartment, isOpenModal: true, editedItem: item } })),
    handleCloseModal: () =>
      set((state) => ({ editDepartment: { ...state.editDepartment, isOpenModal: false, editedItem: null } })),
    editDepartmentApiHandler: async (id, body) => {
      const { data, status } = await department_api.editDepartment(id, body)

      if (status !== 200) {
        return false
      }

      set((state) => ({ editDepartment: { ...state.editDepartment, isOpenModal: false, editedItem: null } }))
      return true
    },
  },
  getAllDepartments: async () => {
    set({ isLoading: true })
    const { data, status } = await department_api.getAllDepartments()

    if (status !== 200) {
      set({ isLoading: false })
      return false
    }

    set({ departments: data.departments, isLoading: false })
    return true
  },
}))
