import TownTabel from '@/components/Tabels/TownTabel/TownTabel'
import TravelWrapper from '@/components/Wrappers/TravelWrapper'
import { Typography } from '@mui/material'

const Town = () => {
  return (
    <TravelWrapper>
      {true ? (
        <TownTabel />
      ) : (
        <Typography variant="h4" color="error">
          You don&apos;t have permission to view Town
        </Typography>
      )}
    </TravelWrapper>
  )
}

export default Town
