// ? hooks
import { useEffect } from 'react'
import { Add } from '@mui/icons-material'
import useAsyncWrapper from '@/hooks/useAsyncWrapper'
import { Grid, Button, TableContainer, Table, TableHead, TableBody, Box } from '@mui/material'

// ? components
import TablePaginationCustom from '@/components/UI/TablePaginationCustom/TablePaginationCustom'
import CreateDeliveryDriverModal from './modals/CreateDeliveryDriverModal'
import EditDriverModal from './modals/EditDriverModal'
import DeleteDriverModal from './modals/DeleteDriverModal'

// ? icons
import DeliveryDriverTabelHeader from './DeliveryDriverTabelHeader'

import DeliveryDriverTabelRow from './DeliveryDriverTabelRow'
import LoadingTableData from '@/components/UI/LoadingTableData/LoadingTableData'
import { deliveryDriverStore } from '@/store/deliveryDriverStore'
import { IDeliveryDriver } from '@/interfaces/dirvers'

const DeliverDriverTabel = () => {
  const {
    getAllDrivers,
    drivers,
    countryMeta,
    createDeliveryDriver,
    editDeliveryDriver,
    deleteDeliveryDriver,
    isLoading,
  } = deliveryDriverStore()

  const getDriversApiHandler = useAsyncWrapper(getAllDrivers, 'getDriversApiHandler')

  const handleOpenCreateModal = () => {
    createDeliveryDriver.handleOpenModal()
  }

  useEffect(() => {
    const fetchData = async () => {
      await getDriversApiHandler([countryMeta])
    }

    fetchData()
  }, [])

  return (
    <Grid container spacing={1} sx={{ pr: 1 }}>
      {createDeliveryDriver.isOpenModal && <CreateDeliveryDriverModal />}
      {editDeliveryDriver.isOpenModal && <EditDriverModal />}
      {deleteDeliveryDriver.isOpenModal && <DeleteDriverModal />}
      <Grid item xs={12}>
        <Box>
          <Button
            variant="contained"
            color="primary"
            size="small"
            endIcon={<Add className="addIcon" />}
            onClick={handleOpenCreateModal}>
            Створити нового водія
          </Button>
        </Box>
        {drivers?.length > 0 ? (
          <TableContainer>
            <Table
              stickyHeader
              sx={{
                maxHeight: 'calc(100vh - 250px)',
                width: '100%',
                maxWidth: '100%',
                overflow: 'scroll',
                mt: 1,
              }}>
              <TableHead>
                <DeliveryDriverTabelHeader />
              </TableHead>
              <TableBody>
                {!isLoading ? (
                  drivers.map((driver: IDeliveryDriver) => (
                    <DeliveryDriverTabelRow driver={driver} key={driver.driver_id} />
                  ))
                ) : (
                  <LoadingTableData columns={3} rows={5} />
                )}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: 'calc(100vh - 250px)',
              gap: 2,
            }}>
            <p>Дані відсутні</p>
            <Button
              variant="contained"
              color="primary"
              size="small"
              className="createUserBtn"
              endIcon={<Add className="addIcon" />}
              onClick={handleOpenCreateModal}>
              Створити нового водія
            </Button>
          </Box>
        )}
        {drivers && drivers?.length !== 0 && (
          <TablePaginationCustom getData={getDriversApiHandler} meta={countryMeta} />
        )}
      </Grid>
    </Grid>
  )
}
export default DeliverDriverTabel
