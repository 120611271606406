interface IPrefixes {
  COUNTRY: string
  DELIVERY_COUNTRY: string
  ADMINS: string
  TOWN: string
  ROUTES: string
  DEPARTMENT: string
  DRIVER: string
  CARS: string
  CONSULTATIONS: string
  NOTIFICATIONS: string
  TRIPS: string
  STOPS: string
  PROMOTIONS: string
  PROMOCODES: string
  DELIVERY_DRIVERS: string
}

export const PREFIXES: IPrefixes = {
  COUNTRY: 'admin/travel/countries',
  ADMINS: 'admin',
  TOWN: 'admin/travel/cities',
  DEPARTMENT: 'admin/delivery/departments',
  DELIVERY_COUNTRY: 'admin/delivery/countries',
  ROUTES: 'admin/delivery/routes',
  STOPS: 'admin/travel/stops',
  DRIVER: 'admin/travel/drivers',
  CARS: 'admin/travel/cars',
  TRIPS: 'trips',
  CONSULTATIONS: 'consultation',
  NOTIFICATIONS: 'admin-notification',
  PROMOTIONS: 'admin/services/promotions',
  PROMOCODES: 'admin/services/promocodes',
  DELIVERY_DRIVERS: 'admin/delivery/drivers',
}
