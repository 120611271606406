// ? hooks
import { useState } from 'react'
import useAsyncWrapper from '@/hooks/useAsyncWrapper'
import useValidation from '@/hooks/useValidation'

// ? stores
import { messageStore } from '@/store/store'

// ? interfaces
import { Icountry, IDeliveryCountry } from '@/interfaces/country'

// ? validations
import { COUNTRY_VALIDATION } from '@/validation/country'

// ? utils
import { inputsChanger } from '@/utils/inputsChanger'

// ? components
import { Button, Box, TextField } from '@mui/material'
import { LoadingButton } from '@mui/lab'

import ModalContainer from '@/components/UI/Modal/ModalContainer'
import { deliveryCountryStore } from '@/store/deliveryCountryStore'

const EditCountryModal = () => {
  const [formValues, setFormValues] = useState<Partial<IDeliveryCountry>>({})
  const [isLoading, setIsLoading] = useState(false)

  const { addMessage } = messageStore()
  const { editDeliveryCountry, getAllCountries, countryMeta } = deliveryCountryStore()
  const { isOpenModal, handleCloseModal, editedItem } = editDeliveryCountry

  // ? changers
  const inputChangeHandler = inputsChanger('input', formValues, setFormValues)

  // ? api handlers
  const editCountryApiHandler = useAsyncWrapper(
    editDeliveryCountry.editDeliveryCountryApiHandler,
    'editCountryApiHandler'
  )

  const getCountriesApiHandler = useAsyncWrapper(getAllCountries, 'getCountriesApiHandler')

  const editCountryValidation = useValidation(COUNTRY_VALIDATION.EDIT)

  const handleClose = () => {
    setFormValues({})
    handleCloseModal()
  }

  const editCountryHandler = async () => {
    setIsLoading(true)
    if (!editCountryValidation(formValues)) {
      setIsLoading(false)
      return
    }

    const { error: editCountryError } = await editCountryApiHandler([editedItem?.country_id, formValues])

    if (editCountryError) {
      setIsLoading(false)
      return
    }

    const { error: getCountriesError } = await getCountriesApiHandler([countryMeta])

    if (getCountriesError) {
      setIsLoading(false)
      return
    }

    addMessage({
      severity: 'success',
      message: 'Успішно відредаговано',
      isOpen: true,
    })

    setIsLoading(false)
    handleClose()
  }

  return (
    <ModalContainer width={800} isOpen={isOpenModal} handleClose={handleClose} title={'Редагування Країни'}>
      <TextField
        name="country_name_en"
        onChange={inputChangeHandler}
        fullWidth
        value={formValues.country_name_en || editedItem?.country_name_en}
        label="Назва країни англійською"
        variant="standard"
      />
      <TextField
        name="country_name_ua"
        onChange={inputChangeHandler}
        fullWidth
        label="Назва країни українською"
        value={formValues.country_name_ua || editedItem?.country_name_ua}
        variant="standard"
      />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          mt: 1,
        }}>
        <LoadingButton
          loading={isLoading}
          color="primary"
          variant="contained"
          size="small"
          onClick={editCountryHandler}>
          Відредагувати
        </LoadingButton>
        <Button onClick={handleClose} color="error" variant="contained" size="small" sx={{ ml: 1 }}>
          Відмінити
        </Button>
      </Box>
    </ModalContainer>
  )
}

export default EditCountryModal
