import { create } from 'zustand'
import { REQ } from '@/api'
import { IMeta } from '@/interfaces/general'
import { IDeliveryCountry } from '@/interfaces/country'

const { delivery_country_api } = REQ

interface IStore {
  countries: IDeliveryCountry[]
  isLoading: boolean
  countryMeta: IMeta
  createDeliveryCountry: {
    isOpenModal: boolean
    handleOpenModal: () => void
    handleCloseModal: () => void
    createDeliveryCountryApiHandler: (body: Partial<IDeliveryCountry>) => Promise<any>
  }
  editDeliveryCountry: {
    isOpenModal: boolean
    editedItem: IDeliveryCountry | null
    handleOpenModal: (item: IDeliveryCountry) => void
    handleCloseModal: () => void
    editDeliveryCountryApiHandler: (id: string, body: Partial<IDeliveryCountry>) => Promise<any>
  }
  deleteDeliveryCountry: {
    isOpenModal: boolean
    deletedItem: IDeliveryCountry | null
    handleOpenModal: (item: IDeliveryCountry) => void
    handleCloseModal: () => void
    deleteDeliveryCountryApiHandler: (id: string) => Promise<any>
  }
  getAllCountries: () => Promise<any>
}

export const deliveryCountryStore = create<IStore>((set) => ({
  countries: [],
  isLoading: false,
  countryMeta: {
    count: 10,
    page: 1,
    total: 0,
  },
  createDeliveryCountry: {
    isOpenModal: false,
    handleOpenModal: () => {
      set((state) => ({
        ...state,
        createDeliveryCountry: {
          ...state.createDeliveryCountry,
          isOpenModal: true,
        },
      }))
    },
    handleCloseModal: () => {
      set((state) => ({
        ...state,
        createDeliveryCountry: {
          ...state.createDeliveryCountry,
          isOpenModal: false,
        },
      }))
    },
    createDeliveryCountryApiHandler: async (body: Partial<IDeliveryCountry>) => {
      const response = await delivery_country_api.createCountry(body)

      const { status, data } = response

      if (!status || !data) {
        throw new Error('No status code or data returned from server.')
      }

      if (status !== 201) {
        throw new Error(data)
      } else {
        return response
      }
    },
  },

  editDeliveryCountry: {
    isOpenModal: false,
    editedItem: null,
    handleOpenModal: (item: IDeliveryCountry) => {
      set((state) => ({
        ...state,
        editDeliveryCountry: {
          ...state.editDeliveryCountry,
          isOpenModal: true,
          editedItem: item,
        },
      }))
    },
    handleCloseModal: () => {
      set((state) => ({
        ...state,
        editDeliveryCountry: {
          ...state.editDeliveryCountry,
          isOpenModal: false,
          editedItem: null,
        },
      }))
    },
    editDeliveryCountryApiHandler: async (id: string, body: Partial<IDeliveryCountry>) => {
      const response = await delivery_country_api.editCountry(id, body)

      const { status, data } = response

      if (!status || !data) {
        throw new Error('No status code or data returned from server.')
      }

      if (status !== 200) {
        throw new Error(data)
      } else {
        return response
      }
    },
  },

  deleteDeliveryCountry: {
    isOpenModal: false,
    deletedItem: null,
    handleOpenModal: (item: IDeliveryCountry) => {
      set((state) => ({
        ...state,
        deleteDeliveryCountry: {
          ...state.deleteDeliveryCountry,
          isOpenModal: true,
          deletedItem: item,
        },
      }))
    },
    handleCloseModal: () => {
      set((state) => ({
        ...state,
        deleteDeliveryCountry: {
          ...state.deleteDeliveryCountry,
          isOpenModal: false,
          deletedItem: null,
        },
      }))
    },
    deleteDeliveryCountryApiHandler: async (id: string) => {
      const response = await delivery_country_api.deleteCountry(id)

      const { status, data } = response

      if (!status || !data) {
        throw new Error('No status code or data returned from server.')
      }

      if (status !== 200) {
        throw new Error(data)
      } else {
        return response
      }
    },
  },

  getAllCountries: async () => {
    set((state) => ({ ...state, isLoading: true }))
    const response = await delivery_country_api.getAllCountries()

    const { status, data } = response

    set((state) => ({ ...state, isLoading: false }))

    if (!status || !data) {
      throw new Error('No status code or data returned from server.')
    }

    set((state) => ({
      ...state,
      countries: data.countries,
      isLoading: false,
    }))
    return response
  },
}))
