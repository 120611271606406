// ? components
import { TableRow, TableCell } from '@mui/material'

const headerTitles = [
  {
    title: 'ПІБ',
    name: 'pib',
  },
  {
    title: 'Емейл',
    name: 'email',
  },
  {
    title: 'Номер телефону',
    name: 'phoneNumber',
  },
  {
    title: 'Роль',
    name: 'role',
  },
  {
    title: 'Дата реєстрації',
    name: 'createdAt',
  },
  {
    title: `Дії`,
    name: '',
  },
]

const AdminTableHeader = () => {
  return (
    <TableRow>
      {headerTitles.map(({ title }) => (
        <TableCell
          key={title}
          sx={{
            backgroundColor: '#222222 !important',
          }}>
          {title}
        </TableCell>
      ))}
    </TableRow>
  )
}

export default AdminTableHeader
