import { api } from '@/api'

// ? interfaces
import { Icountry } from '@/interfaces/country'

// ? routes prefixes
import { PREFIXES } from '@/routes/prefixes'

// ? utils
import { apiCreator } from '@/utils/apiCreator'

const requestCreator = apiCreator(PREFIXES.DELIVERY_COUNTRY)

export const delivery_country_api = {
  getAllCountries: async () => requestCreator(api, '', 'GET'),

  createCountry: async (body: Partial<Icountry>) => requestCreator(api, '', 'POST', body),

  editCountry: async (id: string, body: Partial<Icountry>) => requestCreator(api, `${id}`, 'PUT', body),

  deleteCountry: async (id: string) => requestCreator(api, `${id}`, 'DELETE'),

  getById: async (id: string) => requestCreator(api, `getOne/${id}`, 'GET'),

  getCountryCodes: async () => requestCreator(api, 'get-all-countries-codes', 'GET'),
}
