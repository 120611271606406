import { create } from 'zustand'

import { REQ } from '@/api'
import { IMeta } from '@/interfaces/general'
import { ITrip } from '@/interfaces/trip'
import { IRace } from '@/interfaces/races'

const { trips_api } = REQ

interface IStore {
  trips: ITrip[]
  isLoading: boolean
  currentTrip: ITrip
  setCurrentTrip: (trip: ITrip) => void
  tripsMeta: IMeta
  searchParams: any
  setSearchParams: (searchParams: Partial<ITrip>) => void

  createTripModal: {
    isOpenModal: boolean
    handleOpenModal: () => void
    handleCloseModal: () => void
  }
  editTrip: {
    isOpenModal: boolean
    editedItem: ITrip | null
    handleOpenModal: (item: ITrip) => void
    handleCloseModal: () => void
    editTripApiHandler: (id: string, body: Partial<ITrip>) => Promise<any>
  }
  createTripRaceStop: {
    isOpenModal: boolean
    editedItem: ITrip | null
    handleOpenModal: (item: ITrip) => void
    handleCloseModal: () => void
    createTripRaceStopsApiHandler: (id: string, body: Partial<ITrip>) => Promise<any>
  }
  editTripRaceStop: {
    isOpenModal: boolean
    editedItem: ITrip | null
    raceStopId: string
    handleOpenModal: (item: ITrip, raceStopId: string | undefined) => void
    handleCloseModal: () => void
    editTripRaceStopsApiHandler: (id: string, body: Partial<ITrip>) => Promise<any>
  }
  deleteTripRaceStop: {
    isOpenModal: boolean
    deletedItem: ITrip | null
    raceStopId: string
    handleOpenModal: (item: ITrip, raceStopId: string | undefined) => void
    handleCloseModal: () => void
    deleteTripRaceStopsApiHandler: (id: string, body: Partial<ITrip>) => Promise<any>
  }
  tripTariffs: {
    isOpenModal: boolean
    editedItem: ITrip | null
    handleOpenModal: (item: ITrip) => void
    handleCloseModal: () => void
    editTripTariffsApiHandler: (id: string, body: Partial<ITrip>) => Promise<any>
  }
  tripSalesControl: {
    isOpenModal: boolean
    editedItem: ITrip | null
    handleOpenModal: (item: ITrip) => void
    handleCloseModal: () => void
    editTripSalesControlApiHandler: (id: string, body: Partial<ITrip>) => Promise<any>
  }
  tripBusModal: {
    isOpenModal: boolean
    editedItem: ITrip | null
    handleOpenModal: (item: ITrip) => void
    handleCloseModal: () => void
    editTripBusApiHandler: (id: string, body: Partial<ITrip>) => Promise<any>
  }
  tripDriversModal: {
    isOpenModal: boolean
    editedItem: ITrip | null
    handleOpenModal: (item: ITrip) => void
    handleCloseModal: () => void
    editTripDriversApiHandler: (id: string, body: Partial<ITrip>) => Promise<any>
  }
  deleteTrip: {
    isOpenModal: boolean
    deletedItem: ITrip | null
    handleOpenModal: (item: ITrip) => void
    handleCloseModal: () => void
    deleteTripApiHandler: (id: number) => Promise<any>
  }
  createTrip: (body: Partial<ITrip>) => Promise<any>
  getAllTrips: (
    flight_id?: number,
    trip_id?: number,
    params?: {
      offset: number
      limit: number
    }
  ) => Promise<any>
  getTripById: (id: string, aggrType: string, idType?: string) => Promise<any>
}

export const tripsStore = create<IStore>((set) => ({
  trips: [],
  isLoading: false,
  currentTrip: {} as ITrip,
  setCurrentTrip: (trip) => {
    set((state) => ({ ...state, currentTrip: trip }))
  },
  tripsMeta: {
    count: 10,
    page: 1,
    total: 0,
  },
  searchParams: {
    //departureDate: getDateRangeIn24Hours(new Date().getTime()),
  },
  setSearchParams: (newSearchParams) => {
    set((state) => ({
      ...state,
      searchParams: { ...state.searchParams, ...newSearchParams },
    }))
  },
  createTripModal: {
    isOpenModal: false,
    handleOpenModal: () => {
      set((state) => ({
        ...state,
        createTripModal: { ...state.createTripModal, isOpenModal: true },
      }))
    },
    handleCloseModal: () => {
      set((state) => ({
        ...state,
        createTripModal: { ...state.createTripModal, isOpenModal: false },
      }))
    },
  },
  editTrip: {
    isOpenModal: false,
    editedItem: null,
    handleOpenModal: (item: ITrip) => {
      set((state) => ({
        ...state,
        editTrip: {
          ...state.editTrip,
          isOpenModal: true,
          editedItem: item,
        },
      }))
    },
    handleCloseModal: () => {
      set((state) => ({
        ...state,
        editTrip: {
          ...state.editTrip,
          isOpenModal: false,
          editedItem: null,
        },
      }))
    },
    editTripApiHandler: async (id: string, body: Partial<ITrip>) => {
      const response = await trips_api.editTrip(id, body)

      const { status, data } = response

      if (!status || !data) {
        throw new Error('No status code or data returned from server.')
      }

      if (status !== 200) {
        throw new Error(data)
      } else {
        return response
      }
    },
  },

  createTripRaceStop: {
    isOpenModal: false,
    editedItem: null,
    handleOpenModal: (item: ITrip) => {
      set((state) => ({
        ...state,
        createTripRaceStop: {
          ...state.createTripRaceStop,
          isOpenModal: true,
          editedItem: item,
        },
      }))
    },
    handleCloseModal: () => {
      set((state) => ({
        ...state,
        createTripRaceStop: {
          ...state.createTripRaceStop,
          isOpenModal: false,
          editedItem: null,
          isLoading: true,
        },
      }))
    },
    createTripRaceStopsApiHandler: async (id: string, body: Partial<ITrip>) => {
      const response = await trips_api.editTrip(id, body)
      const { status, data } = response

      if (!status || !data) {
        throw new Error('No status code or data returned from server.')
      }

      if (status !== 200) {
        throw new Error(data)
      } else {
        return response
      }
    },
  },

  editTripRaceStop: {
    isOpenModal: false,
    editedItem: null,
    raceStopId: '',
    handleOpenModal: (item: ITrip, raceStopId: string | undefined) => {
      set((state) => ({
        ...state,
        editTripRaceStop: {
          ...state.editTripRaceStop,
          isOpenModal: true,
          editedItem: item,
          raceStopId: raceStopId!,
        },
      }))
    },
    handleCloseModal: () => {
      set((state) => ({
        ...state,
        editTripRaceStop: {
          ...state.editTripRaceStop,
          isOpenModal: false,
          editedItem: null,
          isLoading: true,
        },
      }))
    },
    editTripRaceStopsApiHandler: async (id: string, body: Partial<ITrip>) => {
      const response = await trips_api.editTrip(id, body)

      const { status, data } = response
      console.log('EDIT:', data)
      if (!status || !data) {
        throw new Error('No status code or data returned from server.')
      }

      if (status !== 200) {
        throw new Error(data)
      } else {
        return response
      }
    },
  },

  deleteTripRaceStop: {
    isOpenModal: false,
    deletedItem: null,
    raceStopId: '',
    handleOpenModal: (item: ITrip, raceStopId: string | undefined) => {
      set((state) => ({
        ...state,
        deleteTripRaceStop: {
          ...state.deleteTripRaceStop,
          isOpenModal: true,
          deletedItem: item,
          raceStopId: raceStopId!,
        },
      }))
    },
    handleCloseModal: () => {
      set((state) => ({
        ...state,
        deleteTripRaceStop: {
          ...state.deleteTripRaceStop,
          isOpenModal: false,
          deletedItem: null,
        },
      }))
    },
    deleteTripRaceStopsApiHandler: async (id: string, body: Partial<ITrip>) => {
      const response = await trips_api.editTrip(id, body)

      const { status, data } = response

      if (!status || !data) {
        throw new Error('No status code or data returned from server.')
      }

      if (status !== 200) {
        throw new Error(data)
      } else {
        return response
      }
    },
  },

  tripTariffs: {
    isOpenModal: false,
    editedItem: null,
    handleOpenModal: (item: ITrip) => {
      set((state) => ({
        ...state,
        tripTariffs: {
          ...state.tripTariffs,
          isOpenModal: true,
          editedItem: item,
        },
      }))
    },
    handleCloseModal: () => {
      set((state) => ({
        ...state,
        tripTariffs: {
          ...state.tripTariffs,
          isOpenModal: false,
          editedItem: null,
        },
      }))
    },
    editTripTariffsApiHandler: async (id: string, body: Partial<ITrip>) => {
      const response = await trips_api.editTrip(id, body)

      const { status, data } = response

      if (!status || !data) {
        throw new Error('No status code or data returned from server.')
      }

      if (status !== 200) {
        throw new Error(data)
      } else {
        return response
      }
    },
  },

  tripSalesControl: {
    isOpenModal: false,
    editedItem: null,
    handleOpenModal: (item: ITrip) => {
      set((state) => ({
        ...state,
        tripSalesControl: {
          ...state.tripSalesControl,
          isOpenModal: true,
          editedItem: item,
        },
      }))
    },
    handleCloseModal: () => {
      set((state) => ({
        ...state,
        tripSalesControl: {
          ...state.tripSalesControl,
          isOpenModal: false,
          editedItem: null,
        },
      }))
    },
    editTripSalesControlApiHandler: async (id: string, body: Partial<ITrip>) => {
      const response = await trips_api.editTrip(id, body)

      const { status, data } = response

      if (!status || !data) {
        throw new Error('No status code or data returned from server.')
      }

      if (status !== 200) {
        throw new Error(data)
      } else {
        return response
      }
    },
  },

  tripBusModal: {
    isOpenModal: false,
    editedItem: null,
    handleOpenModal: (item: ITrip) => {
      set((state) => ({
        ...state,
        tripBusModal: {
          ...state.tripBusModal,
          isOpenModal: true,
          editedItem: item,
        },
      }))
    },
    handleCloseModal: () => {
      set((state) => ({
        ...state,
        tripBusModal: {
          ...state.tripBusModal,
          isOpenModal: false,
          editedItem: null,
        },
      }))
    },
    editTripBusApiHandler: async (id: string, body: Partial<ITrip>) => {
      const response = await trips_api.editTrip(id, body)

      const { status, data } = response

      if (!status || !data) {
        throw new Error('No status code or data returned from server.')
      }

      if (status !== 200) {
        throw new Error(data)
      } else {
        return response
      }
    },
  },

  tripDriversModal: {
    isOpenModal: false,
    editedItem: null,
    handleOpenModal: (item: ITrip) => {
      set((state) => ({
        ...state,
        tripDriversModal: {
          ...state.tripDriversModal,
          isOpenModal: true,
          editedItem: item,
        },
      }))
    },
    handleCloseModal: () => {
      set((state) => ({
        ...state,
        tripDriversModal: {
          ...state.tripDriversModal,
          isOpenModal: false,
          editedItem: null,
        },
      }))
    },
    editTripDriversApiHandler: async (id: string, body: Partial<ITrip>) => {
      const response = await trips_api.editTrip(id, body)

      const { status, data } = response

      if (!status || !data) {
        throw new Error('No status code or data returned from server.')
      }

      if (status !== 200) {
        throw new Error(data)
      } else {
        return response
      }
    },
  },

  deleteTrip: {
    isOpenModal: false,
    deletedItem: null,
    handleOpenModal: (item: ITrip) => {
      set((state) => ({
        ...state,
        deleteTrip: {
          ...state.deleteTrip,
          isOpenModal: true,
          deletedItem: item,
        },
      }))
    },
    handleCloseModal: () => {
      set((state) => ({
        ...state,
        deleteTrip: {
          ...state.deleteTrip,
          isOpenModal: false,
          deletedItem: null,
        },
      }))
    },
    deleteTripApiHandler: async (id: number) => {
      const response = await trips_api.deleteTrip(id)

      const { status, data } = response

      if (!status || !data) {
        throw new Error('No status code or data returned from server.')
      }

      if (status !== 200) {
        throw new Error(data)
      } else {
        return response
      }
    },
  },

  createTrip: async (body) => {
    const response = await trips_api.createTrip(body)

    const { status, data } = response

    if (!status || !data) {
      throw new Error('No status code or data returned from server.')
    }

    if (status !== 201) {
      throw new Error(data)
    } else {
      return response
    }
  },
  getAllTrips: async (flight_id?: number, trip_id?: number, params?: { offset: number; limit: number }) => {
    set((state) => ({ ...state, isLoading: true }))
    const response = await trips_api.getTrips(flight_id, trip_id, params)

    const { status, data } = response

    if (!status || !data) {
      throw new Error('No status code or data returned from server.')
    }

    if (status !== 200) {
      throw new Error(data)
    } else {
      set((state) => ({
        ...state,
        trips: data.trips,
        isLoading: false,
      }))

      return response
    }
  },

  getTripById: async (id, aggrType = '', idType = 'objectId') => {
    set((state) => ({ ...state, isLoading: true }))
    const response = await trips_api.getTripById(id, aggrType, idType)

    const { status, data } = response

    setTimeout(() => {
      set((state) => ({ ...state, isLoading: false }))
    }, 500)

    if (!status || !data) {
      throw new Error('No status code or data returned from server.')
    }

    if (status !== 200) {
      throw new Error(data)
    } else {
      set((state) => ({ ...state, currentTrip: data }))
      return response
    }
  },
}))
