import { create } from 'zustand'
import { REQ } from '@/api'
import { IMeta } from '@/interfaces/general'
import { ICreateRouteDTO, IRoute } from '@/interfaces/route'

const { routes_api } = REQ

interface IStore {
  routes: IRoute[]
  routesMeta: IMeta
  isLoading: boolean
  createRoute: {
    isOpenModal: boolean
    handleOpenModal: () => void
    handleCloseModal: () => void
    createRouteApiHandler: (body: ICreateRouteDTO) => Promise<any>
  }
  editRoute: {
    isOpenModal: boolean
    editedItem: IRoute | null
    handleOpenModal: (item: IRoute) => void
    handleCloseModal: () => void
    editRouteApiHandler: (id: string, body: ICreateRouteDTO) => Promise<any>
  }
  deleteRoute: {
    isOpenModal: boolean
    deletedItem: IRoute | null
    handleOpenModal: (item: IRoute) => void
    handleCloseModal: () => void
    deleteRouteApiHandler: (id: string) => Promise<any>
  }
  getAllRoutes: (origin_country_id?: number, destination_country_id?: number) => Promise<any>
}

export const routesStore = create<IStore>((set) => ({
  routes: [],
  isLoading: false,
  routesMeta: {
    count: 0,
    limit: 0,
    page: 0,
    total: 10,
  },
  createRoute: {
    isOpenModal: false,
    handleOpenModal: () => set((state) => ({ createRoute: { ...state.createRoute, isOpenModal: true } })),
    handleCloseModal: () => set((state) => ({ createRoute: { ...state.createRoute, isOpenModal: false } })),
    createRouteApiHandler: async (body) => {
      const { data, status } = await routes_api.createRoute(body)

      if (status !== 201) {
        return false
      }

      set((state) => ({ createRoute: { ...state.createRoute, isOpenModal: false } }))

      return true
    },
  },
  deleteRoute: {
    isOpenModal: false,
    deletedItem: null,
    handleOpenModal: (item) =>
      set((state) => ({ deleteRoute: { ...state.deleteRoute, isOpenModal: true, deletedItem: item } })),
    handleCloseModal: () =>
      set((state) => ({ deleteRoute: { ...state.deleteRoute, isOpenModal: false, deletedItem: null } })),
    deleteRouteApiHandler: async (id) => {
      const { data, status } = await routes_api.deleteRoute(id)

      if (status !== 200) {
        return false
      }

      set((state) => ({ deleteRoute: { ...state.deleteRoute, isOpenModal: false, deletedItem: null } }))
      return true
    },
  },

  editRoute: {
    isOpenModal: false,
    editedItem: null,
    handleOpenModal: (item) =>
      set((state) => ({ editRoute: { ...state.editRoute, isOpenModal: true, editedItem: item } })),
    handleCloseModal: () =>
      set((state) => ({ editRoute: { ...state.editRoute, isOpenModal: false, editedItem: null } })),
    editRouteApiHandler: async (id, body) => {
      const { data, status } = await routes_api.editRoute(id, body)

      if (status !== 200) {
        return false
      }

      set((state) => ({ editRoute: { ...state.editRoute, isOpenModal: false, editedItem: null } }))
      return true
    },
  },
  getAllRoutes: async (origin_country_id, destination_country_id) => {
    set({ isLoading: true })
    const { data, status } = await routes_api.getAllRoutes(origin_country_id, destination_country_id)

    if (status !== 200) {
      set({ isLoading: false })
      return false
    }

    set({ routes: data.routes, isLoading: false })
    return true
  },
}))
