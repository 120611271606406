import CountyTable from '@/components/Tabels/CountryTable/CountyTable'
import TravelWrapper from '@/components/Wrappers/TravelWrapper'
import { Typography } from '@mui/material'

const Country = () => {
  return (
    <TravelWrapper>
      {true ? (
        <CountyTable />
      ) : (
        <Typography variant="h4" color="error">
          You don&apos;t have permission to view Country
        </Typography>
      )}
    </TravelWrapper>
  )
}

export default Country
