// ? hooks
import React, { useEffect, useState } from 'react'

import useAsyncWrapper from '@/hooks/useAsyncWrapper'

// ? stores
import { townStore } from '@/store/townStore'
import { messageStore } from '@/store/store'
import { countryStore } from '@/store/countryStore'

// ? utils
import { inputsChanger } from '@/utils/inputsChanger'

// ? components
import { Button, Box, FormControl, InputLabel, MenuItem, Select, TextField, FormHelperText } from '@mui/material'
import { LoadingButton } from '@mui/lab'

import ModalContainer from '@/components/UI/Modal/ModalContainer'
import { DataGrid } from '@mui/x-data-grid'
import { Add } from '@mui/icons-material'
import { validateForm } from '@/utils/formValidation'
import { deliveryCountryStore } from '@/store/deliveryCountryStore'
import { departmentStore } from '@/store/departmentStore'
import { IDeliveryCountry } from '@/interfaces/country'

const EditDepartmentModal = () => {
  const [isLoading, setIsLoading] = useState(false)

  const { addMessage } = messageStore()
  const { countryMeta, getAllCountries, countries } = deliveryCountryStore()

  const { editDepartment, getAllDepartments } = departmentStore()
  const { isOpenModal, handleCloseModal, editedItem } = editDepartment

  const [formValues, setFormValues] = useState({} as any)
  const [cityStops, setCityStops] = useState([] as any)

  const [formErrors, setFormErrors] = useState<any>()
  const validationRules = {
    country_id: { required: true },
    address: { required: true },
    city: { required: true },
    work_schedule: { required: true },
  }

  // ? API handlers
  const getCountriesApiHandler = useAsyncWrapper(getAllCountries, 'getCountriesApiHandler')

  const editDepartmentApiHandler = useAsyncWrapper(editDepartment.editDepartmentApiHandler, 'editDepartmentApiHandler')

  const getAllDepartmentsApiHandler = useAsyncWrapper(getAllDepartments, 'getAllDepartmentsApiHandler')

  // ? handlers
  const inputChangeHandler = inputsChanger('input', formValues, setFormValues)
  const selectChangeHandler = inputsChanger('selectChanger', formValues, setFormValues) as (e: any) => void
  // ? handlers

  const editTownHandler = async () => {
    setIsLoading(true)

    const id = editedItem?.department_id

    const formErrors = validateForm(formValues, validationRules)
    if (Object.keys(formErrors).length !== 0) {
      setFormErrors(formErrors)
      setIsLoading(false)
      return
    }

    const { error: editDepartmentError } = await editDepartmentApiHandler([id, formValues])

    if (editDepartmentError) {
      setIsLoading(false)
      return false
    }

    const { error: getDepartmentsError } = await getAllDepartmentsApiHandler([])

    if (getDepartmentsError) {
      setIsLoading(false)
      return false
    }

    addMessage({
      severity: 'success',
      message: 'Успішно відредаговано',
      isOpen: true,
    })
    handleCloseModal()
  }

  useEffect(() => {
    setFormValues({
      ...formValues,
      country_id: editedItem?.country_id,
      address: editedItem?.address,
      city: editedItem?.city,
      work_schedule: editedItem?.work_schedule,
    })

    const fetchData = async () => {
      const { error: getCountriesError } = await getCountriesApiHandler([
        {
          ...countryMeta,
          count: 1000,
        },
      ])

      if (getCountriesError) {
        return false
      }
    }

    fetchData()
  }, [])

  return (
    <ModalContainer
      width={800}
      isOpen={isOpenModal}
      handleClose={handleCloseModal}
      title={`Редагування відділення ${editedItem?.city}`}>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexFlow: 'column nowrap',
          justifyContent: 'center',
          gap: '20px',
        }}>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            gap: '20px',
          }}>
          <FormControl error={!!formErrors?.country_id} fullWidth variant="standard">
            <InputLabel>Країна</InputLabel>
            <Select
              onChange={selectChangeHandler}
              value={formValues.country_id || editedItem?.country_id}
              name="country_id"
              label="Країна">
              {countries?.map((country: IDeliveryCountry) => (
                <MenuItem key={country.country_id} value={country.country_id}>
                  {country.country_name_ua}
                </MenuItem>
              ))}
            </Select>
            {formErrors?.country_id && <FormHelperText>{formErrors?.country_id}</FormHelperText>}
          </FormControl>
          <FormControl fullWidth variant="standard">
            <InputLabel shrink>Назва міста</InputLabel>
            <TextField
              name="city"
              onChange={inputChangeHandler}
              fullWidth
              value={formValues.city || editedItem?.city}
              variant="standard"
              sx={{
                mt: '16px',
              }}
              error={!!formErrors?.city}
              helperText={formErrors?.city}
            />
          </FormControl>
        </Box>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            gap: '20px',
          }}>
          <FormControl fullWidth variant="standard">
            <InputLabel shrink>Адреса</InputLabel>
            <TextField
              name="address"
              onChange={inputChangeHandler}
              fullWidth
              value={formValues.address || editedItem?.address}
              variant="standard"
              sx={{
                mt: '16px',
              }}
              error={!!formErrors?.address}
              helperText={formErrors?.address}
            />
          </FormControl>
          <FormControl fullWidth variant="standard">
            <InputLabel shrink>Графік роботи</InputLabel>
            <TextField
              name="work_schedule"
              onChange={inputChangeHandler}
              fullWidth
              value={formValues.work_schedule || editedItem?.work_schedule}
              variant="standard"
              sx={{
                mt: '16px',
              }}
              error={!!formErrors?.work_schedule}
              helperText={formErrors?.work_schedule}
            />
          </FormControl>
        </Box>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            gap: '10px',
          }}>
          <LoadingButton color="primary" variant="contained" size="small" onClick={editTownHandler} loading={isLoading}>
            Зберегти
          </LoadingButton>

          <Button onClick={handleCloseModal} color="error" variant="contained" size="small">
            Відмінити
          </Button>
        </Box>
      </Box>
    </ModalContainer>
  )
}

export default EditDepartmentModal
