import { api } from '@/api'
import { CreateDeliveryDTO, IDeliveryRequest } from '@/interfaces/deliveryRequests'

export const deliveryRequests_api = {
  getDeliveryRequests: ({
    delivery_type,
    origin_city,
    sender_phone,
    status,
    country_id,
    date_from,
    date_to,
    delivery_city,
    sender_name,
    receiver_name,
    order_id,
    weight_category,
    driver_id,
    department_id,
    receiver_phone,
    exportAs,
  }: {
    delivery_type?: string
    status?: string
    origin_city?: string
    delivery_city?: string
    sender_phone?: string
    receiver_phone?: string
    sender_name?: string
    receiver_name?: string
    order_id?: number
    date_from?: string
    date_to?: string
    weight_category?: string
    driver_id?: number
    department_id?: number
    country_id?: number
    exportAs?: 'pdf' | null
  }) => {
    return api.get('/admin/delivery/orders', {
      params: {
        delivery_type,
        origin_city,
        sender_phone,
        status,
        country_id,
        date_from,
        date_to,
        delivery_city,
        sender_name,
        receiver_name,
        order_id,
        weight_category,
        driver_id,
        department_id,
        receiver_phone,
        export: exportAs,
      },
    })
  },
  editDeliveryRequest: (id: number, changes: Partial<IDeliveryRequest>) => {
    return api.put(`/admin/delivery/orders/${id}`, changes)
  },
  createDeliveryRequest: (data: CreateDeliveryDTO) => {
    return api.post(`/delivery/create`, data)
  },
}
