import React, { useEffect, useState } from 'react'
import LoadingTableData from '@/components/UI/LoadingTableData/LoadingTableData'
import TablePaginationCustom from '@/components/UI/TablePaginationCustom/TablePaginationCustom'
import {
  Grid,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  FormControlLabel,
  Switch,
  Box,
  useTheme,
  Tab,
  Button,
} from '@mui/material'

import useAsyncWrapper from '@/hooks/useAsyncWrapper'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import DeliveryRequestsToAbroadHeader from './tables/DeliveryRequestsToAbroad/DeliveryRequestsToAbroadHeader'
import DeliveryRequestsToAbroadRow from './tables/DeliveryRequestsToAbroad/DeliveryRequestsToAbroadRow'
import { CreateDeliveryRequest } from './modals/CreateDeliveryRequest'
import { deliveryRequestsStore } from '@/store/deliveryRequestsStore'
import { EditDeliveryRequest } from './modals/EditDeliveryRequest'
import { DeliveryRequestStatus, DeliveryType } from '@/interfaces/deliveryRequests'
import { departmentStore } from '@/store/departmentStore'
import { PreviewRequestModal } from './modals/PreviewModelRequest'
import { DeliveryRequestToAbroadTable } from './tables/DeliveryRequestsToAbroad/DeliveryRequestToAbroadTable'
import { DeliveryRequestsToUkraineTable } from './tables/DeliveryRequestsToUkraine/DeliveryRequestsToUkraineTable'
import { DeliveryRequestsCityToCityTable } from './tables/DeliveryRequestsCityToCity/DeliveryRequestsCityToCityTable'

const DeliveryRequestsTable = () => {
  const theme = useTheme()
  const [isReloading, setIsReloading] = useState(false)
  const { createModal } = deliveryRequestsStore()
  const { handleOpen } = createModal

  const [status, setStatus] = useState<DeliveryRequestStatus>('created')
  const [deliveryType, setDeliveryType] = useState<DeliveryType>('abroad_to_ukraine')

  const handleChangeStatusTab = (event: React.SyntheticEvent, newValue: DeliveryRequestStatus) => {
    setStatus(newValue)
  }

  const handleChangeDeliveryType = (event: React.SyntheticEvent, newValue: string) => {
    setDeliveryType(newValue as DeliveryType)
  }

  const { getDeliveryRequests, deliveryRequestsMeta, isLoading, deliveryRequests } = deliveryRequestsStore()
  const { getAllDepartments } = departmentStore()

  const getDeliveryRequestsApiHandler = useAsyncWrapper(getDeliveryRequests, 'getDeliveryRequests')
  const getAllDepartmentsApiHandler = useAsyncWrapper(getAllDepartments, 'getAllDepartments')

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsReloading(event.target.checked)
  }

  useEffect(() => {
    const fetchData = async () => {
      const { error: getDeliveryRequestsError } = await getDeliveryRequestsApiHandler([
        { status, delivery_type: deliveryType },
      ])

      if (getDeliveryRequestsError) {
        return
      }
    }

    fetchData()
  }, [status, deliveryType])

  const getRequestInInterval = async () => {
    const { error: getDeliveryRequestsError } = await getDeliveryRequestsApiHandler([
      { status, delivery_type: deliveryType },
    ])

    const timeOut = setTimeout(() => {
      getRequestInInterval()
      clearTimeout(timeOut)
    }, 60000)

    if (getDeliveryRequestsError) {
      return
    }
  }

  useEffect(() => {
    if (isReloading) {
      getRequestInInterval()
    }
  }, [isReloading, status, deliveryType])

  useEffect(() => {
    const fetchData = async () => {
      const { error: getAllDepartmentsError } = await getAllDepartmentsApiHandler([])

      if (getAllDepartmentsError) {
        return
      }
    }

    fetchData()
  }, [])

  return (
    <Grid container spacing={1} sx={{ pr: 1 }}>
      <CreateDeliveryRequest status={status} deliveryType={deliveryType} />
      <EditDeliveryRequest status={status} deliveryType={deliveryType} />
      <PreviewRequestModal />
      <Grid item xs={12}>
        <FormControlLabel
          control={<Switch checked={isReloading} onChange={handleChange} />}
          label="Автооновлення через 60сек"
        />
        <Grid item xs={12}>
          <Button
            onClick={handleOpen}
            variant="contained"
            color="primary"
            sx={{
              mt: 2,
            }}>
            Створити заявку
          </Button>
        </Grid>
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            mt: 2,
            gap: 2,
            pt: 2,
            borderTop: 1,
            borderColor: 'divider',
          }}>
          <TabContext value={deliveryType}>
            <Box sx={{ borderRight: 1, borderColor: 'divider', width: '250px' }}>
              <TabList onChange={handleChangeDeliveryType} orientation="vertical">
                <Tab value="abroad_to_ukraine" label="в Україну" />
                <Tab value="ukraine_to_abroad" label="З України" />
                <Tab value="city_to_city" label="Міжміст" />
              </TabList>
            </Box>
            <TabPanel
              value="abroad_to_ukraine"
              sx={{
                p: 0,
              }}>
              <TabContext value={status}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <TabList onChange={handleChangeStatusTab}>
                    <Tab label="Нові замовлення" value="created" />
                    <Tab label="В процесі" value="ongoing" />
                    <Tab label="Завершені" value="completed" />
                    <Tab label="Відхилені" value="canceled" />
                  </TabList>
                </Box>
                <TabPanel
                  value="created"
                  sx={{
                    p: 0,
                  }}>
                  <DeliveryRequestsToUkraineTable
                    deliveryRequests={deliveryRequests}
                    isLoading={isLoading}
                    getDeliveryRequestsApiHandler={getDeliveryRequestsApiHandler}
                    deliveryRequestsMeta={deliveryRequestsMeta}
                    status={status}
                  />
                </TabPanel>
                <TabPanel
                  value="ongoing"
                  sx={{
                    p: 0,
                  }}>
                  <DeliveryRequestsToUkraineTable
                    deliveryRequests={deliveryRequests}
                    isLoading={isLoading}
                    getDeliveryRequestsApiHandler={getDeliveryRequestsApiHandler}
                    deliveryRequestsMeta={deliveryRequestsMeta}
                    status={status}
                  />
                </TabPanel>
                <TabPanel
                  value="completed"
                  sx={{
                    p: 0,
                  }}>
                  <DeliveryRequestsToUkraineTable
                    deliveryRequests={deliveryRequests}
                    isLoading={isLoading}
                    getDeliveryRequestsApiHandler={getDeliveryRequestsApiHandler}
                    deliveryRequestsMeta={deliveryRequestsMeta}
                    status={status}
                  />
                </TabPanel>
                <TabPanel
                  value="canceled"
                  sx={{
                    p: 0,
                  }}>
                  <DeliveryRequestsToUkraineTable
                    deliveryRequests={deliveryRequests}
                    isLoading={isLoading}
                    getDeliveryRequestsApiHandler={getDeliveryRequestsApiHandler}
                    deliveryRequestsMeta={deliveryRequestsMeta}
                    status={status}
                  />
                </TabPanel>
              </TabContext>
            </TabPanel>
            <TabPanel
              value="ukraine_to_abroad"
              sx={{
                p: 0,
              }}>
              <TabContext value={status}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <TabList onChange={handleChangeStatusTab}>
                    <Tab label="Нові замовлення" value="created" />
                    <Tab label="В процесі" value="ongoing" />
                    <Tab label="Завершені" value="completed" />
                    <Tab label="Відхилені" value="canceled" />
                  </TabList>
                </Box>
                <TabPanel
                  value="created"
                  sx={{
                    p: 0,
                  }}>
                  <DeliveryRequestToAbroadTable
                    deliveryRequests={deliveryRequests}
                    isLoading={isLoading}
                    getDeliveryRequestsApiHandler={getDeliveryRequestsApiHandler}
                    deliveryRequestsMeta={deliveryRequestsMeta}
                    status={status}
                  />
                </TabPanel>
                <TabPanel
                  value="ongoing"
                  sx={{
                    p: 0,
                  }}>
                  <DeliveryRequestToAbroadTable
                    deliveryRequests={deliveryRequests}
                    isLoading={isLoading}
                    getDeliveryRequestsApiHandler={getDeliveryRequestsApiHandler}
                    deliveryRequestsMeta={deliveryRequestsMeta}
                    status={status}
                  />
                </TabPanel>
                <TabPanel
                  value="completed"
                  sx={{
                    p: 0,
                  }}>
                  <DeliveryRequestToAbroadTable
                    deliveryRequests={deliveryRequests}
                    isLoading={isLoading}
                    getDeliveryRequestsApiHandler={getDeliveryRequestsApiHandler}
                    deliveryRequestsMeta={deliveryRequestsMeta}
                    status={status}
                  />
                </TabPanel>
                <TabPanel
                  value="canceled"
                  sx={{
                    p: 0,
                  }}>
                  <DeliveryRequestToAbroadTable
                    deliveryRequests={deliveryRequests}
                    isLoading={isLoading}
                    getDeliveryRequestsApiHandler={getDeliveryRequestsApiHandler}
                    deliveryRequestsMeta={deliveryRequestsMeta}
                    status={status}
                  />
                </TabPanel>
              </TabContext>
            </TabPanel>
            <TabPanel
              value="city_to_city"
              sx={{
                p: 0,
              }}>
              <TabContext value={status}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <TabList onChange={handleChangeStatusTab}>
                    <Tab label="Нові замовлення" value="created" />
                    <Tab label="В процесі" value="ongoing" />
                    <Tab label="Завершені" value="completed" />
                    <Tab label="Відхилені" value="canceled" />
                  </TabList>
                </Box>
                <TabPanel
                  value="created"
                  sx={{
                    p: 0,
                  }}>
                  <DeliveryRequestsCityToCityTable
                    deliveryRequests={deliveryRequests}
                    isLoading={isLoading}
                    getDeliveryRequestsApiHandler={getDeliveryRequestsApiHandler}
                    deliveryRequestsMeta={deliveryRequestsMeta}
                    status={status}
                  />
                </TabPanel>
                <TabPanel
                  value="ongoing"
                  sx={{
                    p: 0,
                  }}>
                  <DeliveryRequestsCityToCityTable
                    deliveryRequests={deliveryRequests}
                    isLoading={isLoading}
                    getDeliveryRequestsApiHandler={getDeliveryRequestsApiHandler}
                    deliveryRequestsMeta={deliveryRequestsMeta}
                    status={status}
                  />
                </TabPanel>
                <TabPanel
                  value="completed"
                  sx={{
                    p: 0,
                  }}>
                  <DeliveryRequestsCityToCityTable
                    deliveryRequests={deliveryRequests}
                    isLoading={isLoading}
                    getDeliveryRequestsApiHandler={getDeliveryRequestsApiHandler}
                    deliveryRequestsMeta={deliveryRequestsMeta}
                    status={status}
                  />
                </TabPanel>
                <TabPanel
                  value="canceled"
                  sx={{
                    p: 0,
                  }}>
                  <DeliveryRequestsCityToCityTable
                    deliveryRequests={deliveryRequests}
                    isLoading={isLoading}
                    getDeliveryRequestsApiHandler={getDeliveryRequestsApiHandler}
                    deliveryRequestsMeta={deliveryRequestsMeta}
                    status={status}
                  />
                </TabPanel>
              </TabContext>
            </TabPanel>
          </TabContext>
        </Box>
      </Grid>
    </Grid>
  )
}

export default DeliveryRequestsTable
