import { IPromotion } from '@/interfaces/promotion'
import { promotionStore } from '@/store/promotionStore'
import { transportStore } from '@/store/transportStore'
import { Edit, Delete } from '@mui/icons-material'
import { TableRow, TableCell, Typography, IconButton, Tooltip, Button } from '@mui/material'

interface IProps {
  promotion: IPromotion
}

const PROMOTIONS_TYPES = {
  all: 'Всі',
  delivery: 'Доставка',
  travel: 'Подорожі',
} as any

const PromotionsTabelRow = ({ promotion }: IProps) => {
  const { editPromotion, deletePromotion, photosPromotion } = promotionStore()

  const handleEditTransport = (promotion: IPromotion) => {
    editPromotion.handleOpenModal(promotion)
  }

  const handleDeleteTransport = (promotion: IPromotion) => {
    deletePromotion.handleOpenModal(promotion)
  }

  const handlePhotosTransport = (promotion: IPromotion) => {
    console.log('promotion', promotion)
    photosPromotion.handleOpenModal(promotion)
  }

  return (
    <TableRow hover>
      <TableCell align="left">
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{
            cursor: 'pointer',
          }}>
          {PROMOTIONS_TYPES[promotion.promotion_type]}
        </Typography>
      </TableCell>
      <TableCell align="left">
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{
            cursor: 'pointer',
          }}>
          {promotion.promotion_title}
        </Typography>
      </TableCell>
      <TableCell align="left">
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{
            cursor: 'pointer',
          }}>
          {promotion.promotion_text}
        </Typography>
      </TableCell>
      <TableCell align="left">
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{
            cursor: 'pointer',
          }}>
          {promotion.accessibility ? 'Так' : 'Ні'}
        </Typography>
      </TableCell>
      <TableCell align="left">
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{
            cursor: 'pointer',
          }}>
          <Button variant="contained" color="primary" onClick={() => handlePhotosTransport(promotion)}>
            Переглянути
          </Button>
        </Typography>
      </TableCell>
      <TableCell align="left">
        <Tooltip title="Edit">
          <IconButton color="warning" onClick={() => handleEditTransport(promotion)}>
            <Edit />
          </IconButton>
        </Tooltip>
        <Tooltip title="Delete">
          <IconButton color="error" onClick={() => handleDeleteTransport(promotion)}>
            <Delete />
          </IconButton>
        </Tooltip>
      </TableCell>
    </TableRow>
  )
}

export default PromotionsTabelRow
