import React from 'react'

import { formatDate } from '@/utils/formatDate'
import { Edit, Visibility } from '@mui/icons-material'
import { TableRow, TableCell, Typography, IconButton, Tooltip, useTheme } from '@mui/material'
import { IDeliveryRequest } from '@/interfaces/deliveryRequests'
import { deliveryRequestsStore } from '@/store/deliveryRequestsStore'
import { DELIVERY_STATUS, PAYMENT_METHOD } from '@/constants/constants'
import { departmentStore } from '@/store/departmentStore'

type IDeliveryRequestProps = {
  deliveryRequest: IDeliveryRequest
}

const DeliveryRequestsToUkraineRow = ({ deliveryRequest }: IDeliveryRequestProps) => {
  const theme = useTheme()

  const { editModal, previewModal } = deliveryRequestsStore()
  const { departments } = departmentStore()

  const handleEdit = () => {
    editModal.handleOpen(deliveryRequest)
  }

  const handlePreview = () => {
    previewModal.handleOpen(deliveryRequest)
  }

  const getOriginAddress = () => {
    if (deliveryRequest.origin_city && deliveryRequest.origin_address) {
      return `${deliveryRequest.origin_city}, ${deliveryRequest.origin_address}`
    }

    if (deliveryRequest.origin_department) {
      return `відділення ${
        departments.find((department) => department.department_id === deliveryRequest.origin_department)?.city
      }`
    }

    return deliveryRequest.ttn_number
  }

  const getDeliveryAddress = () => {
    if (deliveryRequest.delivery_city && deliveryRequest.delivery_address) {
      return `${deliveryRequest.delivery_city}, ${deliveryRequest.delivery_address}`
    }

    if (deliveryRequest.delivery_department) {
      return `відділення ${
        departments.find((department) => department.department_id === deliveryRequest.delivery_department)?.city
      }`
    }

    return deliveryRequest.ttn_number
  }

  return (
    <TableRow hover>
      <TableCell align="left">
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{
            cursor: 'pointer',
          }}>
          {DELIVERY_STATUS[deliveryRequest.status]}
        </Typography>
      </TableCell>
      <TableCell align="left">
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{
            cursor: 'pointer',
          }}>
          {deliveryRequest?.order_id}
        </Typography>
      </TableCell>
      <TableCell align="left">
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{
            cursor: 'pointer',
          }}>
          {/* @ts-ignore */}
          {deliveryRequest?.origin_country_name_ua || 'N/A'}
        </Typography>
      </TableCell>
      <TableCell align="left">
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{
            cursor: 'pointer',
          }}>
          {deliveryRequest.sender_method}
        </Typography>
      </TableCell>
      <TableCell align="left">
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{
            cursor: 'pointer',
          }}>
          {deliveryRequest.sender_name}
        </Typography>
      </TableCell>
      <TableCell align="left">
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{
            cursor: 'pointer',
          }}>
          {getOriginAddress()}
        </Typography>
      </TableCell>
      <TableCell align="left">
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{
            cursor: 'pointer',
          }}>
          {deliveryRequest.sender_phone}
        </Typography>
      </TableCell>
      <TableCell align="left">
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{
            cursor: 'pointer',
          }}>
          {deliveryRequest.delivery_method}
        </Typography>
      </TableCell>
      <TableCell align="left">
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{
            cursor: 'pointer',
          }}>
          {deliveryRequest.receiver_name}
        </Typography>
      </TableCell>
      <TableCell align="left">
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{
            cursor: 'pointer',
          }}>
          {deliveryRequest.quantity}
        </Typography>
      </TableCell>
      <TableCell align="left">
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{
            cursor: 'pointer',
          }}>
          {deliveryRequest.weight}
        </Typography>
      </TableCell>
      <TableCell align="left">
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{
            cursor: 'pointer',
          }}>
          {deliveryRequest.insurance_sum}
        </Typography>
      </TableCell>
      <TableCell align="left">
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{
            cursor: 'pointer',
          }}>
          {deliveryRequest.price}
        </Typography>
      </TableCell>
      <TableCell align="left">
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{
            cursor: 'pointer',
          }}>
          {deliveryRequest.discount}
        </Typography>
      </TableCell>
      <TableCell align="left">
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{
            cursor: 'pointer',
          }}>
          {deliveryRequest.promocode}
        </Typography>
      </TableCell>
      <TableCell align="left">
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{
            cursor: 'pointer',
          }}>
          {deliveryRequest.notes}
        </Typography>
      </TableCell>
      <TableCell align="left">
        <Tooltip title="Переглянути">
          <IconButton color="warning" onClick={handlePreview}>
            <Visibility />
          </IconButton>
        </Tooltip>
        <Tooltip title="Edit">
          <IconButton color="warning" onClick={handleEdit}>
            <Edit />
          </IconButton>
        </Tooltip>
      </TableCell>
    </TableRow>
  )
}

export default DeliveryRequestsToUkraineRow
