// ? hooks
import { useEffect } from 'react'

import useAsyncWrapper from '@/hooks/useAsyncWrapper'

import { Grid, Button, TableContainer, Table, TableHead, TableBody, Box } from '@mui/material'

import CreateDepartmentModal from './modals/CreateDepartmentModal'
import EditTownModal from './modals/EditDepartmentModal'
import DeleteTownModal from './modals/DeleteDepartmentModal'

import TablePaginationCustom from '@/components/UI/TablePaginationCustom/TablePaginationCustom'
import LoadingTableData from '@/components/UI/LoadingTableData/LoadingTableData'

import { Add } from '@mui/icons-material'

import { departmentStore } from '@/store/departmentStore'
import DeliveryDepartmentTabelRow from './DeliveryDepartmentTabelRow'
import DeliveryDepartmentTabelHeader from './DeliveryDepartmentTabelHeader'
import DeleteDepartmentModal from './modals/DeleteDepartmentModal'
import EditDepartmentModal from './modals/EditDepartmentModal'

const DeliveryDepartmentsTabel = () => {
  const {
    getAllDepartments,
    createDepartment,
    editDepartment,
    deleteDepartment,
    departments,
    departmentMeta,
    isLoading,
  } = departmentStore()

  const handleOpenCreateModal = () => {
    createDepartment.handleOpenModal()
  }

  const getDepartmentsApiHandler = useAsyncWrapper(getAllDepartments, 'getTownsApiHandler')

  useEffect(() => {
    const fetchData = async () => {
      const { error: getDepartmentsError } = await getDepartmentsApiHandler([])

      if (getDepartmentsError) {
        return false
      }
    }

    fetchData()
  }, [])

  return (
    <Grid container spacing={1} sx={{ pr: 1 }}>
      {createDepartment.isOpenModal && <CreateDepartmentModal />}
      {editDepartment.isOpenModal && <EditDepartmentModal />}
      {deleteDepartment.isOpenModal && <DeleteDepartmentModal />}

      <Grid item xs={12}>
        <Box>
          <Button
            variant="contained"
            color="primary"
            size="small"
            className="createUserBtn"
            endIcon={<Add className="addIcon" />}
            onClick={handleOpenCreateModal}>
            Додати відділення
          </Button>
        </Box>
        {departments?.length > 0 ? (
          <TableContainer>
            <Table
              stickyHeader
              sx={{
                maxHeight: 'calc(100vh - 250px)',
                width: '100%',
                maxWidth: '100%',
                overflow: 'scroll',
                mt: 1,
              }}>
              <TableHead>
                <DeliveryDepartmentTabelHeader />
              </TableHead>
              <TableBody>
                {!isLoading ? (
                  departments.map((item) => <DeliveryDepartmentTabelRow department={item} key={item?.department_id} />)
                ) : (
                  <LoadingTableData columns={6} rows={5} />
                )}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: 'calc(100vh - 250px)',
              gap: 2,
            }}>
            <p>Дані відсутні</p>
            <Button
              variant="contained"
              color="primary"
              size="small"
              className="createUserBtn"
              endIcon={<Add className="addIcon" />}
              onClick={handleOpenCreateModal}>
              Додати відділення
            </Button>
          </Box>
        )}
        {departments && departments?.length !== 0 && (
          <TablePaginationCustom getData={getDepartmentsApiHandler} meta={departmentMeta} />
        )}
      </Grid>
    </Grid>
  )
}
export default DeliveryDepartmentsTabel
