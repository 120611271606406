// ? hooks
import { useState } from 'react'

import useAsyncWrapper from '@/hooks/useAsyncWrapper'

import { messageStore } from '@/store/store'

// ? components
import { Button, Box } from '@mui/material'
import { LoadingButton } from '@mui/lab'

import ModalContainer from '@/components/UI/Modal/ModalContainer'
import { departmentStore } from '@/store/departmentStore'

const DeleteDepartmentModal = () => {
  const [isLoading, setIsLoading] = useState(false)
  const { addMessage } = messageStore()
  const { deleteDepartment, getAllDepartments } = departmentStore()
  const { isOpenModal, handleCloseModal, deletedItem } = deleteDepartment

  // ? API handlers
  const deleteDepartmentApiHandler = useAsyncWrapper(
    deleteDepartment.deleteDepartmentApiHandler,
    'deleteDepartmentApiHandler'
  )
  const getDepartmentsApiHandler = useAsyncWrapper(getAllDepartments, 'getAllDepartmentsApiHandler')

  const handleClose = () => {
    handleCloseModal()
  }

  const deleteDepartmentHandler = async () => {
    setIsLoading(true)

    const id = deletedItem?.department_id
    if (!id) {
      return false
    }

    const { error: deleteDepartmentError } = await deleteDepartmentApiHandler([id])

    if (deleteDepartmentError) {
      setIsLoading(false)
      return false
    }

    const { error: getDepartmentsError } = await getDepartmentsApiHandler([])

    if (getDepartmentsError) {
      setIsLoading(false)
      return false
    }

    addMessage({
      severity: 'success',
      message: 'Успішно видалено',
      isOpen: true,
    })

    setIsLoading(false)
    handleClose()
  }

  return (
    <ModalContainer width={800} isOpen={isOpenModal} handleClose={handleClose} title={`Видалення ${deletedItem?.city}`}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          mt: 1,
        }}>
        <LoadingButton
          loading={isLoading}
          color="error"
          variant="contained"
          size="small"
          onClick={deleteDepartmentHandler}>
          Видалити
        </LoadingButton>
        <Button onClick={handleClose} color="primary" variant="contained" size="small" sx={{ ml: 1 }}>
          Відмінити
        </Button>
      </Box>
    </ModalContainer>
  )
}

export default DeleteDepartmentModal
