// ? components
import { TableRow, TableCell, Typography, Tooltip, IconButton } from '@mui/material'

// ? icons
import { Delete, Edit } from '@mui/icons-material'
import { routesStore } from '@/store/routesStore'
import { IRoute } from '@/interfaces/route'

interface IProps {
  route: IRoute
}

const DeliveryRouteTabelRow = ({ route }: IProps) => {
  const { editRoute, deleteRoute } = routesStore()

  const handleEditRoute = (route: IRoute) => {
    editRoute.handleOpenModal(route)
  }

  const handleDeleteRoute = (route: IRoute) => {
    deleteRoute.handleOpenModal(route)
  }

  return (
    <TableRow hover>
      <TableCell align="left">
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{
            cursor: 'pointer',
          }}>
          {route.route_id}
        </Typography>
      </TableCell>
      <TableCell align="left">
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{
            cursor: 'pointer',
          }}>
          {`EN: ${route.origin_name_en}`}
          <br />
          {`UA: ${route.origin_name_ua}`}
        </Typography>
      </TableCell>
      <TableCell align="left">
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{
            cursor: 'pointer',
          }}>
          {`EN: ${route.destination_name_en}`}
          <br />
          {`UA: ${route.destination_name_ua}`}
        </Typography>
      </TableCell>
      <TableCell align="left">
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{
            cursor: 'pointer',
          }}>
          {route.sender_types}
        </Typography>
      </TableCell>
      <TableCell align="left">
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{
            cursor: 'pointer',
          }}>
          {route.delivery_types}
        </Typography>
      </TableCell>
      <TableCell align="left">
        <Tooltip title="Edit">
          <IconButton color="warning" onClick={() => handleEditRoute(route)}>
            <Edit />
          </IconButton>
        </Tooltip>
        <Tooltip title="Delete">
          <IconButton color="error" onClick={() => handleDeleteRoute(route)}>
            <Delete />
          </IconButton>
        </Tooltip>
      </TableCell>
    </TableRow>
  )
}

export default DeliveryRouteTabelRow
