import Admins from '@/pages/Admins/Admins'
import Consultations from '@/pages/Consultations/Consultations'
import Country from '@/pages/Country/Country'
import Drivers from '@/pages/Drivers/Drivers'
import Error404 from '@/pages/Error404/Error404'
import News from '@/pages/News/News'
import Blog from '@/pages/Blog/Blog'
import Races from '@/pages/Races/Races'
import RaceTripsPage from '@/pages/RaceTripsPage/RaceTripsPage'
import RaceTariffsPage from '@/pages/RaceTariffsPage/RaceTariffsPage'
import RacePlanningPage from '@/pages/RacePlanningPage/RacePlanningPage'
import PlanningSchedulePage from '@/pages/PlanningSchedulePage/PlanningSchedulePage'
import PlanningTariffsPage from '@/pages/PlanningTariffsPage/PlanningTariffsPage'
import TripSalesControlPage from '@/pages/TripSalesControlPage/TripSalesControlPage'
import TripTariffsPage from '@/pages/TripTariffsPage/TripTariffsPage'
import SchedulePage from '@/pages/SchedulePage/SchedulePage'
import TripSchedulePage from '@/pages/TripSchedulePage/TripSchedulePage'
import Town from '@/pages/Town/Town'
import Transports from '@/pages/Transports/Transports'
import TripItemPage from '@/pages/TripItemPage/TripItemPage'
import Users from '@/pages/Users/Users'
import Stops from '@/pages/Stops/Stops'
import Requests from '@/pages/Requests/Requests'
import { HomePage } from '@/pages/Home/Home'
import DeliveryRequests from '@/pages/DeliveryRequests/DeliveryRequests'
import DeliveryCountry from '@/pages/DeliveryCountry/DeliveryCountry'
import DeliveryDepartments from '@/pages/DeliveryDepartments/DeliveryDepartments'
import DeliveryRoutes from '@/pages/DeliveryRoutes/DeliveryRoutes'
import Promotions from '@/pages/Promotions/Promotions'
import Promocodes from '@/pages/Promocodes/Promocodes'
import DeliveryDrivers from '@/pages/DeliveryDrivers/DeliveryDrivers'

export const ROUTES = [
  {
    path: '/',
    element: <HomePage />,
  },
  {
    path: '/travel',
    element: <Requests />,
  },
  {
    path: '/delivery',
    element: <DeliveryRequests />,
  },
  {
    path: '/admins',
    element: <Admins />,
  },
  {
    path: '/promotions',
    element: <Promotions />,
  },
  {
    path: '/promocodes',
    element: <Promocodes />,
  },
  {
    path: '/travel/transports',
    element: <Transports />,
  },
  {
    path: '/travel/races',
    element: <Races />,
  },
  {
    path: '/travel/races/:raceId/trips',
    element: <RaceTripsPage />,
  },
  {
    path: '/travel/races/:raceId/tariffs',
    element: <RaceTariffsPage />,
  },
  {
    path: '/travel/races/:raceId/trips/:tripId/sales-control',
    element: <TripSalesControlPage />,
  },
  {
    path: '/travel/races/:raceId/trips/:tripId/tariffs',
    element: <TripTariffsPage />,
  },
  {
    path: '/travel/races/:raceId/schedule',
    element: <SchedulePage />,
  },
  {
    path: '/travel/races/:raceId/trips/:tripId/schedule',
    element: <TripSchedulePage />,
  },
  {
    path: '/travel/:tripId/requests',
    element: <TripItemPage />,
  },
  {
    path: '/travel/races/:raceId/planning',
    element: <RacePlanningPage />,
  },
  {
    path: '/travel/races/:raceId/planning/:planId/schedule',
    element: <PlanningSchedulePage />,
  },
  {
    path: '/travel/races/:raceId/planning/:planId/tariffs',
    element: <PlanningTariffsPage />,
  },
  {
    path: '/travel/drivers',
    element: <Drivers />,
  },
  {
    path: '/delivery/drivers',
    element: <DeliveryDrivers />,
  },
  {
    path: '/travel/countries',
    element: <Country />,
  },
  {
    path: '/delivery/countries',
    element: <DeliveryCountry />,
  },
  {
    path: '/travel/towns',
    element: <Town />,
  },
  {
    path: '/delivery/departments',
    element: <DeliveryDepartments />,
  },
  {
    path: '/delivery/routes',
    element: <DeliveryRoutes />,
  },
  {
    path: '/travel/stops',
    element: <Stops />,
  },
  {
    path: '/travel/consultations',
    element: <Consultations />,
  },
  {
    path: '/travel/news',
    element: <News />,
  },
  {
    path: '/travel/blog',
    element: <Blog />,
  },
  {
    path: '/users',
    element: <Users />,
  },
  {
    path: '*',
    element: <Error404 />,
  },
]
