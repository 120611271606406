import axios from 'axios'
import { town_api } from './town.api'

import { country_api } from './country.api'

import { auth_api } from './auth.api'
import { users_api } from './users.api'
import { admins_api } from './admins.api'
import { transports_api } from './transports.api'
import { races_api } from './races.api'
import { drivers_api } from './drivers.api'
import { trips_api } from './trips.api'
import { tripRequests_api } from './tripRequests.api'
import { planning_tasks_api } from './planningTasks.api'
import { consultations_api } from './consultations.api'
import { notifications_api } from './notifications.api'
import { stops_api } from './stops.api'
import { ACCESS_TOKEN } from '@/constants/constants'
import { department_api } from './department.api'
import { delivery_country_api } from './delivery_country.api'
import { routes_api } from './routes.api'
import { deliveryRequests_api } from './deliveryRequests.api'
import { promotion_api } from './promotion.api'
import { promocodes_api } from './promocode.api'
import { delivery_drivers_api } from './delivery_drivers.api'

export const api = axios.create({
  withCredentials: true,
  baseURL: `${process.env.REACT_APP_DEV_BASE_API_URL}/api`,
  headers: {
    'Content-Type': 'application/json',
  },
})

export const REQ = {
  town_api,
  country_api,
  auth_api,
  users_api,
  admins_api,
  transports_api,
  races_api,
  drivers_api,
  trips_api,
  tripRequests_api,
  planning_tasks_api,
  consultations_api,
  notifications_api,
  stops_api,
  department_api,
  delivery_country_api,
  routes_api,
  deliveryRequests_api,
  promotion_api,
  promocodes_api,
  delivery_drivers_api,
}

api.interceptors.request.use(
  async (config) => {
    const token = localStorage.getItem(ACCESS_TOKEN)

    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`
    }

    return config
  },

  (error) => Promise.reject(error)
)

// api.interceptors.response.use(
//     (response) => response,
//     async (error) => {
//         const originalRequest = error.config;
//         if (error.response) {
//             // ? Access Token was expired
//             if (error.response.status === 401 && !originalRequest._retry) {
//                 originalRequest._retry = true;

//                 await REQ.auth_api
//                     .refresh()
//                     .then((res) => {
//                         if (res.status === 200) {
//                             return api(originalRequest);
//                         }
//                     })
//                     .catch((err) => {
//                         console.log("originalRequest._retry", originalRequest);

//                         if (err.response && err.response.data) {
//                             return Promise.reject(err.response.data);
//                         }
//                         return Promise.reject(err);
//                     });
//             }
//             if (error.response.status === 403 && error.response.data) {
//                 return Promise.reject(error.response.data);
//             }
//         }
//         return Promise.reject(error);
//     }
// );
