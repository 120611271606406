// ? hooks
import { useEffect } from 'react'

import useAsyncWrapper from '@/hooks/useAsyncWrapper'

import { Grid, Button, TableContainer, Table, TableHead, TableBody, Box } from '@mui/material'

import CreateRouteModal from './modals/CreateRouteModal'

import TablePaginationCustom from '@/components/UI/TablePaginationCustom/TablePaginationCustom'
import LoadingTableData from '@/components/UI/LoadingTableData/LoadingTableData'

import { Add } from '@mui/icons-material'

import EditRouteModal from './modals/EditRouteModal'
import { routesStore } from '@/store/routesStore'
import DeliveryRouteTabelRow from './DeliveryRouteTabelRow'
import DeliveryRouteTabelHeader from './DeliveryRouteTabelHeader'
import DeleteRouteModal from './modals/DeleteRouteModal'

const DeliveryRoutesTabel = () => {
  const { getAllRoutes, createRoute, editRoute, deleteRoute, routes, routesMeta, isLoading } = routesStore()

  const handleOpenCreateModal = () => {
    createRoute.handleOpenModal()
  }

  const getRoutesApiHandler = useAsyncWrapper(getAllRoutes, 'getRoutesApiHandler')

  useEffect(() => {
    const fetchData = async () => {
      const { error: getRoutesError } = await getRoutesApiHandler([])

      if (getRoutesError) {
        return false
      }
    }

    fetchData()
  }, [])

  return (
    <Grid container spacing={1} sx={{ pr: 1 }}>
      {createRoute.isOpenModal && <CreateRouteModal />}
      {editRoute.isOpenModal && <EditRouteModal />}
      {deleteRoute.isOpenModal && <DeleteRouteModal />}

      <Grid item xs={12}>
        <Box>
          <Button
            variant="contained"
            color="primary"
            size="small"
            className="createUserBtn"
            endIcon={<Add className="addIcon" />}
            onClick={handleOpenCreateModal}>
            Додати маршрут
          </Button>
        </Box>
        {routes?.length > 0 ? (
          <TableContainer>
            <Table
              stickyHeader
              sx={{
                maxHeight: 'calc(100vh - 250px)',
                width: '100%',
                maxWidth: '100%',
                overflow: 'scroll',
                mt: 1,
              }}>
              <TableHead>
                <DeliveryRouteTabelHeader />
              </TableHead>
              <TableBody>
                {!isLoading ? (
                  routes.map((item) => <DeliveryRouteTabelRow route={item} key={item?.route_id} />)
                ) : (
                  <LoadingTableData columns={7} rows={5} />
                )}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: 'calc(100vh - 250px)',
              gap: 2,
            }}>
            <p>Дані відсутні</p>
            <Button
              variant="contained"
              color="primary"
              size="small"
              className="createUserBtn"
              endIcon={<Add className="addIcon" />}
              onClick={handleOpenCreateModal}>
              Додати маршрут
            </Button>
          </Box>
        )}
        {routes && routes?.length !== 0 && <TablePaginationCustom getData={getRoutesApiHandler} meta={routesMeta} />}
      </Grid>
    </Grid>
  )
}
export default DeliveryRoutesTabel
