import { TableRow, TableCell } from '@mui/material'
import React from 'react'

const DeliveryRequestsToAbroadHeader = () => {
  const headerTitles = [
    {
      title: 'Статус',
      name: 'status',
    },
    {
      title: 'Номер замовлення',
      name: 'order_id',
    },
    {
      title: 'Спосіб відправлення',
      name: '',
    },
    {
      title: 'Ім’я отримувача',
      name: 'receiver_name',
    },
    {
      title: 'Адреса доставки',
      name: 'receiver_address',
    },
    {
      title: 'Телефон отримувача',
      name: 'receiver_phone',
    },
    {
      title: 'Спосіб оплати',
      name: 'payment_method',
    },
    {
      title: 'Сума доставки',
      name: 'delivery_price',
    },
    {
      title: 'Знижка',
      name: 'discount',
    },
    {
      title: 'Страхування',
      name: 'insurance',
    },
    {
      title: 'Промокод',
      name: 'promocode',
    },
    {
      title: 'Кількість',
      name: 'quantity',
    },
    {
      title: 'Вага',
      name: 'weight',
    },
    {
      title: 'Примітки',
      name: 'notes',
    },
    {
      title: 'Дії',
      name: '',
    },
  ]
  return (
    <TableRow>
      {headerTitles.map(({ title }) => (
        <TableCell
          key={title}
          sx={{
            backgroundColor: '#222222 !important',
          }}>
          {title}
        </TableCell>
      ))}
    </TableRow>
  )
}

export default DeliveryRequestsToAbroadHeader
