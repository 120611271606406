import { Typography } from '@mui/material'
import UserTable from '@/components/Tabels/UserTabel/UserTable'
import TravelWrapper from '@/components/Wrappers/TravelWrapper'

const Users = () => {
  // const { currentAdmin } = adminStore();

  // const rules = currentAdmin?.rules ? currentAdmin.rules : [];
  const VIEW_TRADERS = `rules.includes("View_traders");`

  return (
    <TravelWrapper>
      {VIEW_TRADERS ? (
        <UserTable />
      ) : (
        <Typography variant="h4" color="error">
          You don&apos;t have permission to view traders
        </Typography>
      )}
    </TravelWrapper>
  )
}

export default Users
