import { api } from '@/api'
import { CreateDeliveryDTO, IDeliveryRequest } from '@/interfaces/deliveryRequests'

export const deliveryRequests_api = {
  getDeliveryRequests: ({
    delivery_type,
    origin_city,
    sender_phone,
    status,
  }: {
    origin_city?: string
    delivery_type?: string
    sender_phone?: string
    status?: string
  }) => {
    return api.get('/admin/delivery/orders', {
      params: {
        delivery_type,
        origin_city,
        sender_phone,
        status,
      },
    })
  },
  editDeliveryRequest: (id: number, changes: Partial<IDeliveryRequest>) => {
    return api.put(`/admin/delivery/orders/${id}`, changes)
  },
  createDeliveryRequest: (data: CreateDeliveryDTO) => {
    return api.post(`/delivery/create`, data)
  },
}
