import { api } from '@/api'
import { IMeta } from '@/interfaces/general'
import { ITransport, ITransportCreateDTO } from '@/interfaces/transport'

// ? routes prefixes
import { PREFIXES } from '@/routes/prefixes'
import { apiCreator } from '@/utils/apiCreator'
import { AxiosHeaders } from 'axios'

const requestCreator = apiCreator(PREFIXES.CARS)

export const transports_api = {
  getAllTransports: async () => requestCreator(api, '', 'GET'),

  createTransport: async (body: ITransportCreateDTO) =>
    requestCreator(api, '', 'POST', body, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }),

  updateTransport: async (id: string, body: Partial<ITransport>) => requestCreator(api, `${id}`, 'PUT', body),

  deleteTransport: async (id: string) => requestCreator(api, `${id}`, 'DELETE'),

  deletePhotoTransport: async (transportId: string, fileName: string) =>
    requestCreator(api, `delete-file/${transportId}/${fileName}`, 'DELETE'),

  addPhotoTransport: async (transportId: string, body: any) =>
    requestCreator(api, `add-file/${transportId}`, 'POST', body),

  getById: async (id: string) => requestCreator(api, `${id}`, 'GET'),

  getTransportPhotos: async (id: number) => {
    return api.get(`/travel/cars/${id}/photo`)
  },
}
