import React, { useEffect, useState } from 'react'

import { Grid, FormControlLabel, Switch, Box, Tab, Button, Input, FormLabel, Select, MenuItem } from '@mui/material'

import useAsyncWrapper from '@/hooks/useAsyncWrapper'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import { CreateDeliveryRequest } from './modals/CreateDeliveryRequest'
import { deliveryRequestsStore } from '@/store/deliveryRequestsStore'
import { EditDeliveryRequest } from './modals/EditDeliveryRequest'
import { DeliveryRequestStatus, DeliveryType } from '@/interfaces/deliveryRequests'
import { departmentStore } from '@/store/departmentStore'
import { PreviewRequestModal } from './modals/PreviewModelRequest'
import { DeliveryRequestToAbroadTable } from './tables/DeliveryRequestsToAbroad/DeliveryRequestToAbroadTable'
import { DeliveryRequestsToUkraineTable } from './tables/DeliveryRequestsToUkraine/DeliveryRequestsToUkraineTable'
import { DeliveryRequestsCityToCityTable } from './tables/DeliveryRequestsCityToCity/DeliveryRequestsCityToCityTable'
import { deliveryDriverStore } from '@/store/deliveryDriverStore'
import { deliveryCountryStore } from '@/store/deliveryCountryStore'
import useDebounce from '@/hooks/useDebounce'

const DeliveryRequestsTable = () => {
  const [isReloading, setIsReloading] = useState(false)
  const { createModal } = deliveryRequestsStore()
  const { handleOpen } = createModal
  const { getDeliveryRequests, deliveryRequestsMeta, isLoading, deliveryRequests } = deliveryRequestsStore()
  const { drivers, getAllDrivers } = deliveryDriverStore()
  const { countries, getAllCountries } = deliveryCountryStore()
  const { getAllDepartments, departments } = departmentStore()

  const [status, setStatus] = useState<DeliveryRequestStatus>('created')
  const [deliveryType, setDeliveryType] = useState<DeliveryType>('abroad_to_ukraine')
  const [originCity, setOriginCity] = useState<string>('')
  const [deliveryCity, setDeliveryCity] = useState<string>('')
  const [senderPhone, setSenderPhone] = useState<string>('')
  const [receiverPhone, setReceiverPhone] = useState<string>('')
  const [senderName, setSenderName] = useState<string>('')
  const [receiverName, setReceiverName] = useState<string>('')
  const [orderId, setOrderId] = useState<number | null>(null)
  const [dateFrom, setDateFrom] = useState<string>('')
  const [dateTo, setDateTo] = useState<string>('')
  const [weightCategory, setWeightCategory] = useState<string>('')
  const [selectedDriver, setSelectedDriver] = useState<number | null>(null)
  const [selectedDepartment, setSelectedDepartment] = useState<number | null>(null)
  const [selectedCountry, setSelectedCountry] = useState<number | null>(null)

  const debouncedOriginCity = useDebounce(originCity, 500)
  const debouncedDeliveryCity = useDebounce(deliveryCity, 500)
  const debouncedSenderPhone = useDebounce(senderPhone, 500)
  const debouncedReceiverPhone = useDebounce(receiverPhone, 500)
  const debouncedSenderName = useDebounce(senderName, 500)
  const debouncedReceiverName = useDebounce(receiverName, 500)
  const debouncedOrderId = useDebounce(orderId, 500)
  const debouncedWeightCategory = useDebounce(weightCategory, 500)

  const handleChangeStatusTab = (event: React.SyntheticEvent, newValue: DeliveryRequestStatus) => {
    setStatus(newValue)
  }

  const handleChangeDeliveryType = (event: React.SyntheticEvent, newValue: string) => {
    setDeliveryType(newValue as DeliveryType)
  }

  const getDeliveryRequestsApiHandler = useAsyncWrapper(getDeliveryRequests, 'getDeliveryRequests')
  const getAllDepartmentsApiHandler = useAsyncWrapper(getAllDepartments, 'getAllDepartments')
  const getAllDriversApiHandler = useAsyncWrapper(getAllDrivers, 'getAllDrivers')
  const getAllCountriesApiHandler = useAsyncWrapper(getAllCountries, 'getAllCountries')

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsReloading(event.target.checked)
  }

  useEffect(() => {
    const fetchData = async () => {
      const { error: getDeliveryRequestsError } = await getDeliveryRequestsApiHandler([
        {
          status,
          delivery_type: deliveryType,
          order_id: debouncedOrderId,
          origin_city: debouncedOriginCity,
          delivery_city: debouncedDeliveryCity,
          sender_phone: debouncedSenderPhone,
          receiver_phone: debouncedReceiverPhone,
          sender_name: debouncedSenderName,
          receiver_name: debouncedReceiverName,
          date_from: dateFrom,
          date_to: dateTo,
          weight_category: debouncedWeightCategory,
          driver_id: selectedDriver,
          department_id: selectedDepartment,
          country_id: selectedCountry,
        },
      ])

      if (getDeliveryRequestsError) {
        return
      }
    }

    fetchData()
  }, [
    status,
    deliveryType,
    debouncedOrderId,
    debouncedOriginCity,
    debouncedDeliveryCity,
    debouncedSenderPhone,
    debouncedReceiverPhone,
    debouncedSenderName,
    debouncedReceiverName,
    dateFrom,
    dateTo,
    debouncedWeightCategory,
    selectedDriver,
    selectedDepartment,
    selectedCountry,
  ])

  useEffect(() => {
    const fetchData = async () => {
      const { error: getAllDepartmentsError } = await getAllDepartmentsApiHandler([])

      if (getAllDepartmentsError) {
        return
      }

      const { error: getAllDriversError } = await getAllDriversApiHandler([])

      if (getAllDriversError) {
        return
      }

      const { error: getAllCountriesError } = await getAllCountriesApiHandler([])

      if (getAllCountriesError) {
        return
      }
    }

    fetchData()
  }, [])

  const getRequestInInterval = async () => {
    const { error: getDeliveryRequestsError } = await getDeliveryRequestsApiHandler([
      { status, delivery_type: deliveryType },
    ])

    const timeOut = setTimeout(() => {
      getRequestInInterval()
      clearTimeout(timeOut)
    }, 60000)

    if (getDeliveryRequestsError) {
      return
    }
  }

  useEffect(() => {
    if (isReloading) {
      getRequestInInterval()
    }
  }, [isReloading, status, deliveryType])

  return (
    <Grid container spacing={1} sx={{ pr: 1 }}>
      <CreateDeliveryRequest status={status} deliveryType={deliveryType} />
      <EditDeliveryRequest status={status} deliveryType={deliveryType} />
      <PreviewRequestModal />
      <Grid item xs={12}>
        <FormControlLabel
          control={<Switch checked={isReloading} onChange={handleChange} />}
          label="Автооновлення через 60сек"
        />
        <Box
          sx={{
            display: 'flex',
            alignItems: 'flex-start',
            gap: '20px',
            mt: 1,
            mb: 1,
            px: 2,
          }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
            }}>
            <FormLabel>Пошук по ID</FormLabel>
            <Input
              value={orderId ? orderId.toString() : ''}
              onChange={(e) => setOrderId(e.target.value ? parseInt(e.target.value) : null)}
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
            }}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
              }}>
              <FormLabel>Пошук по місту відправлення</FormLabel>
              <Input value={originCity} onChange={(e) => setOriginCity(e.target.value)} />
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
              }}>
              <FormLabel>Пошук по місту доставки</FormLabel>
              <Input value={deliveryCity} onChange={(e) => setDeliveryCity(e.target.value)} />
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
            }}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
              }}>
              <FormLabel>Пошук по телефону відправника</FormLabel>
              <Input value={senderPhone} onChange={(e) => setSenderPhone(e.target.value)} />
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
              }}>
              <FormLabel>Пошук по телефону отримувача</FormLabel>
              <Input value={receiverPhone} onChange={(e) => setReceiverPhone(e.target.value)} />
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
            }}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
              }}>
              <FormLabel>Пошук по імені відправника</FormLabel>
              <Input value={senderName} onChange={(e) => setSenderName(e.target.value)} />
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
              }}>
              <FormLabel>Пошук по імені отримувача</FormLabel>
              <Input value={receiverName} onChange={(e) => setReceiverName(e.target.value)} />
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
            }}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
              }}>
              <FormLabel>Пошук по даті від</FormLabel>
              <Input type="date" value={dateFrom} onChange={(e) => setDateFrom(e.target.value)} />
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
              }}>
              <FormLabel>Пошук по даті до</FormLabel>
              <Input
                type="date"
                placeholder="Пошук по даті до"
                value={dateTo}
                onChange={(e) => setDateTo(e.target.value)}
              />
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
            }}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
              }}>
              <FormLabel>Пошук по вазі</FormLabel>
              <Input value={weightCategory} onChange={(e) => setWeightCategory(e.target.value)} />
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              gap: 2,
            }}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '200px',
              }}>
              <FormLabel>Водій</FormLabel>
              <Select
                value={selectedDriver}
                onChange={(e) => setSelectedDriver(e.target.value as number)}
                name="driver"
                placeholder="Водій">
                {drivers?.map((driver) => {
                  return (
                    <MenuItem key={driver.driver_id} value={driver.driver_id}>
                      {driver.pib}
                    </MenuItem>
                  )
                })}
              </Select>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '200px',
              }}>
              <FormLabel>Країна</FormLabel>
              <Select
                value={selectedCountry}
                onChange={(e) => setSelectedCountry(e.target.value as number)}
                name="country"
                placeholder="Країна">
                {countries?.map((country) => {
                  return (
                    <MenuItem key={country.country_id} value={country.country_id}>
                      {country.country_name_ua}
                    </MenuItem>
                  )
                })}
              </Select>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '200px',
              }}>
              <FormLabel>Відділення</FormLabel>
              <Select
                value={selectedDepartment}
                onChange={(e) => setSelectedDepartment(e.target.value as number)}
                name="department"
                placeholder="Відділення">
                {departments?.map((department) => {
                  return (
                    <MenuItem key={department.department_id} value={department.department_id}>
                      {department.country_name_ua}, {department.address}
                    </MenuItem>
                  )
                })}
              </Select>
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
            }}>
            <Button
              onClick={() => {
                getDeliveryRequestsApiHandler([
                  {
                    status,
                    delivery_type: deliveryType,
                    order_id: orderId,
                    origin_city: originCity,
                    delivery_city: deliveryCity,
                    sender_phone: senderPhone,
                    receiver_phone: receiverPhone,
                    sender_name: senderName,
                    receiver_name: receiverName,
                    date_from: dateFrom,
                    date_to: dateTo,
                    weight_category: weightCategory,
                    driver_id: selectedDriver,
                    department_id: selectedDepartment,
                    country_id: selectedCountry,
                    exportAs: 'pdf',
                  },
                ])
              }}
              variant="contained"
              color="primary">
              Експорт в PDF
            </Button>
            <Button
              onClick={() => {
                setOrderId(null)
                setOriginCity('')
                setDeliveryCity('')
                setSenderPhone('')
                setReceiverPhone('')
                setSenderName('')
                setReceiverName('')
                setDateFrom('')
                setDateTo('')
                setWeightCategory('')
                setSelectedDriver(null)
                setSelectedDepartment(null)
                setSelectedCountry(null)
              }}>
              Очистити фільтри
            </Button>
          </Box>
        </Box>
        <Grid item xs={12}>
          <Button
            onClick={handleOpen}
            variant="contained"
            color="primary"
            sx={{
              mt: 2,
            }}>
            Створити заявку
          </Button>
        </Grid>
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            mt: 2,
            gap: 2,
            pt: 2,
            borderTop: 1,
            borderColor: 'divider',
          }}>
          <TabContext value={deliveryType}>
            <Box sx={{ borderRight: 1, borderColor: 'divider', width: '250px' }}>
              <TabList onChange={handleChangeDeliveryType} orientation="vertical">
                <Tab value="abroad_to_ukraine" label="в Україну" />
                <Tab value="ukraine_to_abroad" label="З України" />
                <Tab value="city_to_city" label="Міжміст" />
              </TabList>
            </Box>
            <TabPanel
              value="abroad_to_ukraine"
              sx={{
                p: 0,
              }}>
              <TabContext value={status}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <TabList onChange={handleChangeStatusTab}>
                    <Tab label="Прийнято" value="created" />
                    <Tab label="В обробці" value="ongoing" />
                    <Tab label="Оформлено на забір" value="ready_for_pickup" />
                    <Tab label="Прямує в Україну" value="going_to_ukraine" />
                    <Tab label="Відправлено Новою Поштою" value="sent_by_nova_poshta" />
                    <Tab label="У відділенні" value="in_department" />
                    <Tab label="Завершені" value="completed" />
                    <Tab label="Відхилені" value="canceled" />
                  </TabList>
                </Box>
                <TabPanel
                  value="created"
                  sx={{
                    p: 0,
                  }}>
                  <DeliveryRequestsToUkraineTable
                    deliveryRequests={deliveryRequests}
                    isLoading={isLoading}
                    getDeliveryRequestsApiHandler={getDeliveryRequestsApiHandler}
                    deliveryRequestsMeta={deliveryRequestsMeta}
                    status={status}
                  />
                </TabPanel>
                <TabPanel
                  value="ongoing"
                  sx={{
                    p: 0,
                  }}>
                  <DeliveryRequestsToUkraineTable
                    deliveryRequests={deliveryRequests}
                    isLoading={isLoading}
                    getDeliveryRequestsApiHandler={getDeliveryRequestsApiHandler}
                    deliveryRequestsMeta={deliveryRequestsMeta}
                    status={status}
                  />
                </TabPanel>
                <TabPanel
                  value="ready_for_pickup"
                  sx={{
                    p: 0,
                  }}>
                  <DeliveryRequestsToUkraineTable
                    deliveryRequests={deliveryRequests}
                    isLoading={isLoading}
                    getDeliveryRequestsApiHandler={getDeliveryRequestsApiHandler}
                    deliveryRequestsMeta={deliveryRequestsMeta}
                    status={status}
                  />
                </TabPanel>
                <TabPanel
                  value="going_to_ukraine"
                  sx={{
                    p: 0,
                  }}>
                  <DeliveryRequestsToUkraineTable
                    deliveryRequests={deliveryRequests}
                    isLoading={isLoading}
                    getDeliveryRequestsApiHandler={getDeliveryRequestsApiHandler}
                    deliveryRequestsMeta={deliveryRequestsMeta}
                    status={status}
                  />
                </TabPanel>
                <TabPanel
                  value="sent_by_nova_poshta"
                  sx={{
                    p: 0,
                  }}>
                  <DeliveryRequestsToUkraineTable
                    deliveryRequests={deliveryRequests}
                    isLoading={isLoading}
                    getDeliveryRequestsApiHandler={getDeliveryRequestsApiHandler}
                    deliveryRequestsMeta={deliveryRequestsMeta}
                    status={status}
                  />
                </TabPanel>
                <TabPanel
                  value="in_department"
                  sx={{
                    p: 0,
                  }}>
                  <DeliveryRequestsToUkraineTable
                    deliveryRequests={deliveryRequests}
                    isLoading={isLoading}
                    getDeliveryRequestsApiHandler={getDeliveryRequestsApiHandler}
                    deliveryRequestsMeta={deliveryRequestsMeta}
                    status={status}
                  />
                </TabPanel>
                <TabPanel
                  value="completed"
                  sx={{
                    p: 0,
                  }}>
                  <DeliveryRequestsToUkraineTable
                    deliveryRequests={deliveryRequests}
                    isLoading={isLoading}
                    getDeliveryRequestsApiHandler={getDeliveryRequestsApiHandler}
                    deliveryRequestsMeta={deliveryRequestsMeta}
                    status={status}
                  />
                </TabPanel>
                <TabPanel
                  value="canceled"
                  sx={{
                    p: 0,
                  }}>
                  <DeliveryRequestsToUkraineTable
                    deliveryRequests={deliveryRequests}
                    isLoading={isLoading}
                    getDeliveryRequestsApiHandler={getDeliveryRequestsApiHandler}
                    deliveryRequestsMeta={deliveryRequestsMeta}
                    status={status}
                  />
                </TabPanel>
              </TabContext>
            </TabPanel>
            <TabPanel
              value="ukraine_to_abroad"
              sx={{
                p: 0,
              }}>
              <TabContext value={status}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <TabList onChange={handleChangeStatusTab}>
                    <Tab label="Прийнято" value="created" />
                    <Tab label="В процесі" value="ongoing" />
                    <Tab label="Прямує до отримувача" value="going_to_receiver" />
                    <Tab label="Прямує до відділення" value="going_to_department" />
                    <Tab label="У відділенні" value="in_department" />
                    <Tab label="Завершені" value="completed" />
                    <Tab label="Відхилені" value="canceled" />
                  </TabList>
                </Box>
                <TabPanel
                  value="created"
                  sx={{
                    p: 0,
                  }}>
                  <DeliveryRequestToAbroadTable
                    deliveryRequests={deliveryRequests}
                    isLoading={isLoading}
                    getDeliveryRequestsApiHandler={getDeliveryRequestsApiHandler}
                    deliveryRequestsMeta={deliveryRequestsMeta}
                    status={status}
                  />
                </TabPanel>
                <TabPanel
                  value="ongoing"
                  sx={{
                    p: 0,
                  }}>
                  <DeliveryRequestToAbroadTable
                    deliveryRequests={deliveryRequests}
                    isLoading={isLoading}
                    getDeliveryRequestsApiHandler={getDeliveryRequestsApiHandler}
                    deliveryRequestsMeta={deliveryRequestsMeta}
                    status={status}
                  />
                </TabPanel>
                <TabPanel
                  value="going_to_receiver"
                  sx={{
                    p: 0,
                  }}>
                  <DeliveryRequestToAbroadTable
                    deliveryRequests={deliveryRequests}
                    isLoading={isLoading}
                    getDeliveryRequestsApiHandler={getDeliveryRequestsApiHandler}
                    deliveryRequestsMeta={deliveryRequestsMeta}
                    status={status}
                  />
                </TabPanel>
                <TabPanel
                  value="going_to_department"
                  sx={{
                    p: 0,
                  }}>
                  <DeliveryRequestToAbroadTable
                    deliveryRequests={deliveryRequests}
                    isLoading={isLoading}
                    getDeliveryRequestsApiHandler={getDeliveryRequestsApiHandler}
                    deliveryRequestsMeta={deliveryRequestsMeta}
                    status={status}
                  />
                </TabPanel>
                <TabPanel
                  value="in_department"
                  sx={{
                    p: 0,
                  }}>
                  <DeliveryRequestToAbroadTable
                    deliveryRequests={deliveryRequests}
                    isLoading={isLoading}
                    getDeliveryRequestsApiHandler={getDeliveryRequestsApiHandler}
                    deliveryRequestsMeta={deliveryRequestsMeta}
                    status={status}
                  />
                </TabPanel>
                <TabPanel
                  value="completed"
                  sx={{
                    p: 0,
                  }}>
                  <DeliveryRequestToAbroadTable
                    deliveryRequests={deliveryRequests}
                    isLoading={isLoading}
                    getDeliveryRequestsApiHandler={getDeliveryRequestsApiHandler}
                    deliveryRequestsMeta={deliveryRequestsMeta}
                    status={status}
                  />
                </TabPanel>
                <TabPanel
                  value="canceled"
                  sx={{
                    p: 0,
                  }}>
                  <DeliveryRequestToAbroadTable
                    deliveryRequests={deliveryRequests}
                    isLoading={isLoading}
                    getDeliveryRequestsApiHandler={getDeliveryRequestsApiHandler}
                    deliveryRequestsMeta={deliveryRequestsMeta}
                    status={status}
                  />
                </TabPanel>
              </TabContext>
            </TabPanel>
            <TabPanel
              value="city_to_city"
              sx={{
                p: 0,
              }}>
              <TabContext value={status}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <TabList onChange={handleChangeStatusTab}>
                    <Tab label="Нові замовлення" value="created" />
                    <Tab label="В процесі" value="ongoing" />
                    <Tab label="Завершені" value="completed" />
                    <Tab label="Відхилені" value="canceled" />
                  </TabList>
                </Box>
                <TabPanel
                  value="created"
                  sx={{
                    p: 0,
                  }}>
                  <DeliveryRequestsCityToCityTable
                    deliveryRequests={deliveryRequests}
                    isLoading={isLoading}
                    getDeliveryRequestsApiHandler={getDeliveryRequestsApiHandler}
                    deliveryRequestsMeta={deliveryRequestsMeta}
                    status={status}
                  />
                </TabPanel>
                <TabPanel
                  value="ongoing"
                  sx={{
                    p: 0,
                  }}>
                  <DeliveryRequestsCityToCityTable
                    deliveryRequests={deliveryRequests}
                    isLoading={isLoading}
                    getDeliveryRequestsApiHandler={getDeliveryRequestsApiHandler}
                    deliveryRequestsMeta={deliveryRequestsMeta}
                    status={status}
                  />
                </TabPanel>
                <TabPanel
                  value="completed"
                  sx={{
                    p: 0,
                  }}>
                  <DeliveryRequestsCityToCityTable
                    deliveryRequests={deliveryRequests}
                    isLoading={isLoading}
                    getDeliveryRequestsApiHandler={getDeliveryRequestsApiHandler}
                    deliveryRequestsMeta={deliveryRequestsMeta}
                    status={status}
                  />
                </TabPanel>
                <TabPanel
                  value="canceled"
                  sx={{
                    p: 0,
                  }}>
                  <DeliveryRequestsCityToCityTable
                    deliveryRequests={deliveryRequests}
                    isLoading={isLoading}
                    getDeliveryRequestsApiHandler={getDeliveryRequestsApiHandler}
                    deliveryRequestsMeta={deliveryRequestsMeta}
                    status={status}
                  />
                </TabPanel>
              </TabContext>
            </TabPanel>
          </TabContext>
        </Box>
      </Grid>
    </Grid>
  )
}

export default DeliveryRequestsTable
