import { Grid, Table, TableBody, TableContainer, TableHead } from '@mui/material'

import UserTableHeader from './UserTableHeader/UserTableHeader'
import UserTableRow from './UserTableRow'
import UserTablePagination from './UserTablePagination'
import UserTableFastFilter from './UserTableFastFilter'

import { usersStore } from '@/store/usersStore'
import { tableHeadContainer } from './styles'
import NoDataHelper from '@/components/UI/NoDataHelper/NoDataHelper'
import LoadingTableData from '@/components/UI/LoadingTableData/LoadingTableData'
import { UserRacesModal } from './modal/UserRacesModal'

const UserTable = () => {
  const { users, filterQuery, setFilterQuery, isLoading, isUserRacesModalOpen, setIsUserRacesModalOpen } = usersStore()

  const handleCloseUserRacesModal = () => {
    setIsUserRacesModalOpen(false)
  }

  return (
    <Grid container spacing={1} sx={{ pr: 1 }}>
      <Grid item xs={12}>
        <UserTableFastFilter />
        <UserRacesModal isOpen={isUserRacesModalOpen} handleClose={handleCloseUserRacesModal} />
        <TableContainer>
          <Table
            stickyHeader
            sx={{
              maxHeight: 'calc(100vh - 250px)',
              width: '100%',
              maxWidth: '100%',
              overflow: 'scroll',
              mt: 1,
            }}>
            <TableHead sx={tableHeadContainer}>
              <UserTableHeader />
            </TableHead>
            <TableBody>
              {!isLoading ? (
                users.map((user: any) => <UserTableRow row={{ ...user }} key={user.pib} />)
              ) : (
                <LoadingTableData columns={6} rows={5} />
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {!users.length && filterQuery ? (
          <NoDataHelper message="No users found" filterQuery={filterQuery} setFilterQuery={setFilterQuery} />
        ) : null}
        <UserTablePagination />
      </Grid>
    </Grid>
  )
}

export default UserTable
