import { TableRow, TableCell } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'

const PromotionTabelHeader = () => {
  const { t } = useTranslation()

  const i18nTranslateHeaderString = 'transportsPage'

  const headerTitles = [
    {
      title: t(`${i18nTranslateHeaderString}.type`), // ? "Тип"
      name: 'promotion_type',
    },
    {
      title: 'Заголовок',
      name: 'promotion_title',
    },
    {
      title: 'Текст',
      name: 'promotion_text',
    },
    {
      title: 'Доступність',
      name: 'accessibility',
    },
    {
      title: 'Фото',
      name: 'photos',
    },
    {
      title: t(`${i18nTranslateHeaderString}.actions`), // ?"Кількість місць"
      name: '',
    },
  ]
  return (
    <TableRow>
      {headerTitles.map(({ title, name }) => (
        <TableCell
          key={title}
          sx={{
            backgroundColor: '#222222 !important',
          }}>
          {title}
        </TableCell>
      ))}
    </TableRow>
  )
}

export default PromotionTabelHeader
