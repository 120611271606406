// ? hooks
import { useState } from 'react'
import useAsyncWrapper from '@/hooks/useAsyncWrapper'
import useValidation from '@/hooks/useValidation'

// ? stores
import { countryStore } from '@/store/countryStore'
import { messageStore } from '@/store/store'

// ? interfaces
import { IDeliveryCountryCreateDTO } from '@/interfaces/country'

// ? utils
import { inputsChanger } from '@/utils/inputsChanger'

// ? components
import { Button, Box, TextField } from '@mui/material'
import { LoadingButton } from '@mui/lab'

import ModalContainer from '@/components/UI/Modal/ModalContainer'
import { DELIVERY_COUNTRY_VALIDATION } from '@/validation/delivery-country'
import { deliveryCountryStore } from '@/store/deliveryCountryStore'

const initialState: IDeliveryCountryCreateDTO = {
  country_name_en: '',
  country_name_ua: '',
}

const CreateDeliveryCountryModal = () => {
  const { addMessage } = messageStore()
  const { createDeliveryCountry, getAllCountries, countryMeta } = deliveryCountryStore()
  const { isOpenModal, handleCloseModal, createDeliveryCountryApiHandler } = createDeliveryCountry

  const [formValues, setFormValues] = useState(initialState)
  const [isLoading, setIsLoading] = useState(false)

  const inputChangeHandler = inputsChanger('input', formValues, setFormValues)

  const createCountryValidation = useValidation(DELIVERY_COUNTRY_VALIDATION.CREATE)
  const createCountryApiHandler = useAsyncWrapper(createDeliveryCountryApiHandler, 'createDeliveryCountryApiHandler')

  const getCountriesApiHandler = useAsyncWrapper(getAllCountries, 'getCountriesApiHandler')

  const handleClose = () => {
    setFormValues(initialState)
    handleCloseModal()
  }

  const createCountryHandler = async () => {
    setIsLoading(true)
    if (!createCountryValidation(formValues)) return

    const { error: createCountryError } = await createCountryApiHandler([formValues])

    if (createCountryError) {
      setIsLoading(false)
      return
    }

    const { error: getCountryError } = await getCountriesApiHandler([countryMeta])

    if (getCountryError) {
      setIsLoading(false)
      return
    }

    addMessage({
      severity: 'success',
      message: 'Успішно створено',
      isOpen: true,
    })

    setIsLoading(false)
    handleClose()
  }

  return (
    <ModalContainer width={400} isOpen={isOpenModal} handleClose={handleClose} title={'Додати країну'}>
      <TextField
        name="country_name_en"
        onChange={inputChangeHandler}
        fullWidth
        label="Назва країни англійською"
        variant="standard"
      />
      <TextField
        name="country_name_ua"
        onChange={inputChangeHandler}
        fullWidth
        label="Назва країни українською"
        variant="standard"
      />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          mt: 1,
        }}>
        <LoadingButton
          loading={isLoading}
          color="primary"
          variant="contained"
          size="small"
          onClick={createCountryHandler}>
          Створити
        </LoadingButton>
        <Button onClick={handleClose} color="error" variant="contained" size="small" sx={{ ml: 1 }}>
          Відмінити
        </Button>
      </Box>
    </ModalContainer>
  )
}

export default CreateDeliveryCountryModal
