import ModalContainer from '@/components/UI/Modal/ModalContainer'
import { ITransportCreateDTO } from '@/interfaces/transport'
import { messageStore } from '@/store/store'
import { transportStore } from '@/store/transportStore'
import { FormControl, InputLabel, Select, MenuItem, TextField, Box, Button } from '@mui/material'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import useAsyncWrapper from '@/hooks/useAsyncWrapper'
import { inputsChanger } from '@/utils/inputsChanger'
import { LoadingButton } from '@mui/lab'
import { TRANSPORT_VALIDATION } from '@/validation/transport'
import useValidation from '@/hooks/useValidation'
import { api } from '@/api'
import { IPromotionCreateDTO } from '@/interfaces/promotion'
import { promotionStore } from '@/store/promotionStore'

interface IProps {
  isOpen: boolean
  handleClose: () => void
}

const initialState: IPromotionCreateDTO = {
  accessibility: 0,
  promotion_photo: '',
  promotion_text: '',
  promotion_type: 'all',
  promotion_title: '',
}

const CreatePromotionModal = ({ handleClose, isOpen }: IProps) => {
  const { t } = useTranslation()

  const [formValues, setFormValues] = useState(initialState)
  const [isLoading, setIsLoading] = useState(false)
  const [file, setFile] = useState<File | null>(null)

  const { createPromotion, getAllPromotions, transportMeta } = promotionStore()
  const { addMessage } = messageStore()

  const getAllPromotionsApiHandler = useAsyncWrapper(getAllPromotions, 'getAllPromotionsApiHandler')
  const createPromotionApiHandler = useAsyncWrapper(createPromotion, 'createPromotionApiHandler')

  const handleChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, files } = e.target as HTMLInputElement

    if (type === 'file' && files) {
      setFile(files[0])
    } else {
      setFormValues((prevState) => ({
        ...prevState,
        [name]: type === 'number' ? Number(value) : value,
      }))
    }
  }

  const handleChangeSelect = inputsChanger('selectChanger', formValues, setFormValues) as (e: any) => void

  const handleCloseForm = () => {
    setFormValues(initialState)
    setFile(null)
    handleClose()
  }

  const createBusHandler = async () => {
    setIsLoading(true)

    const formData = new FormData()
    formData.append('promotion_title', formValues.promotion_title)
    formData.append('promotion_text', formValues.promotion_text)
    formData.append('promotion_type', formValues.promotion_type)
    formData.append('accessibility', formValues.accessibility ? '1' : '0')

    console.log('file', file)

    if (file) {
      formData.append('promotion_photo', file)
    }

    try {
      const { status } = await api.post('/admin/services/promotions', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })

      if (status !== 201) {
        throw new Error('Failed to create promotion')
      }

      const { error: getAllPromotionsError } = await getAllPromotionsApiHandler([transportMeta])

      if (getAllPromotionsError) {
        throw new Error(getAllPromotionsError.message)
      }

      addMessage({
        severity: 'success',
        message: 'Промоакція успішно створена',
        isOpen: true,
      })
      handleCloseForm()
    } catch (error) {
      console.error('Error:', error)
      addMessage({
        severity: 'error',
        message: 'Помилка при створенні промоакції',
        isOpen: true,
      })
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <ModalContainer isOpen={isOpen} handleClose={handleClose} title="Створення акції" width={600}>
      <Box
        sx={{
          display: 'flex',
          flexFlow: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '16px',
        }}>
        <FormControl fullWidth variant="standard">
          <InputLabel>Тип акції</InputLabel>
          <Select value={formValues.promotion_type || ''} name="promotion_type" onChange={handleChangeSelect}>
            <MenuItem value={'all'}>Всі</MenuItem>
            <MenuItem value={'travel'}>Подорожі</MenuItem>
            <MenuItem value={'delivery'}>Доставка</MenuItem>
          </Select>
        </FormControl>
        <TextField
          fullWidth
          label="Заголовок акції"
          onChange={handleChangeInput}
          value={formValues.promotion_title || ''}
          name="promotion_title"
          variant="standard"
          placeholder="Заголовок акції"
        />
        <TextField
          fullWidth
          label="Текст акції"
          onChange={handleChangeInput}
          value={formValues.promotion_text || ''}
          name="promotion_text"
          variant="standard"
          placeholder="Текст акції"
        />
        <FormControl fullWidth variant="standard">
          <TextField
            type="file"
            id="promotion_photo"
            name="promotion_photo"
            onChange={handleChangeInput}
            style={{ marginTop: '8px' }}
          />
        </FormControl>
        <FormControl fullWidth variant="standard">
          <InputLabel>Активна</InputLabel>
          <Select value={formValues.accessibility || ''} name="accessibility" onChange={handleChangeSelect}>
            <MenuItem value={1}>Так</MenuItem>
            <MenuItem value={0}>Ні</MenuItem>
          </Select>
        </FormControl>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            mt: 1,
          }}>
          <LoadingButton
            loading={isLoading}
            color="primary"
            variant="contained"
            size="small"
            onClick={createBusHandler}>
            Створити
          </LoadingButton>
          <Button onClick={handleCloseForm} color="error" variant="contained" size="small" sx={{ ml: 1 }}>
            {t('transportsPage.cancelCreation')}
          </Button>
        </Box>
      </Box>
    </ModalContainer>
  )
}

export default CreatePromotionModal
