import { api } from '@/api'

import { ICreateRouteDTO } from '@/interfaces/route'

// ? routes prefixes
import { PREFIXES } from '@/routes/prefixes'

// ? utils
import { apiCreator } from '@/utils/apiCreator'

const requestCreator = apiCreator(PREFIXES.ROUTES)

export const routes_api = {
  getAllRoutes: async (origin_country_id?: number, destination_country_id?: number) =>
    await requestCreator(api, '', 'GET', undefined, { origin_country_id, destination_country_id }),

  createRoute: async (body: ICreateRouteDTO) => await requestCreator(api, '', 'POST', body),

  editRoute: async (id: string, body: ICreateRouteDTO) => await requestCreator(api, `${id}`, 'PUT', body),

  deleteRoute: async (id: string) => await requestCreator(api, `${id}`, 'DELETE'),
}
