import { api } from '@/api'

// ? routes prefixes
import { PREFIXES } from '@/routes/prefixes'

// ? utils
import { apiCreator } from '@/utils/apiCreator'

const requestCreator = apiCreator(PREFIXES.ADMINS)

export const admins_api = {
  getAllAdmins: async () => requestCreator(api, 'getAllAdmins', 'GET'),

  registerAdmin: async (body: Partial<any>) => requestCreator(api, 'register', 'POST', body),
  deleteAdmin: async (id: string) => requestCreator(api, `${id}`, 'DELETE'),
  checkAuth: () => requestCreator(api, 'auth/protected', 'GET'),
}
