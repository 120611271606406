// ? hooks
import { useState } from 'react'
import useAsyncWrapper from '@/hooks/useAsyncWrapper'

// ? stores
import { messageStore } from '@/store/store'

// ? utils
import { inputsChanger } from '@/utils/inputsChanger'

// ? components
import { Button, Box, TextField } from '@mui/material'
import { LoadingButton } from '@mui/lab'

import ModalContainer from '@/components/UI/Modal/ModalContainer'
import { deliveryDriverStore } from '@/store/deliveryDriverStore'

const EditDriverModal = () => {
  const [formValues, setFormValues] = useState<{
    pib: string
  }>({
    pib: '',
  })
  const [isLoading, setIsLoading] = useState(false)

  const { addMessage } = messageStore()
  const { editDeliveryDriver, getAllDrivers, countryMeta } = deliveryDriverStore()
  const { isOpenModal, handleCloseModal, editedItem } = editDeliveryDriver

  // ? changers
  const inputChangeHandler = inputsChanger('input', formValues, setFormValues)

  // ? api handlers
  const editDeliveryDriverApiHandler = useAsyncWrapper(
    editDeliveryDriver.editDeliveryDriverApiHandler,
    'editDeliveryDriverApiHandler'
  )

  const getDriversApiHandler = useAsyncWrapper(getAllDrivers, 'getDriversApiHandler')

  const handleClose = () => {
    setFormValues({
      pib: '',
    })
    handleCloseModal()
  }

  const editCountryHandler = async () => {
    setIsLoading(true)

    const { error: editDriverError } = await editDeliveryDriverApiHandler([editedItem?.driver_id, formValues])

    if (editDriverError) {
      setIsLoading(false)
      return
    }

    const { error: getDriversError } = await getDriversApiHandler([countryMeta])

    if (getDriversError) {
      setIsLoading(false)
      return
    }

    addMessage({
      severity: 'success',
      message: 'Успішно відредаговано',
      isOpen: true,
    })

    setIsLoading(false)
    handleClose()
  }

  return (
    <ModalContainer width={800} isOpen={isOpenModal} handleClose={handleClose} title={'Редагування водія'}>
      <TextField
        name="pib"
        onChange={inputChangeHandler}
        fullWidth
        value={formValues.pib || editedItem?.pib}
        label="ПІБ"
        variant="standard"
      />

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          mt: 1,
        }}>
        <LoadingButton
          loading={isLoading}
          color="primary"
          variant="contained"
          size="small"
          onClick={editCountryHandler}>
          Відредагувати
        </LoadingButton>
        <Button onClick={handleClose} color="error" variant="contained" size="small" sx={{ ml: 1 }}>
          Відмінити
        </Button>
      </Box>
    </ModalContainer>
  )
}

export default EditDriverModal
