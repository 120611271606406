import React from 'react'
import NewsTabel from '@/components/Tabels/NewsTabel/NewsTabel'
import TravelWrapper from '@/components/Wrappers/TravelWrapper'

const News = () => {
  return (
    <TravelWrapper>
      <NewsTabel />
    </TravelWrapper>
  )
}

export default News
