// ? hooks
import useAsyncWrapper from '@/hooks/useAsyncWrapper'

// ? store
import { adminStore } from '@/store/adminStore'

// ? components
import { Grid, TableContainer, Table, TableHead, TableBody, Box, Button } from '@mui/material'

import LoadingTableData from '@/components/UI/LoadingTableData/LoadingTableData'
import NoDataHelper from '@/components/UI/NoDataHelper/NoDataHelper'
import TablePaginationCustom from '@/components/UI/TablePaginationCustom/TablePaginationCustom'

import AdminTableHeader from './AdminTableHeader'
import AdminTableFastFilter from './AdminTableFastFilter'
import AdminTableRow from './AdminTableRow'

import AdminCreateModal from './modals/AdminCreateModal'
import AdminDeleteModal from './modals/AdminDeleteModal'

// ? icons
import { Add } from '@mui/icons-material'

const AdminTabel = () => {
  const { admins, filterQuery, setFilterQuery, isLoading, create, deleteAdminModal, getAllAdmins, meta } = adminStore()

  const getAdminsApiHandler = useAsyncWrapper(getAllAdmins, 'getAdminsApiHandler')

  const createNewAdmin = () => {
    create.toggleCreateAdmin()
  }

  return (
    <Grid container spacing={1} sx={{ pr: 1 }}>
      <Grid item xs={12}>
        <AdminTableFastFilter />
        {create?.isOpenModal && <AdminCreateModal />}
        {deleteAdminModal?.isOpenModal && <AdminDeleteModal />}
        <Box
          sx={{
            mt: 1,
          }}>
          <Button variant="contained" color="primary" size="small" onClick={createNewAdmin} endIcon={<Add />}>
            Створити нового адміна
          </Button>
        </Box>

        <TableContainer>
          <Table
            stickyHeader
            sx={{
              maxHeight: 'calc(100vh - 250px)',
              width: '100%',
              maxWidth: '100%',
              overflow: 'scroll',
              mt: 1,
            }}>
            <TableHead>
              <AdminTableHeader />
            </TableHead>
            <TableBody>
              {!isLoading ? (
                admins.map((admin: any) => <AdminTableRow row={{ ...admin }} key={admin._id} />)
              ) : (
                <LoadingTableData columns={4} rows={5} />
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {!admins?.length && filterQuery ? (
          <NoDataHelper
            message="Адміністраторів не знайдено"
            filterQuery={filterQuery}
            setFilterQuery={setFilterQuery}
          />
        ) : null}

        <TablePaginationCustom getData={getAdminsApiHandler} meta={meta} filterQuery={filterQuery} />
      </Grid>
    </Grid>
  )
}

export default AdminTabel
