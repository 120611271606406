// ? hooks
import { useState } from 'react'
import useAsyncWrapper from '@/hooks/useAsyncWrapper'
import useValidation from '@/hooks/useValidation'

// ? store
import { adminStore } from '@/store/adminStore'
import { messageStore } from '@/store/store'

// ? interfaces
import { IRegisterFormDTO } from '@/interfaces/auth'

// ? utils
import { inputsChanger } from '@/utils/inputsChanger'

// ? validation
import { AUTH_VALIDATION } from '@/validation/auth'

// ? components
import { Button, Box, TextField, InputLabel, Select, MenuItem } from '@mui/material'
import { LoadingButton } from '@mui/lab'

import { Roles } from '@/interfaces/auth'

import ModalContainer from '@/components/UI/Modal/ModalContainer'

const initialState: IRegisterFormDTO = {
  pib: '',
  email: '',
  password: '',
  phoneNumber: '',
  role: 'average_delivery',
}

const roles: Record<Roles, string> = {
  main_delivery: 'Головний делівері',
  main_travel: 'Головний травел',
  average_delivery: 'Середній делівері',
  average_travel: 'Середній травел',
  low_delivery: 'Низький делівері',
  low_travel: 'Низький травел',
}

const AdminCreateModal = () => {
  const [formValues, setFormValues] = useState(initialState)

  const { create, registerAdmin, getAllAdmins, meta, filterQuery } = adminStore()
  const { isOpenModal, isLoading, toggleCreateAdmin } = create
  const { addMessage } = messageStore()

  const validationRegisterHandler = useValidation(AUTH_VALIDATION.REGISTER)

  const getAdminsApiHandler = useAsyncWrapper(getAllAdmins, 'getAdminsApiHandler')
  const registerAdminApiHandler = useAsyncWrapper(registerAdmin, 'registerAdminApiHandler')

  const handleCloseForm = () => {
    toggleCreateAdmin()
  }

  const handleChangeInputs = inputsChanger('input', formValues, setFormValues) as any
  const selectChangeHandler = inputsChanger('selectChanger', formValues, setFormValues) as (e: any) => void

  const handleCreateNewAdmin = async () => {
    if (!validationRegisterHandler(formValues)) {
      return false
    }

    const { error: registerError } = await registerAdminApiHandler([formValues])

    if (registerError) {
      return false
    }

    const { error } = await getAdminsApiHandler([meta, filterQuery])

    if (error) {
      return false
    }

    handleCloseForm()
    addMessage({
      type: 'success',
      message: 'Адміна успішно створено',
      isOpen: true,
    })
  }

  return (
    <ModalContainer width={800} isOpen={isOpenModal} handleClose={handleCloseForm} title={`Створити нового адміна`}>
      <TextField fullWidth label={'ПІБ'} onChange={handleChangeInputs} name="pib" variant="standard" />
      <TextField fullWidth label={'Email'} onChange={handleChangeInputs} name="email" variant="standard" />
      <Box sx={{ mt: 1 }}>
        <InputLabel>Роль</InputLabel>
        <Select value={formValues.role || ''} onChange={selectChangeHandler} name="role" label="Роль">
          {Object.keys(roles).map((role) => (
            <MenuItem key={role} value={role}>
              {roles[role as Roles]}
            </MenuItem>
          ))}
        </Select>
      </Box>
      <TextField fullWidth label={'Пароль'} onChange={handleChangeInputs} name="password" variant="standard" />
      <TextField
        fullWidth
        label={'Номер телефону'}
        onChange={handleChangeInputs}
        name="phoneNumber"
        variant="standard"
      />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          mt: 1,
        }}>
        <LoadingButton
          loading={isLoading}
          color="primary"
          variant="contained"
          size="small"
          onClick={handleCreateNewAdmin}>
          Створити
        </LoadingButton>
        <Button onClick={handleCloseForm} color="error" variant="contained" size="small" sx={{ ml: 1 }}>
          Відмінити
        </Button>
      </Box>
    </ModalContainer>
  )
}

export default AdminCreateModal
