import { Table, TableBody, TableContainer, TableHead } from '@mui/material'
import DeliveryRequestsToAbroadHeader from './DeliveryRequestsToAbroadHeader'
import { IDeliveryRequest } from '@/interfaces/deliveryRequests'
import { IMeta } from '@/interfaces/general'
import DeliveryRequestsToAbroadRow from './DeliveryRequestsToAbroadRow'
import LoadingTableData from '@/components/UI/LoadingTableData/LoadingTableData'
import TablePaginationCustom from '@/components/UI/TablePaginationCustom/TablePaginationCustom'

export function DeliveryRequestToAbroadTable({
  deliveryRequests,
  deliveryRequestsMeta,
  isLoading,
  getDeliveryRequestsApiHandler,
  status,
}: {
  isLoading: boolean
  deliveryRequests: IDeliveryRequest[]
  deliveryRequestsMeta: IMeta
  getDeliveryRequestsApiHandler: any
  status: string
}) {
  return (
    <>
      <TableContainer>
        <Table
          stickyHeader
          sx={{
            maxHeight: 'calc(100vh - 250px)',
            width: '100%',
            maxWidth: '100%',
            overflow: 'scroll',
            mt: 1,
          }}>
          <TableHead>
            <DeliveryRequestsToAbroadHeader />
          </TableHead>
          <TableBody>
            {!isLoading ? (
              deliveryRequests?.map((deliveryRequest) => (
                <DeliveryRequestsToAbroadRow deliveryRequest={deliveryRequest} key={deliveryRequest.order_id} />
              ))
            ) : (
              <LoadingTableData columns={17} rows={5} />
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePaginationCustom
        getData={getDeliveryRequestsApiHandler}
        meta={deliveryRequestsMeta}
        filterQuery={{ status }}
      />
    </>
  )
}
