// ? hooks
import React, { useEffect, useState } from 'react'
import useAsyncWrapper from '@/hooks/useAsyncWrapper'

// ? store
import { countryStore } from '@/store/countryStore'
import { townStore } from '@/store/townStore'
import { racesStore } from '@/store/racesStore'
import { messageStore } from '@/store/store'

// ? components
import {
  Box,
  Button,
  Checkbox,
  Divider,
  RadioGroup,
  Radio,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from '@mui/material'

import ModalContainer from '@/components/UI/Modal/ModalContainer'

import { LoadingButton } from '@mui/lab'
import { inputsChanger } from '@/utils/inputsChanger'
import { validateForm } from '@/utils/formValidation'
import { formatDateString } from '@/utils/formatDate'

const RaceSalesModal = () => {
  const [isLoading, setIsLoading] = useState(false)
  const { getAllRaces, racesMeta, editRace, raceSalesModal } = racesStore()

  const { isOpenModal, editedItem, handleCloseModal } = raceSalesModal

  const { addMessage } = messageStore()

  const [formValues, setFormValues] = useState<any>({
    active_days: 0,
  })

  const [formErrors, setFormErrors] = useState<any>()
  const validationRules = {
    active_days: { required: true },
  }

  const editRaceApiHandler = useAsyncWrapper(editRace.editRaceApiHandler, 'editRaceApiHandler')
  const getAllRacesApiHandler = useAsyncWrapper(getAllRaces, 'getAllRacesApiHandler')

  const handleCloseForm = () => {
    handleCloseModal()
  }

  const editRaceSalesHandler = async () => {
    setIsLoading(true)

    const formErrors = validateForm(formValues, validationRules)
    if (Object.keys(formErrors).length !== 0) {
      setFormErrors(formErrors)
      return
    }

    const { error: editRaceSalesError } = await editRaceApiHandler([
      editedItem?.flight_id,
      {
        ...editedItem,
        ...formValues,
        active_days: Number(formValues.active_days),
        begins_at: formatDateString(editedItem?.begins_at!),
        expires_at: formatDateString(editedItem?.expires_at!),
      },
    ])

    if (editRaceSalesError) {
      setIsLoading(false)
      return
    }

    const { error: getAllRacesError } = await getAllRacesApiHandler([racesMeta])

    if (getAllRacesError) {
      setIsLoading(false)
      return
    }

    setIsLoading(false)
    addMessage({
      severity: 'success',
      message: 'Рейс успішно відредаговано',
      isOpen: true,
    })
    handleCloseForm()
  }

  useEffect(() => {
    setFormValues({ active_days: editedItem?.active_days })
  }, [])

  const handleInputChange = inputsChanger('input', formValues, setFormValues) as (e: any) => void

  return (
    <ModalContainer width={700} isOpen={isOpenModal} handleClose={handleCloseForm} title="Продаж/бронь">
      <Box
        sx={{
          display: 'flex',
          flexFlow: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '20px',
        }}>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            gap: '20px',
          }}>
          <FormControl fullWidth variant="standard">
            <InputLabel shrink>Глибина продажу</InputLabel>
            <TextField
              name="active_days"
              variant="standard"
              type="number"
              value={formValues?.active_days ?? 0}
              onChange={handleInputChange}
              sx={{
                mt: '16px',
              }}
              error={!!formErrors?.active_days}
              helperText={formErrors?.active_days}
            />
          </FormControl>
        </Box>

        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
            mt: 1,
          }}>
          <LoadingButton
            loading={isLoading}
            color="primary"
            variant="contained"
            size="small"
            onClick={editRaceSalesHandler}>
            Зберегти
          </LoadingButton>
          <Button onClick={handleCloseForm} color="error" variant="contained" size="small" sx={{ ml: 1 }}>
            Скасувати
          </Button>
        </Box>
      </Box>
    </ModalContainer>
  )
}

export default RaceSalesModal
