import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IPlan } from '@/interfaces/planning'
import { racesStore } from '@/store/racesStore'
import { getTranslateValue } from '@/utils/getTranslateValue'
import { TableRow, TableCell, Typography, Collapse, Box, Button, Menu, MenuItem } from '@mui/material'

import { Add, Edit, Delete, SportsScore, LocationOn, Money } from '@mui/icons-material'
import useAsyncWrapper from '@/hooks/useAsyncWrapper'
import { BoardingStatus } from '@/constants/constants'
import { formatDate } from '@/utils/formatDate'
import { IRace } from '@/interfaces/races'
import { planningStore } from '@/store/planningStore'
import { useNavigate } from 'react-router-dom'

interface IProps {
  plan: IPlan
}

const PlanningTabelRow = ({ plan }: IProps) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const { oneRace } = racesStore()

  const { planningMeta, getAllRacePlans, createPlanModal, editPlanModal, deletePlanModal, addRaceParamsModal } =
    planningStore()

  const [open, setOpen] = useState(false)
  const [anchorAddEl, setAnchorAddEl] = useState<null | HTMLElement>(null)
  const [anchorEditEl, setAnchorEditEl] = useState<null | HTMLElement>(null)
  const openAddMenu = Boolean(anchorAddEl)
  const openEditsMenu = Boolean(anchorEditEl)
  const [addMenuLinks, setAddMenuLinks] = useState<Array<string>>([])
  const [editMenuLinks, setEditMenuLinks] = useState<Array<string>>([])

  useEffect(() => {
    let addMenuLinksArr = addMenuLinks.filter((item) => item !== 'params')
    let editMenuLinksArr = editMenuLinks.filter((item) => item !== 'params')

    if (plan?.params) {
      editMenuLinksArr.push('params')
    } else {
      addMenuLinksArr.push('params')
    }

    if (plan?.raceStops) {
      editMenuLinksArr.push('raceStops')
    } else {
      addMenuLinksArr.push('raceStops')
    }

    if (plan?.tariffs) {
      editMenuLinksArr.push('tariffs')
    } else {
      addMenuLinksArr.push('tariffs')
    }

    setAddMenuLinks([...addMenuLinksArr])

    setEditMenuLinks([...editMenuLinksArr])
  }, [])

  const handleAddMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorAddEl(event.currentTarget)
  }

  const handleAddMenuClose = () => {
    setAnchorAddEl(null)
  }

  const handleEditsMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEditEl(event.currentTarget)
  }

  const handleEditsMenuClose = () => {
    setAnchorEditEl(null)
  }

  const handleAddPlanParams = (plan: IPlan, race: IRace) => {
    addRaceParamsModal.handleOpenModal(plan, race)
  }

  const handleAddPlanSchedule = () => {
    navigate(`/travel/races/${plan?.raceId}/planning/${plan?._id}/schedule`)
  }

  const handleAddPlanTariffs = () => {
    navigate(`/travel/races/${plan?.raceId}/planning/${plan?._id}/tariffs`)
  }

  const handleEditPlanParams = (plan: IPlan, race: IRace) => {
    addRaceParamsModal.handleOpenModal(plan, race)
  }

  const handleEditPlanSchedule = () => {
    navigate(`/travel/races/${plan?.raceId}/planning/${plan?._id}/schedule`)
  }

  const handleEditPlanTariffs = () => {
    navigate(`/travel/races/${plan?.raceId}/planning/${plan?._id}/tariffs`)
  }

  const handleDeletePlan = () => {
    deletePlanModal.handleOpenModal(plan)
  }

  return (
    <>
      <TableRow hover onClick={() => setOpen(!open)}>
        <TableCell align="center">
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{
              cursor: 'pointer',
            }}>
            {formatDate(plan.startDate, true)}
          </Typography>
        </TableCell>
        <TableCell align="center">
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{
              cursor: 'pointer',
            }}>
            {editMenuLinks.includes('params') && <SportsScore />}
            {editMenuLinks.includes('raceStops') && <LocationOn />}
            {editMenuLinks.includes('tariffs') && <Money />}
          </Typography>
        </TableCell>

        <TableCell align="center">
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{
              cursor: 'pointer',
            }}>
            {`${plan?.note ?? ''}`}
          </Typography>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0, width: '100%' }} colSpan={13}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box
              sx={{
                margin: 1,
                display: 'flex',
                gap: '10px',
              }}>
              {addMenuLinks.length > 0 && (
                <>
                  <Button
                    id="add-button"
                    variant="contained"
                    color="primary"
                    size="small"
                    className="addBtn"
                    startIcon={<Add className="addIcon" />}
                    aria-controls={openAddMenu ? 'add-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={openAddMenu ? 'true' : undefined}
                    onClick={handleAddMenuClick}>
                    {t('racePlanningPage.racePlanningMenu.add')}
                  </Button>
                  <Menu
                    id="add-menu"
                    anchorEl={anchorAddEl}
                    open={openAddMenu}
                    onClose={handleAddMenuClose}
                    MenuListProps={{
                      'aria-labelledby': 'add-button',
                    }}>
                    {addMenuLinks.includes('params') && (
                      <MenuItem onClick={() => handleAddPlanParams(plan, oneRace)}>
                        {t('racePlanningPage.racePlanningMenu.addMenu.params')}
                      </MenuItem>
                    )}
                    {addMenuLinks.includes('raceStops') && (
                      <MenuItem onClick={() => handleAddPlanSchedule()}>
                        {t('racePlanningPage.racePlanningMenu.addMenu.schedule')}
                      </MenuItem>
                    )}
                    {addMenuLinks.includes('tariffs') && (
                      <MenuItem onClick={() => handleAddPlanTariffs()}>
                        {t('racePlanningPage.racePlanningMenu.addMenu.tariffs')}
                      </MenuItem>
                    )}
                  </Menu>
                </>
              )}
              {editMenuLinks.length > 0 && (
                <>
                  <Button
                    id="edits-button"
                    variant="contained"
                    color="primary"
                    size="small"
                    className="editsBtn"
                    startIcon={<Edit className="editIcon" />}
                    aria-controls={openEditsMenu ? 'edits-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={openEditsMenu ? 'true' : undefined}
                    onClick={handleEditsMenuClick}>
                    {t('racePlanningPage.racePlanningMenu.edits')}
                  </Button>
                  <Menu
                    id="edits-menu"
                    anchorEl={anchorEditEl}
                    open={openEditsMenu}
                    onClose={handleEditsMenuClose}
                    MenuListProps={{
                      'aria-labelledby': 'edits-button',
                    }}>
                    {editMenuLinks.includes('params') && (
                      <MenuItem onClick={() => handleEditPlanParams(plan, oneRace)}>
                        {t('racePlanningPage.racePlanningMenu.editsMenu.params')}
                      </MenuItem>
                    )}
                    {editMenuLinks.includes('raceStops') && (
                      <MenuItem onClick={() => handleEditPlanSchedule()}>
                        {t('racePlanningPage.racePlanningMenu.editsMenu.schedule')}
                      </MenuItem>
                    )}
                    {editMenuLinks.includes('tariffs') && (
                      <MenuItem onClick={() => handleEditPlanTariffs()}>
                        {t('racePlanningPage.racePlanningMenu.editsMenu.tariffs')}
                      </MenuItem>
                    )}
                  </Menu>
                </>
              )}
              <Button
                variant="contained"
                color="primary"
                size="small"
                className="deleteRaceStop"
                startIcon={<Delete className="deleteIcon" />}
                onClick={handleDeletePlan}>
                {t('racePlanningPage.racePlanningMenu.delete')}
              </Button>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )
}

export default PlanningTabelRow
