// ? hooks
import React, { useEffect, useState } from 'react'

import useAsyncWrapper from '@/hooks/useAsyncWrapper'

// ? stores

import { messageStore } from '@/store/store'

// ? interfaces
import { ITownCreateDTO } from '@/interfaces/town'

// ? utils
import { inputsChanger } from '@/utils/inputsChanger'

// ? components
import {
  Button,
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  FormHelperText,
  FormControlLabel,
  Checkbox,
} from '@mui/material'
import { LoadingButton } from '@mui/lab'

import ModalContainer from '@/components/UI/Modal/ModalContainer'
import { IDeliveryCountry } from '@/interfaces/country'
import { validateForm } from '@/utils/formValidation'

import { deliveryCountryStore } from '@/store/deliveryCountryStore'
import { ICreateRouteDTO } from '@/interfaces/route'
import { routesStore } from '@/store/routesStore'

const getDeliveryTypes = (isAddress: boolean, isDepartment: boolean) => {
  let result = []

  if (isAddress) result.push('address')
  if (isDepartment) result.push('department')

  return result.join(', ')
}

type CreateRouteFormValues = {
  isSenderAddress: boolean
  isSenderDepartment: boolean
  isDeliveryAddress: boolean
  isDeliveryDepartment: boolean
} & ICreateRouteDTO

const initialState: CreateRouteFormValues = {
  delivery_types: '',
  origin_country_id: null,
  destination_country_id: null,
  sender_types: '',
  isSenderAddress: false,
  isSenderDepartment: false,
  isDeliveryAddress: false,
  isDeliveryDepartment: false,
}

const CreateRouteModal = () => {
  const [isLoading, setIsLoading] = useState(false)

  const { addMessage } = messageStore()

  const { countryMeta, getAllCountries, countries } = deliveryCountryStore()

  const { createRoute, getAllRoutes } = routesStore()
  const { isOpenModal, handleCloseModal } = createRoute

  const [formValues, setFormValues] = useState(initialState)

  const [formErrors, setFormErrors] = useState<any>()
  const validationRules = {
    origin_country_id: {
      required: true,
    },
    destination_country_id: {
      required: true,
    },
  }

  const handleClose = () => {
    setFormValues(initialState)
    handleCloseModal()
  }

  const createRouteApiHandler = useAsyncWrapper(createRoute.createRouteApiHandler, 'createRouteApiHandler')

  const getAllRoutesApiHandler = useAsyncWrapper(getAllRoutes, 'getAllRoutesApiHandler')
  const getCountriesApiHandler = useAsyncWrapper(getAllCountries, 'getCountriesApiHandler')

  const selectChangeHandler = inputsChanger('selectChanger', formValues, setFormValues) as (e: any) => void

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target
    setFormValues((prevState) => ({
      ...prevState,
      [name]: checked,
    }))
  }

  const createRouteHandler = async () => {
    setIsLoading(true)

    const formErrors = validateForm(formValues, validationRules)
    if (Object.keys(formErrors).length !== 0) {
      setFormErrors(formErrors)
      setIsLoading(false)
      return
    }

    const data = {
      ...formValues,
      delivery_types: getDeliveryTypes(formValues.isDeliveryAddress, formValues.isDeliveryDepartment),
      sender_types: getDeliveryTypes(formValues.isSenderAddress, formValues.isSenderDepartment),
    }

    const { error: createRouteError } = await createRouteApiHandler([data])

    if (createRouteError) {
      setIsLoading(false)
      return
    }

    const { error: getRoutesError } = await getAllRoutesApiHandler([])

    if (getRoutesError) {
      setIsLoading(false)
      return
    }

    setIsLoading(false)

    addMessage({
      severity: 'success',
      message: 'Успішно створено',
      isOpen: true,
    })

    handleClose()
  }

  useEffect(() => {
    const fetchData = async () => {
      const { error: getCountriesError } = await getCountriesApiHandler([
        {
          ...countryMeta,
          count: 1000,
        },
      ])

      if (getCountriesError) {
        return false
      }
    }

    fetchData()
  }, [])

  return (
    <ModalContainer width={800} isOpen={isOpenModal} handleClose={handleClose} title={'Додати маршрут'}>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexFlow: 'column nowrap',
          justifyContent: 'center',
          gap: '20px',
        }}>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            gap: '20px',
          }}>
          <FormControl error={!!formErrors?.country} fullWidth variant="standard">
            <InputLabel>Країна відправлення</InputLabel>
            <Select
              value={formValues.origin_country_id || ''}
              onChange={selectChangeHandler}
              name="origin_country_id"
              label="Країна відправлення">
              {countries?.map((country: IDeliveryCountry) => (
                <MenuItem key={country.country_id} value={country.country_id}>
                  {country.country_name_ua}
                </MenuItem>
              ))}
            </Select>
            {formErrors?.origin_country_id && <FormHelperText>{formErrors?.origin_country_id}</FormHelperText>}
          </FormControl>
          <FormControl error={!!formErrors?.country} fullWidth variant="standard">
            <InputLabel>Країна призначення</InputLabel>
            <Select
              value={formValues.destination_country_id || ''}
              onChange={selectChangeHandler}
              name="destination_country_id"
              label="Країна прибуття">
              {countries?.map((country: IDeliveryCountry) => (
                <MenuItem key={country.country_id} value={country.country_id}>
                  {country.country_name_ua}
                </MenuItem>
              ))}
            </Select>
            {formErrors?.destination_country_id && (
              <FormHelperText>{formErrors?.destination_country_id}</FormHelperText>
            )}
          </FormControl>
        </Box>
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
            gap: '20px',
          }}>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              gap: '20px',
            }}>
            <FormControlLabel
              control={
                <Checkbox
                  name="isDeliveryAddress"
                  checked={formValues.isDeliveryAddress}
                  onChange={handleCheckboxChange}
                />
              }
              label="Адреса"
            />
            <FormControlLabel
              control={
                <Checkbox
                  name="isDeliveryDepartment"
                  checked={formValues.isDeliveryDepartment}
                  onChange={handleCheckboxChange}
                />
              }
              label="Відділення"
            />
          </Box>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              gap: '20px',
            }}>
            <FormControlLabel
              control={
                <Checkbox name="isSenderAddress" checked={formValues.isSenderAddress} onChange={handleCheckboxChange} />
              }
              label="Адреса"
            />
            <FormControlLabel
              control={
                <Checkbox
                  name="isSenderDepartment"
                  checked={formValues.isSenderDepartment}
                  onChange={handleCheckboxChange}
                />
              }
              label="Відділення"
            />
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            gap: '10px',
          }}>
          <LoadingButton
            loading={isLoading}
            color="primary"
            variant="contained"
            size="small"
            onClick={createRouteHandler}>
            Створити
          </LoadingButton>
          <Button onClick={handleClose} color="error" variant="contained" size="small">
            Відмінити
          </Button>
        </Box>
      </Box>
    </ModalContainer>
  )
}

export default CreateRouteModal
