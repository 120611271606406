// ? components
import { TableRow, TableCell } from '@mui/material'

const headerTitles = [
  {
    title: 'ID',
    name: 'id',
  },
  {
    title: 'Назва Eng',
    name: 'name_eng',
  },

  {
    title: 'Назва Ukr',
    name: 'name_ukr',
  },

  {
    title: 'Дії',
    name: 'actions',
  },
]

const DeliveryCountryTabelHeader = () => {
  return (
    <TableRow>
      {headerTitles?.length
        ? headerTitles.map(({ title, name }) => (
            <TableCell
              key={title}
              sx={{
                backgroundColor: '#222222 !important',
              }}>
              {title}
            </TableCell>
          ))
        : null}
    </TableRow>
  )
}

export default DeliveryCountryTabelHeader
