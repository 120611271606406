import TransportsTabel from '@/components/Tabels/TransportsTabel/TransportsTabel'
import TravelWrapper from '@/components/Wrappers/TravelWrapper'
import { Typography } from '@mui/material'

const Transports = () => {
  return (
    <TravelWrapper>
      {true ? (
        <TransportsTabel />
      ) : (
        <Typography variant="h4" color="error">
          You don&apos;t have permission to view traders
        </Typography>
      )}
    </TravelWrapper>
  )
}

export default Transports
