// ? interfaces
import { ICountryCreateDTO, IDeliveryCountryCreateDTO, Icountry } from '@/interfaces/country'
import { IErrorValidation } from '@/interfaces/general'

interface IDELIVERY_COUNTRY_VALIDATION {
  CREATE: (data: IDeliveryCountryCreateDTO) => IErrorValidation[]
  EDIT: (data: Partial<Icountry>) => IErrorValidation[]
  DELETE: (id: string) => IErrorValidation[]
}

export const DELIVERY_COUNTRY_VALIDATION: IDELIVERY_COUNTRY_VALIDATION = {
  CREATE: ({ country_name_en, country_name_ua }: IDeliveryCountryCreateDTO) => {
    const errors: IErrorValidation[] = []

    if (!country_name_ua) {
      errors.push({
        field: 'name_ukr',
        message: 'Введіть назву країни українською',
      })
    }

    if (!country_name_en) {
      errors.push({
        field: 'name_eng',
        message: 'Введіть назву країни англійською',
      })
    }

    return errors
  },
  EDIT: (data: Partial<Icountry>) => {
    const errors: IErrorValidation[] = []

    if (Object.keys(data).length === 0) {
      errors.push({
        field: 'data',
        message: 'Дані для редагування не передані',
      })
    }

    return errors
  },
  DELETE: (id: string) => {
    const errors: IErrorValidation[] = []

    if (!id) {
      errors.push({
        field: 'id',
        message: 'Не передано id для видалення',
      })
    }

    return errors
  },
}
