import { api } from '@/api'

// ? interfaces
import { IDepartment, IDepartmentCreateDTO } from '@/interfaces/department'

// ? routes prefixes
import { PREFIXES } from '@/routes/prefixes'

// ? utils
import { apiCreator } from '@/utils/apiCreator'

const requestCreator = apiCreator(PREFIXES.DEPARTMENT)

export const department_api = {
  getAllDepartments: async () => await requestCreator(api, '', 'GET', undefined),

  createDepartment: async (body: IDepartmentCreateDTO) => await requestCreator(api, '', 'POST', body),

  editDepartment: async (id: string, body: IDepartmentCreateDTO) => await requestCreator(api, `${id}`, 'PUT', body),

  deleteDepartment: async (id: string) => await requestCreator(api, `${id}`, 'DELETE'),
}
