import FileUploader from '@/components/UI/FileUploader/FileUploader'
import ModalContainer from '@/components/UI/Modal/ModalContainer'
import { newsStore } from '@/store/newsStore'
import { messageStore } from '@/store/store'
import { inputsChanger } from '@/utils/inputsChanger'
import { LoadingButton } from '@mui/lab'
import { TextField, Button, Box, Select, MenuItem, Switch, FormControlLabel } from '@mui/material'
import React, { useState } from 'react'

const initialState = {
  title: '',
  description: '',
  language: 'UA',
  isHidden: false,
  type: 'news',
  entity: 'passengerTransportation',
  images: [],
}

const CreateNewsModal = () => {
  const { createNew, getAllNews, newsMeta, filter } = newsStore()
  const { isOpenModal, handleCloseModal } = createNew

  const { addMessage } = messageStore()

  const [formValues, setFormValues] = useState<any>(initialState)
  const [isLoading, setIsLoading] = useState(false)
  const [files, setFiles] = useState<any>([])

  const handleChangeInput = inputsChanger('input', formValues, setFormValues)

  const handleChangeSelect = inputsChanger('selectChanger', formValues, setFormValues)
  const handleChangeSwitch = inputsChanger('switchChanger', formValues, setFormValues)

  const createNewsHandler = async () => {
    console.log(formValues)

    const formData = new FormData()

    for (const key in formValues) {
      formData.append(key, formValues[key])
    }

    files.forEach((file: any) => {
      const blob = new Blob([file], { type: file.type })

      formData.append('images', blob)
    })

    if (formValues.title.length < 3) {
      return alert('Заголовок має бути більше 3 символів')
    }

    if (formValues.description.length < 3) {
      return alert('Опис має бути більше 3 символів')
    }

    setIsLoading(true)

    await createNew.createNewApiHandler(formData)
    await getAllNews(newsMeta, filter)

    setIsLoading(false)

    setFormValues(initialState)
    handleCloseModal()

    addMessage({
      severity: 'success',
      message: 'Новину успішно створено',
    })
  }

  return (
    <ModalContainer width={1000} isOpen={isOpenModal} handleClose={handleCloseModal} title="Створення новини">
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          maxHeight: '80vh',
          overflow: 'auto',
          p: 1,
        }}>
        <TextField
          fullWidth
          multiline
          minRows={3}
          label="Заголовок"
          onChange={handleChangeInput}
          name="title"
          variant="outlined"
          value={formValues.title}
          sx={{ mt: 2 }}
        />

        <TextField
          fullWidth
          minRows={3}
          multiline
          label="Опис"
          onChange={handleChangeInput}
          sx={{ mt: 2 }}
          name="description"
          value={formValues.description}
          variant="outlined"
        />

        <Select
          fullWidth
          name="language"
          onChange={handleChangeSelect}
          value={formValues.language}
          variant="standard"
          sx={{
            mt: 1,
          }}>
          <MenuItem value="UA">UA</MenuItem>
          <MenuItem value="EN">EN</MenuItem>
        </Select>

        <Select
          fullWidth
          name="type"
          onChange={handleChangeSelect}
          variant="standard"
          value={formValues.type}
          sx={{
            mt: 1,
          }}>
          <MenuItem value="news">Новина</MenuItem>
          <MenuItem value="discount">Знижка</MenuItem>
        </Select>

        <Select
          fullWidth
          name="entity"
          variant="standard"
          onChange={handleChangeSelect}
          value={formValues.entity}
          sx={{
            mt: 1,
          }}>
          <MenuItem value="passengerTransportation">Пасажирські перевезення</MenuItem>
          <MenuItem value="cargoTransportation">Вантажні перевезення</MenuItem>
        </Select>

        <FormControlLabel
          control={<Switch name="isHidden" value={formValues.isHidden} onChange={handleChangeSwitch} />}
          label="Приховати новину"
        />

        <FileUploader setFiles={setFiles} files={files} />

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            mt: 1,
          }}>
          <LoadingButton
            loading={isLoading}
            color="primary"
            variant="contained"
            size="small"
            onClick={createNewsHandler}>
            Створити
          </LoadingButton>
          <Button onClick={handleCloseModal} color="error" variant="contained" size="small" sx={{ ml: 1 }}>
            Закрити
          </Button>
        </Box>
      </Box>
    </ModalContainer>
  )
}

export default CreateNewsModal
