// ? hooks
import { useState } from 'react'
import useAsyncWrapper from '@/hooks/useAsyncWrapper'
import useValidation from '@/hooks/useValidation'
// ? stores
import { countryStore } from '@/store/countryStore'
import { messageStore } from '@/store/store'
// ? interfaces
import { ICountryCreateDTO } from '@/interfaces/country'
// ? validations
import { COUNTRY_VALIDATION } from '@/validation/country'
// ? utils
import { inputsChanger } from '@/utils/inputsChanger'
// ? components
import { Button, Box, TextField, Autocomplete } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import ModalContainer from '@/components/UI/Modal/ModalContainer'

const initialState: ICountryCreateDTO = {
  name_eng: '',
  name_ukr: '',
}

const CreateCountryModal = () => {
  const { addMessage } = messageStore()
  const { createCountry, getAllCountries, countryMeta, countryCodes, getCountryCodes } = countryStore()
  const { isOpenModal, handleCloseModal } = createCountry
  const [formValues, setFormValues] = useState(initialState)
  const [isLoading, setIsLoading] = useState(false)

  const inputChangeHandler = inputsChanger('input', formValues, setFormValues)
  const createCountryValidation = useValidation(COUNTRY_VALIDATION.CREATE)
  const createCountryApiHandler = useAsyncWrapper(createCountry.createCountryApiHandler, 'createCountryApiHandler')
  const getCountriesApiHandler = useAsyncWrapper(getAllCountries, 'getCountriesApiHandler')

  const handleClose = () => {
    setFormValues(initialState)
    handleCloseModal()
  }

  const createCountryHandler = async () => {
    setIsLoading(true)
    if (!createCountryValidation(formValues)) return
    const { error: createCountryError } = await createCountryApiHandler([formValues])
    if (createCountryError) {
      setIsLoading(false)
      return
    }
    const { error: getCountryError } = await getCountriesApiHandler([countryMeta])
    if (getCountryError) {
      setIsLoading(false)
      return
    }
    addMessage({
      severity: 'success',
      message: 'Успішно створено',
      isOpen: true,
    })
    setIsLoading(false)
    handleClose()
  }

  return (
    <ModalContainer width={400} isOpen={isOpenModal} handleClose={handleClose} title={'Додати країну'}>
      <TextField
        name="name_eng"
        onChange={inputChangeHandler}
        fullWidth
        label="Назва країни англійською"
        variant="standard"
      />
      <TextField
        name="name_ukr"
        onChange={inputChangeHandler}
        fullWidth
        label="Назва країни українською"
        variant="standard"
      />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          mt: 1,
        }}>
        <LoadingButton
          loading={isLoading}
          color="primary"
          variant="contained"
          size="small"
          onClick={createCountryHandler}>
          Створити
        </LoadingButton>
        <Button onClick={handleClose} color="error" variant="contained" size="small" sx={{ ml: 1 }}>
          Відмінити
        </Button>
      </Box>
    </ModalContainer>
  )
}
export default CreateCountryModal
