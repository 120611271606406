import ModalContainer from '@/components/UI/Modal/ModalContainer'
import { ITransportCreateDTO } from '@/interfaces/transport'
import { messageStore } from '@/store/store'
import { transportStore } from '@/store/transportStore'
import { FormControl, InputLabel, Select, MenuItem, TextField, Box, Button } from '@mui/material'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import useAsyncWrapper from '@/hooks/useAsyncWrapper'
import { inputsChanger } from '@/utils/inputsChanger'
import { LoadingButton } from '@mui/lab'
import { TRANSPORT_VALIDATION } from '@/validation/transport'
import useValidation from '@/hooks/useValidation'
import { api } from '@/api'

interface IProps {
  isOpen: boolean
  handleClose: () => void
}

const initialState: ITransportCreateDTO = {
  vehicle_amenities: [],
  vehicle_brand: '',
  vehicle_capacity: 0,
  vehicle_license_plates: '',
  vehicle_photo: '',
  vehicle_type: '',
}

const CreateTransportModal = ({ handleClose, isOpen }: IProps) => {
  const { t } = useTranslation()

  const [formValues, setFormValues] = useState(initialState)
  const [isLoading, setIsLoading] = useState(false)
  const [file, setFile] = useState<File | null>(null)

  const { createTransport, getAllTransports, transportMeta } = transportStore()
  const { addMessage } = messageStore()
  const createTransportValidation = useValidation(TRANSPORT_VALIDATION.CREATE)

  const getAllTransportsApiHandler = useAsyncWrapper(getAllTransports, 'getAllTransportsApiHandler')
  const createTransportApiHandler = useAsyncWrapper(createTransport, 'createTransportApiHandler')

  const handleChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, files } = e.target as HTMLInputElement
    if (type === 'file' && files) {
      setFile(files[0])
    } else {
      setFormValues((prevState) => ({
        ...prevState,
        [name]: type === 'number' ? Number(value) : value,
      }))
    }
  }

  const handleChangeSelect = inputsChanger('selectChanger', formValues, setFormValues) as (e: any) => void

  const handleCloseForm = () => {
    setFormValues(initialState)
    setFile(null)
    handleClose()
  }

  const createBusHandler = async () => {
    setIsLoading(true)

    if (!createTransportValidation(formValues)) {
      setIsLoading(false)
      return false
    }

    const formData = new FormData()
    formData.append('vehicle_type', formValues.vehicle_type)
    formData.append('vehicle_brand', formValues.vehicle_brand)
    formData.append('vehicle_license_plates', formValues.vehicle_license_plates)
    formData.append('vehicle_capacity', formValues.vehicle_capacity.toString())
    formData.append(
      'vehicle_amenities',
      Array.isArray(formValues.vehicle_amenities)
        ? formValues.vehicle_amenities.join(', ')
        : formValues.vehicle_amenities
    )
    if (file) formData.append('vehicle_photo', file)

    try {
      const { status } = await api.post('/admin/travel/cars', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })

      if (status !== 201) {
        throw new Error('Failed to create transport')
      }

      const { error: getAllTransportsError } = await getAllTransportsApiHandler([transportMeta])

      if (getAllTransportsError) {
        throw new Error(getAllTransportsError.message)
      }

      addMessage({
        severity: 'success',
        message: t('transportsPage.createBusSuccess'),
        isOpen: true,
      })
      handleCloseForm()
    } catch (error) {
      console.error('Error:', error)
      addMessage({
        severity: 'error',
        message: 'Failed to create transport',
        isOpen: true,
      })
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <ModalContainer
      isOpen={isOpen}
      handleClose={handleClose}
      title={t('transportsPage.createNewTransport')}
      width={600}>
      <Box
        sx={{
          display: 'flex',
          flexFlow: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '16px',
        }}>
        <FormControl fullWidth variant="standard">
          <InputLabel>{t('transportsPage.type')}</InputLabel>
          <Select value={formValues.vehicle_type || ''} name="vehicle_type" onChange={handleChangeSelect}>
            <MenuItem value={'bus'}>Автобус</MenuItem>
            <MenuItem value={'microBus'}>Мікроавтобус</MenuItem>
            <MenuItem value={'car'}>Легковий автомобіль</MenuItem>
          </Select>
        </FormControl>
        <TextField
          fullWidth
          label={t('transportsPage.name')}
          onChange={handleChangeInput}
          value={formValues.vehicle_brand || ''}
          name="vehicle_brand"
          variant="standard"
          placeholder={'Mercedes-Benz Sprinter'}
        />
        <TextField
          fullWidth
          label={t('transportsPage.busNumber')}
          onChange={handleChangeInput}
          name="vehicle_license_plates"
          value={formValues.vehicle_license_plates || ''}
          variant="standard"
          placeholder="AA0000AA"
        />
        <TextField
          fullWidth
          label={t('transportsPage.quantitySeats')}
          onChange={handleChangeInput}
          name="vehicle_capacity"
          value={formValues.vehicle_capacity || ''}
          variant="standard"
          placeholder="24"
          type="number"
        />
        <FormControl fullWidth variant="standard">
          <InputLabel>{t('transportsPage.amenities')}</InputLabel>
          <Select value={formValues.vehicle_amenities} name="vehicle_amenities" onChange={handleChangeSelect} multiple>
            <MenuItem value="WIFI">WIFI</MenuItem>
            <MenuItem value="Відкидні сидіння">Відкидні сидіння</MenuItem>
            <MenuItem value="Подушки/пледи">Подушки/пледи</MenuItem>
            <MenuItem value="Зарядки">Зарядки</MenuItem>
          </Select>
        </FormControl>
        <FormControl fullWidth variant="standard">
          <TextField
            type="file"
            id="vehicle_photo"
            name="vehicle_photo"
            onChange={handleChangeInput}
            style={{ marginTop: '8px' }}
          />
        </FormControl>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            mt: 1,
          }}>
          <LoadingButton
            loading={isLoading}
            color="primary"
            variant="contained"
            size="small"
            onClick={createBusHandler}>
            Створити
          </LoadingButton>
          <Button onClick={handleCloseForm} color="error" variant="contained" size="small" sx={{ ml: 1 }}>
            {t('transportsPage.cancelCreation')}
          </Button>
        </Box>
      </Box>
    </ModalContainer>
  )
}

export default CreateTransportModal
