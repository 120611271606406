import { TableRow, TableCell } from '@mui/material'
import React from 'react'

const DeliveryRequestsToUkraineHeader = () => {
  const headerTitles = [
    {
      title: 'Статус',
      name: 'status',
    },
    {
      title: 'Номер замовлення',
      name: 'order_id',
    },
    {
      title: 'Країна відправлення',
      name: 'origin_city',
    },
    {
      title: 'Спосіб Відправлення',
      name: 'sender_method',
    },
    {
      title: `Ім'я відправника`,
      name: 'sender_name',
    },
    {
      title: 'Адреса відправки',
      name: 'origin_address',
    },
    {
      title: 'Номер телефону',
      name: 'sender_phone',
    },
    {
      title: 'Спосіб отримання',
      name: 'delivery_method',
    },
    {
      title: 'Дані Отримання',
      name: 'receiver_name',
    },
    {
      title: 'Кількість',
      name: 'quantity',
    },
    {
      title: 'Вага',
      name: 'weight',
    },
    {
      title: 'Страхування',
      name: 'insurance',
    },
    {
      title: 'Сума',
      name: 'price',
    },
    {
      title: 'Знижка',
      name: 'discount',
    },
    {
      title: 'Промокод',
      name: 'promocode',
    },
    {
      title: 'Примітки',
      name: 'notes',
    },
    {
      title: 'Дії',
      name: '',
    },
  ]
  return (
    <TableRow>
      {headerTitles.map(({ title }) => (
        <TableCell
          key={title}
          sx={{
            backgroundColor: '#222222 !important',
          }}>
          {title}
        </TableCell>
      ))}
    </TableRow>
  )
}

export default DeliveryRequestsToUkraineHeader
