import AdminTabel from '@/components/Tabels/AdminTabel/AdminTabel'
import Header from '@/components/UI/GlobalNavigation/Header'

const Admins = () => (
  <>
    <Header />
    <AdminTabel />
  </>
)

export default Admins
