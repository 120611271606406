import { useEffect } from 'react'
import useAsyncWrapper from '@/hooks/useAsyncWrapper'

// ? store
import { countryStore } from '@/store/countryStore'

// ? components
import { Grid, Button, TableContainer, Table, TableHead, TableBody, Box } from '@mui/material'

import TablePaginationCustom from '@/components/UI/TablePaginationCustom/TablePaginationCustom'
import LoadingTableData from '@/components/UI/LoadingTableData/LoadingTableData'

import CountryTableHeader from './CountryTableHeader'
import CountryTableRow from './CountryTableRow'

import CreateCountryModal from './modals/CreateCountryModal'
import EditCountryModal from './modals/EditCountryModal'
import DeleteCountryModal from './modals/DeleteCountryModal'

// ? icons
import { Add } from '@mui/icons-material'
import { Icountry } from '@/interfaces/country'

const CountryTable = () => {
  const { getAllCountries, countries, countryMeta, createCountry, editCountry, deleteCountry, isLoading } =
    countryStore()

  const getCountriesApiHandler = useAsyncWrapper(getAllCountries, 'getCountriesApiHandler')

  const handleOpenCreateModal = () => {
    createCountry.handleOpenModal()
  }

  useEffect(() => {
    const fetchData = async () => {
      await getCountriesApiHandler([countryMeta])
    }

    fetchData()
  }, [])

  return (
    <Grid container spacing={1} sx={{ pr: 1 }}>
      {createCountry.isOpenModal && <CreateCountryModal />}
      {editCountry.isOpenModal && <EditCountryModal />}
      {deleteCountry.isOpenModal && <DeleteCountryModal />}

      <Grid item xs={12}>
        <Box>
          <Button
            variant="contained"
            color="primary"
            size="small"
            className="createUserBtn"
            endIcon={<Add className="addIcon" />}
            onClick={handleOpenCreateModal}>
            Створити нову країну
          </Button>
        </Box>
        {countries?.length > 0 ? (
          <TableContainer>
            <Table
              stickyHeader
              sx={{
                maxHeight: 'calc(100vh - 250px)',
                width: '100%',
                maxWidth: '100%',
                overflow: 'scroll',
                mt: 1,
              }}>
              <TableHead>
                <CountryTableHeader />
              </TableHead>
              <TableBody>
                {!isLoading ? (
                  countries.map((country: Icountry) => <CountryTableRow country={country} key={country.country_id} />)
                ) : (
                  <LoadingTableData columns={7} rows={5} />
                )}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: 'calc(100vh - 250px)',
              gap: 2,
            }}>
            <p>Дані відсутні</p>
            <Button
              variant="contained"
              color="primary"
              size="small"
              className="createUserBtn"
              endIcon={<Add className="addIcon" />}
              onClick={handleOpenCreateModal}>
              Створити нову країну
            </Button>
          </Box>
        )}
        {countries && countries?.length !== 0 && (
          <TablePaginationCustom getData={getCountriesApiHandler} meta={countryMeta} />
        )}
      </Grid>
    </Grid>
  )
}
export default CountryTable
