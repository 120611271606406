// ? hooks
import React, { useEffect, useState } from 'react'

import useAsyncWrapper from '@/hooks/useAsyncWrapper'

// ? stores
import { messageStore } from '@/store/store'

// ? utils
import { inputsChanger } from '@/utils/inputsChanger'

// ? components
import {
  Button,
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  FormHelperText,
  FormControlLabel,
  Checkbox,
} from '@mui/material'
import { LoadingButton } from '@mui/lab'

import ModalContainer from '@/components/UI/Modal/ModalContainer'
import { validateForm } from '@/utils/formValidation'
import { deliveryCountryStore } from '@/store/deliveryCountryStore'
import { IDeliveryCountry } from '@/interfaces/country'
import { routesStore } from '@/store/routesStore'
import { ICreateRouteDTO } from '@/interfaces/route'

type CreateRouteFormValues = {
  isSenderAddress: boolean
  isSenderDepartment: boolean
  isDeliveryAddress: boolean
  isDeliveryDepartment: boolean
} & ICreateRouteDTO

const getDeliveryTypes = (isAddress: boolean, isDepartment: boolean) => {
  let result = []

  if (isAddress) result.push('address')
  if (isDepartment) result.push('department')

  return result.join(', ')
}

const EditRouteModal = () => {
  const [isLoading, setIsLoading] = useState(false)

  const { addMessage } = messageStore()
  const { countryMeta, getAllCountries, countries } = deliveryCountryStore()

  const { editRoute, getAllRoutes } = routesStore()
  const { isOpenModal, handleCloseModal, editedItem } = editRoute

  const [formValues, setFormValues] = useState<CreateRouteFormValues>({
    delivery_types: editedItem?.delivery_types || '',
    sender_types: editedItem?.sender_types || '',
    origin_country_id: editedItem?.origin_country_id || null,
    destination_country_id: editedItem?.destination_country_id || null,
    isSenderAddress: editedItem?.delivery_types.includes('address') || false,
    isSenderDepartment: editedItem?.delivery_types.includes('department') || false,
    isDeliveryAddress: editedItem?.sender_types.includes('address') || false,
    isDeliveryDepartment: editedItem?.sender_types.includes('department') || false,
  })

  const [formErrors, setFormErrors] = useState<any>({})
  const validationRules = {
    origin_country_id: {
      required: true,
    },
    destination_country_id: {
      required: true,
    },
  }

  // ? API handlers
  const getCountriesApiHandler = useAsyncWrapper(getAllCountries, 'getCountriesApiHandler')

  const editRouteApiHandler = useAsyncWrapper(editRoute.editRouteApiHandler, 'editRouteApiHandler')

  const getAllRoutesApiHandler = useAsyncWrapper(getAllRoutes, 'getAllRoutesApiHandler')

  // ? handlers
  const selectChangeHandler = inputsChanger('selectChanger', formValues, setFormValues) as (e: any) => void
  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target
    setFormValues((prevState) => ({
      ...prevState,
      [name]: checked,
    }))
  }

  const editTownHandler = async () => {
    setIsLoading(true)

    const id = editedItem?.route_id

    const formErrors = validateForm(formValues, validationRules)
    if (Object.keys(formErrors).length !== 0) {
      setFormErrors(formErrors)
      setIsLoading(false)
      return
    }

    const data = {
      ...formValues,
      delivery_types: getDeliveryTypes(formValues.isDeliveryAddress, formValues.isDeliveryDepartment),
      sender_types: getDeliveryTypes(formValues.isSenderAddress, formValues.isSenderDepartment),
    }

    const { error: editRouteError } = await editRouteApiHandler([id, data])

    if (editRouteError) {
      setIsLoading(false)
      return false
    }

    const { error: getRoutesError } = await getAllRoutesApiHandler([])

    if (getRoutesError) {
      setIsLoading(false)
      return false
    }

    addMessage({
      severity: 'success',
      message: 'Успішно відредаговано',
      isOpen: true,
    })
    handleCloseModal()
  }

  useEffect(() => {
    const fetchData = async () => {
      const { error: getCountriesError } = await getCountriesApiHandler([
        {
          ...countryMeta,
          count: 1000,
        },
      ])

      if (getCountriesError) {
        return false
      }
    }

    fetchData()
  }, [])

  return (
    <ModalContainer
      width={800}
      isOpen={isOpenModal}
      handleClose={handleCloseModal}
      title={`Редагування маршруту ${editedItem?.origin_name_ua} -> ${editedItem?.destination_name_ua}`}>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexFlow: 'column nowrap',
          justifyContent: 'center',
          gap: '20px',
        }}>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            gap: '20px',
          }}>
          <FormControl error={!!formErrors?.country_id} fullWidth variant="standard">
            <InputLabel>Країна відправлення</InputLabel>
            <Select
              onChange={selectChangeHandler}
              value={formValues.origin_country_id}
              name="origin_country_id"
              label="Країна">
              {countries?.map((country: IDeliveryCountry) => (
                <MenuItem key={country.country_id} value={country.country_id}>
                  {country.country_name_ua}
                </MenuItem>
              ))}
            </Select>
            {formErrors?.origin_country_id && <FormHelperText>{formErrors?.origin_country_id}</FormHelperText>}
          </FormControl>
          <FormControl error={!!formErrors?.country_id} fullWidth variant="standard">
            <InputLabel>Країна призначення</InputLabel>
            <Select
              onChange={selectChangeHandler}
              value={formValues.destination_country_id}
              name="origin_country_id"
              label="Країна">
              {countries?.map((country: IDeliveryCountry) => (
                <MenuItem key={country.country_id} value={country.country_id}>
                  {country.country_name_ua}
                </MenuItem>
              ))}
            </Select>
            {formErrors?.destination_country_id && (
              <FormHelperText>{formErrors?.destination_country_id}</FormHelperText>
            )}
          </FormControl>
        </Box>
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
            gap: '20px',
          }}>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              gap: '20px',
            }}>
            <FormControlLabel
              control={
                <Checkbox
                  name="isDeliveryAddress"
                  checked={formValues.isDeliveryAddress}
                  onChange={handleCheckboxChange}
                />
              }
              label="Адреса"
            />
            <FormControlLabel
              control={
                <Checkbox
                  name="isDeliveryDepartment"
                  checked={formValues.isDeliveryDepartment}
                  onChange={handleCheckboxChange}
                />
              }
              label="Відділення"
            />
          </Box>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              gap: '20px',
            }}>
            <FormControlLabel
              control={
                <Checkbox name="isSenderAddress" checked={formValues.isSenderAddress} onChange={handleCheckboxChange} />
              }
              label="Адреса"
            />
            <FormControlLabel
              control={
                <Checkbox
                  name="isSenderDepartment"
                  checked={formValues.isSenderDepartment}
                  onChange={handleCheckboxChange}
                />
              }
              label="Відділення"
            />
          </Box>
        </Box>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            gap: '10px',
          }}>
          <LoadingButton color="primary" variant="contained" size="small" onClick={editTownHandler} loading={isLoading}>
            Зберегти
          </LoadingButton>

          <Button onClick={handleCloseModal} color="error" variant="contained" size="small">
            Відмінити
          </Button>
        </Box>
      </Box>
    </ModalContainer>
  )
}

export default EditRouteModal
