import TravelWrapper from '@/components/Wrappers/TravelWrapper'
import { adminStore } from '@/store/adminStore'
import { Box, Button, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

type IPageRedirrect = '/auth' | '/' | null

const Error404 = () => {
  const navigate = useNavigate()

  const { currentAdmin } = adminStore()

  const [pageRedirrect, setPageRedirrect] = useState<IPageRedirrect>(null)

  const route = window.location.pathname

  const handleBackToMainPage = () => {
    navigate(`${pageRedirrect}`)
  }

  useEffect(() => {
    if (currentAdmin) {
      setPageRedirrect('/')
    } else {
      setPageRedirrect('/auth')
    }
  }, [])

  return (
    <TravelWrapper>
      <Box
        sx={{
          minHeight: 'calc(100vh - 80px)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        <Typography
          variant="h1"
          sx={{
            fontSize: '400px',
            fontWeight: 'bold',
            mr: '200px',
          }}>
          404
        </Typography>
        <Box>
          <Typography variant="h2" color="error">
            Error
          </Typography>
          <Typography variant="h2" sx={{ my: 2 }}>
            Route
            <Typography component="span" variant="h2" color="error">
              {' '}
              {route}
            </Typography>{' '}
            not found
          </Typography>
          <Button variant="contained" color="primary" onClick={handleBackToMainPage}>
            Back to main page
          </Button>
        </Box>
      </Box>
    </TravelWrapper>
  )
}

export default Error404
