// ? hooks
import { useState } from 'react'
import useAsyncWrapper from '@/hooks/useAsyncWrapper'

import { messageStore } from '@/store/store'

// ? utils
import { inputsChanger } from '@/utils/inputsChanger'

// ? components
import { Button, Box, TextField } from '@mui/material'
import { LoadingButton } from '@mui/lab'

import ModalContainer from '@/components/UI/Modal/ModalContainer'
import { deliveryDriverStore } from '@/store/deliveryDriverStore'

const initialState: {
  pib: string
} = {
  pib: '',
}

const CreateDeliveryDriverModal = () => {
  const { addMessage } = messageStore()
  const { createDeliveryDriver, getAllDrivers, countryMeta } = deliveryDriverStore()
  const { isOpenModal, handleCloseModal, createDeliveryDriverApiHandler } = createDeliveryDriver

  const [formValues, setFormValues] = useState(initialState)
  const [isLoading, setIsLoading] = useState(false)

  const inputChangeHandler = inputsChanger('input', formValues, setFormValues)

  const createDriverApiHandler = useAsyncWrapper(createDeliveryDriverApiHandler, 'createDeliveryDriverApiHandler')

  const getDriversApiHandler = useAsyncWrapper(getAllDrivers, 'getDriversApiHandler')

  const handleClose = () => {
    setFormValues(initialState)
    handleCloseModal()
  }

  const createCountryHandler = async () => {
    setIsLoading(true)

    const { error: createDriverError } = await createDriverApiHandler([formValues])

    if (createDriverError) {
      setIsLoading(false)
      return
    }

    const { error: getDriversError } = await getDriversApiHandler([countryMeta])

    if (getDriversError) {
      setIsLoading(false)
      return
    }

    addMessage({
      severity: 'success',
      message: 'Успішно створено',
      isOpen: true,
    })

    setIsLoading(false)
    handleClose()
  }

  return (
    <ModalContainer width={400} isOpen={isOpenModal} handleClose={handleClose} title={'Додати водія'}>
      <TextField name="pib" onChange={inputChangeHandler} fullWidth label="ПІБ" variant="standard" />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          mt: 1,
        }}>
        <LoadingButton
          loading={isLoading}
          color="primary"
          variant="contained"
          size="small"
          onClick={createCountryHandler}>
          Створити
        </LoadingButton>
        <Button onClick={handleClose} color="error" variant="contained" size="small" sx={{ ml: 1 }}>
          Відмінити
        </Button>
      </Box>
    </ModalContainer>
  )
}

export default CreateDeliveryDriverModal
