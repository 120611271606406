import { Button } from '@mui/material'
import { Box } from '@mui/system'
import { useNavigate } from 'react-router-dom'

import Header from '@/components/UI/GlobalNavigation/Header'

export function HomePage() {
  const navigate = useNavigate()

  return (
    <>
      <Header />
      <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr', height: '100vh' }}>
        <Button variant="contained" color="primary" onClick={() => navigate('/travel')}>
          Travel
        </Button>
        <Button variant="outlined" color="primary" onClick={() => navigate('/delivery')}>
          Delivery
        </Button>
      </Box>
    </>
  )
}
