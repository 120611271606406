import { create } from 'zustand'
import { IMeta } from '@/interfaces/general'
import { IDeliveryDriver } from '@/interfaces/dirvers'
import { delivery_drivers_api } from '@/api/delivery_drivers.api'

interface IStore {
  drivers: IDeliveryDriver[]
  isLoading: boolean
  countryMeta: IMeta
  createDeliveryDriver: {
    isOpenModal: boolean
    handleOpenModal: () => void
    handleCloseModal: () => void
    createDeliveryDriverApiHandler: (body: { pib: string }) => Promise<any>
  }
  editDeliveryDriver: {
    isOpenModal: boolean
    editedItem: IDeliveryDriver | null
    handleOpenModal: (item: IDeliveryDriver) => void
    handleCloseModal: () => void
    editDeliveryDriverApiHandler: (
      id: string,
      body: {
        pib: string
      }
    ) => Promise<any>
  }
  deleteDeliveryDriver: {
    isOpenModal: boolean
    deletedItem: IDeliveryDriver | null
    handleOpenModal: (item: IDeliveryDriver) => void
    handleCloseModal: () => void
    deleteDeliveryDriverApiHandler: (id: string) => Promise<any>
  }
  getAllDrivers: () => Promise<any>
}

export const deliveryDriverStore = create<IStore>((set) => ({
  drivers: [],
  isLoading: false,
  countryMeta: {
    count: 10,
    page: 1,
    total: 0,
  },
  createDeliveryDriver: {
    isOpenModal: false,
    handleOpenModal: () => {
      set((state) => ({
        ...state,
        createDeliveryDriver: {
          ...state.createDeliveryDriver,
          isOpenModal: true,
        },
      }))
    },
    handleCloseModal: () => {
      set((state) => ({
        ...state,
        createDeliveryDriver: {
          ...state.createDeliveryDriver,
          isOpenModal: false,
        },
      }))
    },
    createDeliveryDriverApiHandler: async (body: { pib: string }) => {
      const response = await delivery_drivers_api.createDriver(body)

      const { status, data } = response

      if (!status || !data) {
        throw new Error('No status code or data returned from server.')
      }

      if (status !== 201) {
        throw new Error(data)
      } else {
        return response
      }
    },
  },

  editDeliveryDriver: {
    isOpenModal: false,
    editedItem: null,
    handleOpenModal: (item: IDeliveryDriver) => {
      set((state) => ({
        ...state,
        editDeliveryDriver: {
          ...state.editDeliveryDriver,
          isOpenModal: true,
          editedItem: item,
        },
      }))
    },
    handleCloseModal: () => {
      set((state) => ({
        ...state,
        editDeliveryDriver: {
          ...state.editDeliveryDriver,
          isOpenModal: false,
          editedItem: null,
        },
      }))
    },
    editDeliveryDriverApiHandler: async (
      id: string,
      body: {
        pib: string
      }
    ) => {
      const response = await delivery_drivers_api.editDriver(id, body)

      const { status, data } = response

      if (!status || !data) {
        throw new Error('No status code or data returned from server.')
      }

      if (status !== 200) {
        throw new Error(data)
      } else {
        return response
      }
    },
  },

  deleteDeliveryDriver: {
    isOpenModal: false,
    deletedItem: null,
    handleOpenModal: (item: IDeliveryDriver) => {
      set((state) => ({
        ...state,
        deleteDeliveryDriver: {
          ...state.deleteDeliveryDriver,
          isOpenModal: true,
          deletedItem: item,
        },
      }))
    },
    handleCloseModal: () => {
      set((state) => ({
        ...state,
        deleteDeliveryDriver: {
          ...state.deleteDeliveryDriver,
          isOpenModal: false,
          deletedItem: null,
        },
      }))
    },
    deleteDeliveryDriverApiHandler: async (id: string) => {
      const response = await delivery_drivers_api.deleteDriver(id)

      const { status, data } = response

      if (!status || !data) {
        throw new Error('No status code or data returned from server.')
      }

      if (status !== 200) {
        throw new Error(data)
      } else {
        return response
      }
    },
  },

  getAllDrivers: async () => {
    set((state) => ({ ...state, isLoading: true }))
    const response = await delivery_drivers_api.getAllDrivers()

    const { status, data } = response

    set((state) => ({ ...state, isLoading: false }))

    if (!status || !data) {
      throw new Error('No status code or data returned from server.')
    }

    set((state) => ({
      ...state,
      drivers: data.delivery_drivers,
    }))
    return response
  },
}))
