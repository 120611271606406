import DeliveryCountryTabel from '@/components/Tabels/DeliveryCountryTabel/DeliveryCountryTabel'
import DeliveryWrapper from '@/components/Wrappers/DeliveryWrapper'
import { Typography } from '@mui/material'

const DeliveryCountry = () => {
  return (
    <DeliveryWrapper>
      {true ? (
        <DeliveryCountryTabel />
      ) : (
        <Typography variant="h4" color="error">
          You don&apos;t have permission to view Country
        </Typography>
      )}
    </DeliveryWrapper>
  )
}

export default DeliveryCountry
