import RequestsTable from '@/components/Tabels/Requests/RequestsTable'
import TravelWrapper from '@/components/Wrappers/TravelWrapper'
import { Typography } from '@mui/material'
import React from 'react'

const Requests = () => {
  return (
    <TravelWrapper>
      {true ? (
        <RequestsTable />
      ) : (
        <Typography variant="h4" color="error">
          You don&apos;t have permission to view traders
        </Typography>
      )}
    </TravelWrapper>
  )
}

export default Requests
