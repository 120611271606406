import { api } from '@/api'

// ? routes prefixes
import { PREFIXES } from '@/routes/prefixes'

// ? utils
import { apiCreator } from '@/utils/apiCreator'

const requestCreator = apiCreator(PREFIXES.DELIVERY_DRIVERS)

export const delivery_drivers_api = {
  getAllDrivers: async () => requestCreator(api, '', 'GET'),

  createDriver: async (body: { pib: string }) => requestCreator(api, '', 'POST', body),

  editDriver: async (
    id: string,
    body: {
      pib: string
    }
  ) => requestCreator(api, `${id}`, 'PUT', body),

  deleteDriver: async (id: string) => requestCreator(api, `${id}`, 'DELETE'),
}
