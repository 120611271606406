// ? store
import { townStore } from '@/store/townStore'

// ? components
import { TableRow, TableCell, Typography, Tooltip, IconButton } from '@mui/material'

// ? icons
import { Delete, Edit } from '@mui/icons-material'
import { IDepartment } from '@/interfaces/department'
import { departmentStore } from '@/store/departmentStore'

interface IProps {
  department: IDepartment
}

const DeliveryDepartmentTabelRow = ({ department }: IProps) => {
  const { editDepartment, deleteDepartment } = departmentStore()

  const handleEditTown = (town: IDepartment) => {
    editDepartment.handleOpenModal(town)
  }

  const handleDeleteTown = (town: IDepartment) => {
    deleteDepartment.handleOpenModal(town)
  }

  return (
    <TableRow hover>
      <TableCell align="left">
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{
            cursor: 'pointer',
          }}>
          {department.department_id}
        </Typography>
      </TableCell>
      <TableCell align="left">
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{
            cursor: 'pointer',
          }}>
          {`EN: ${department?.country_name_en}`}
          <br />
          {`UA: ${department?.country_name_ua}`}
        </Typography>
      </TableCell>
      <TableCell align="left">
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{
            cursor: 'pointer',
          }}>
          {department.city}
        </Typography>
      </TableCell>
      <TableCell align="left">
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{
            cursor: 'pointer',
          }}>
          {department.address}
        </Typography>
      </TableCell>
      <TableCell align="left">
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{
            cursor: 'pointer',
          }}>
          {department.work_schedule}
        </Typography>
      </TableCell>
      <TableCell align="left">
        <Tooltip title="Edit">
          <IconButton color="warning" onClick={() => handleEditTown(department)}>
            <Edit />
          </IconButton>
        </Tooltip>
        <Tooltip title="Delete">
          <IconButton color="error" onClick={() => handleDeleteTown(department)}>
            <Delete />
          </IconButton>
        </Tooltip>
      </TableCell>
    </TableRow>
  )
}

export default DeliveryDepartmentTabelRow
