import { useParams } from 'react-router-dom'
import { Grid } from '@mui/material'

import UserNavigation from '@/components/User/UserNavigation'
import DeliveryNavigation from '../UI/GlobalNavigation/DeliveryNavigation'

interface PageProps {
  children: JSX.Element | JSX.Element[]
}

const DeliveryWrapper = ({ children }: PageProps) => {
  const { accountNumber } = useParams()

  return (
    <Grid container spacing={1} sx={{ minHeight: '100vh', display: 'flex', flexDirection: 'column' }}>
      <Grid item xs={12}>
        <DeliveryNavigation />
      </Grid>
      <Grid item xs={12} sx={{ mx: '10px', width: 'calc(100% - 20px)' }}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            {accountNumber && <UserNavigation />}
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              height: '100%',
            }}>
            {children}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default DeliveryWrapper
