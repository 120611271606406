import React from 'react'
import { Typography } from '@mui/material'

import DeliveryRequestsTable from '@/components/Tabels/DeliveryRequests/DeliveryRequestsTable'
import DeliveryWrapper from '@/components/Wrappers/DeliveryWrapper'

const DeliveryRequests = () => {
  return (
    <DeliveryWrapper>
      {true ? (
        <DeliveryRequestsTable />
      ) : (
        <Typography variant="h4" color="error">
          You don&apos;t have permission to view traders
        </Typography>
      )}
    </DeliveryWrapper>
  )
}

export default DeliveryRequests
