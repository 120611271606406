import ModalContainer from '@/components/UI/Modal/ModalContainer'
import { PAYMENT_METHOD } from '@/constants/constants'
import { deliveryRequestsStore } from '@/store/deliveryRequestsStore'
import { departmentStore } from '@/store/departmentStore'
import { formatDate } from '@/utils/formatDate'
import { Divider, Grid, Typography } from '@mui/material'

export function PreviewRequestModal() {
  const { previewModal } = deliveryRequestsStore()
  const { departments } = departmentStore()

  const { deliveryRequest, handleClose, isOpen } = previewModal

  if (!deliveryRequest) {
    return null
  }

  const getOriginAddress = () => {
    if (deliveryRequest.origin_city && deliveryRequest.origin_address) {
      return `${deliveryRequest.origin_city}, ${deliveryRequest.origin_address}`
    }

    if (deliveryRequest.origin_department) {
      return `відділення ${
        departments.find((department) => department.department_id === deliveryRequest.origin_department)?.city
      }`
    }

    return deliveryRequest.ttn_number
  }

  const getDeliveryAddress = () => {
    if (deliveryRequest.delivery_city && deliveryRequest.delivery_address) {
      return `${deliveryRequest.delivery_city}, ${deliveryRequest.delivery_address}`
    }

    if (deliveryRequest.delivery_department) {
      return `відділення ${
        departments.find((department) => department.department_id === deliveryRequest.delivery_department)?.city
      }`
    }

    return deliveryRequest.ttn_number
  }

  return (
    <ModalContainer
      width={800}
      isOpen={isOpen}
      handleClose={handleClose}
      title={`Перегляд заявки №${deliveryRequest?.order_id}`}>
      {deliveryRequest && (
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 1,
            }}>
            <Typography variant="body2">{formatDate(deliveryRequest?.created_at, true)}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Typography variant="h6">Відправник:</Typography>
              </Grid>
              {deliveryRequest.sender_name && (
                <>
                  <Grid
                    item
                    xs={12}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: 1,
                    }}>
                    <Typography variant="subtitle1">Ім'я:</Typography>
                    {deliveryRequest.sender_name}
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: 1,
                    }}>
                    <Typography variant="subtitle1">Телефон:</Typography>
                    {deliveryRequest.sender_phone}
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: 1,
                    }}>
                    <Typography variant="subtitle1">Пошта:</Typography>
                    {deliveryRequest.sender_email}
                  </Grid>
                </>
              )}
              <Grid
                item
                xs={12}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1,
                }}>
                <Typography variant="subtitle1">Адреса відправлення:</Typography>
                {getOriginAddress()}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Typography variant="h6">Отримувач</Typography>
              </Grid>
              {deliveryRequest.receiver_name && (
                <>
                  <Grid
                    item
                    xs={12}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: 1,
                    }}>
                    <Typography variant="subtitle1">Ім'я:</Typography>
                    {deliveryRequest.receiver_name}
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: 1,
                    }}>
                    <Typography variant="subtitle1">Телефон:</Typography>
                    {deliveryRequest.receiver_phone}
                  </Grid>
                </>
              )}
              <Grid
                item
                xs={12}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1,
                }}>
                <Typography variant="subtitle1">Пошта:</Typography>
                {deliveryRequest.receiver_email}
              </Grid>
              <Grid
                item
                xs={12}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1,
                }}>
                <Typography variant="subtitle1">Адреса доставки:</Typography>
                {getDeliveryAddress()}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Typography variant="h6">Деталі відправлення</Typography>
              </Grid>
              <Grid
                item
                xs={6}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1,
                }}>
                <Typography variant="subtitle1">Спосіб оплати:</Typography>
                {PAYMENT_METHOD[deliveryRequest.payment_method!]}
              </Grid>
              <Grid
                item
                xs={6}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1,
                }}>
                <Typography variant="subtitle1">Сума доставки:</Typography>
                {deliveryRequest.price}
              </Grid>
              <Grid
                item
                xs={6}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1,
                }}>
                <Typography variant="subtitle1">Знижка:</Typography>
                {deliveryRequest.discount}
              </Grid>
              {deliveryRequest.insurance !== 'none' && (
                <>
                  <Grid
                    item
                    xs={6}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: 1,
                    }}>
                    <Typography variant="subtitle1">Страхування:</Typography>
                    {deliveryRequest.insurance}
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: 1,
                    }}>
                    <Typography variant="subtitle1">Сума страхування:</Typography>
                    {deliveryRequest.insurance_sum}
                  </Grid>
                </>
              )}
              <Grid
                item
                xs={6}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1,
                }}>
                <Typography variant="subtitle1">Промокод:</Typography>
                {deliveryRequest.promocode}
              </Grid>
              <Grid
                item
                xs={6}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1,
                }}>
                <Typography variant="subtitle1">Кількість:</Typography>
                {deliveryRequest.quantity}
              </Grid>
              <Grid
                item
                xs={6}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1,
                }}>
                <Typography variant="subtitle1">Вага:</Typography>
                {deliveryRequest.weight}
              </Grid>
              <Grid
                item
                xs={6}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1,
                }}>
                <Typography variant="subtitle1">Примітки:</Typography>
                {deliveryRequest.notes}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </ModalContainer>
  )
}
