import React from 'react'
import { useNavigate } from 'react-router-dom'
import dayjs from 'dayjs'
import { formatDate, formatDateAndTimeWithTimeZone } from '@/utils/formatDate'
import { AddTask, Close, Info } from '@mui/icons-material'
import { TableRow, TableCell, Typography, IconButton, Tooltip, useTheme } from '@mui/material'
import { ITripRequest } from '@/interfaces/tripRequests'
import { CONNECTION_TYPE, TRIP_REQUEST_STATUS } from '@/constants/constants'
import { tripRequestsStore } from '@/store/tripRequestsStore'

type ITripRequestProps = {
  tripRequest: ITripRequest
}

const RequestsTableRow = ({ tripRequest }: ITripRequestProps) => {
  const { setCurrentTripRequest } = tripRequestsStore()
  const navigate = useNavigate()
  const theme = useTheme()

  const { approveModal, declineModal } = tripRequestsStore()

  const handleApprove = (tripRequest: ITripRequest) => {
    approveModal.handleOpen(tripRequest)
  }

  const handleDecline = (tripRequest: ITripRequest) => {
    declineModal.handleOpen(tripRequest)
  }

  return (
    <TableRow hover>
      <TableCell align="left">
        <Tooltip placement="top" title={`Час відправлення за часом системи: ${dayjs.tz.guess()}`} arrow>
          <Typography
            variant="body2"
            color={theme.palette.info.main}
            sx={{
              cursor: 'pointer',
              minWidth: '170px',
            }}>
            {formatDate(tripRequest?.departureDate, true)}
          </Typography>
        </Tooltip>
        <Tooltip
          // @ts-ignore
          title={`Час відправлення за місцевим часом: ${tripRequest?.departurePlace} ${tripRequest?.fromLocationCity?.timeZone?.zoneName}`}
          arrow>
          <Typography
            variant="body2"
            color={theme.palette.success.main}
            sx={{
              cursor: 'pointer',
              minWidth: '170px',
            }}>
            {formatDate(tripRequest.departureDate)}
          </Typography>
        </Tooltip>
      </TableCell>
      <TableCell align="left">
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{
            cursor: 'pointer',
          }}>
          {tripRequest?.raceNumber}
        </Typography>
      </TableCell>
      <TableCell align="left">
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{
            cursor: 'pointer',
          }}>
          {tripRequest.fromLocationCity.city_name_ua} | {tripRequest?.departurePlace.bus_stop_name_ua} -{' '}
          {tripRequest.toLocationCity.city_name_ua} | {tripRequest?.arrivalPlace.bus_stop_name_ua}
        </Typography>
      </TableCell>
      <TableCell align="left">
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{
            cursor: 'pointer',
          }}>
          {tripRequest.pib}
        </Typography>
      </TableCell>
      <TableCell align="left">
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{
            cursor: 'pointer',
          }}>
          {tripRequest.phone}
        </Typography>
      </TableCell>
      <TableCell align="left">
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{
            cursor: 'pointer',
          }}>
          {CONNECTION_TYPE[tripRequest.connectionType]}
        </Typography>
      </TableCell>
      <TableCell align="left">
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{
            cursor: 'pointer',
          }}>
          {tripRequest.connectPhoneNumber}
        </Typography>
      </TableCell>
      <TableCell align="left">
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{
            cursor: 'pointer',
          }}>
          {tripRequest.baggageAmount}
        </Typography>
      </TableCell>
      <TableCell align="left">
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{
            cursor: 'pointer',
          }}>
          {tripRequest.adultPassengerAmount}
        </Typography>
      </TableCell>
      <TableCell align="left">
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{
            cursor: 'pointer',
          }}>
          {tripRequest.childPassengerAmount + tripRequest.childMiddlePassengerAmount}
        </Typography>
      </TableCell>
      <TableCell align="left">
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{
            cursor: 'pointer',
          }}>
          {tripRequest.note}
        </Typography>
      </TableCell>
      <TableCell align="left">
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{
            cursor: 'pointer',
          }}>
          {TRIP_REQUEST_STATUS[tripRequest.status]}
        </Typography>
      </TableCell>
      <TableCell align="left">
        {tripRequest.status === 'created' ? (
          <Tooltip title="Підтвердити">
            <IconButton color="success" onClick={() => handleApprove(tripRequest)}>
              <AddTask />
            </IconButton>
          </Tooltip>
        ) : null}
        <Tooltip title="Скасувати">
          <IconButton color="error" onClick={() => handleDecline(tripRequest)}>
            <Close />
          </IconButton>
        </Tooltip>
      </TableCell>
    </TableRow>
  )
}

export default RequestsTableRow
