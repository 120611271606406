import { Typography } from '@mui/material'
import TravelWrapper from '@/components/Wrappers/TravelWrapper'
import RaceTariffs from '@/components/Tabels/RaceTariffs/RaceTariffs'

const RaceTariffsPage = () => {
  return (
    <TravelWrapper>
      {true ? (
        <RaceTariffs />
      ) : (
        <Typography variant="h4" color="error">
          You don&apos;t have permission to view traders
        </Typography>
      )}
    </TravelWrapper>
  )
}

export default RaceTariffsPage
