import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Box, Button } from '@mui/material'
import { West } from '@mui/icons-material'

import TripItemCreateModal from '@/components/Tabels/TripItemRequests/modals/TripItemCreateModal'
import TripItemUsers from '@/components/Tabels/TripItemUsers/TripItemUsers'
import TravelWrapper from '@/components/Wrappers/TravelWrapper'
import { tripRequestsStore } from '@/store/tripRequestsStore'

const TripItemPage = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const { createModal } = tripRequestsStore()

  const handleAddTripRequest = () => {
    createModal.handleOpen()
  }

  return (
    <TravelWrapper>
      <Box sx={{ width: '100%', typography: 'body1' }}>
        {createModal.isOpen && <TripItemCreateModal />}
        <Box
          sx={{
            my: 1,
            display: 'flex',
            gap: '10px',
          }}>
          <Button
            variant="contained"
            color="primary"
            size="small"
            className="createUserBtn"
            startIcon={<West className="goBack" />}
            onClick={() => navigate(-1)}>
            {t('common.goBack')}
          </Button>
          <Button variant="contained" color="primary" size="small" onClick={handleAddTripRequest}>
            Додати бронювання
          </Button>
        </Box>
        <TripItemUsers />
      </Box>
    </TravelWrapper>
  )
}

export default TripItemPage
