// ? components
import { TableRow, TableCell, Typography, Tooltip, IconButton } from '@mui/material'

// ? icons
import { Delete, Edit } from '@mui/icons-material'
import { IDeliveryDriver } from '@/interfaces/dirvers'
import { deliveryDriverStore } from '@/store/deliveryDriverStore'

interface IProps {
  driver: IDeliveryDriver
}

const DeliveryDriverTabelRow = ({ driver }: IProps) => {
  const { pib, driver_id } = driver

  const { editDeliveryDriver, deleteDeliveryDriver } = deliveryDriverStore()

  const handleEditDriver = (driver: IDeliveryDriver) => {
    editDeliveryDriver.handleOpenModal(driver)
  }

  const handleDeleteCountry = (driver: IDeliveryDriver) => {
    deleteDeliveryDriver.handleOpenModal(driver)
  }

  return (
    <TableRow hover>
      <TableCell align="left">
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{
            cursor: 'pointer',
          }}>
          {driver_id}
        </Typography>
      </TableCell>
      <TableCell align="left">
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{
            cursor: 'pointer',
          }}>
          {pib}
        </Typography>
      </TableCell>
      <TableCell align="left">
        <Tooltip title="Відредагувати">
          <IconButton color="warning" onClick={() => handleEditDriver(driver)}>
            <Edit />
          </IconButton>
        </Tooltip>
        <Tooltip title="Видалити">
          <IconButton color="error" onClick={() => handleDeleteCountry(driver)}>
            <Delete />
          </IconButton>
        </Tooltip>
      </TableCell>
    </TableRow>
  )
}

export default DeliveryDriverTabelRow
