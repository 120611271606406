import { ITrip } from '@/interfaces/trip'

import { messageStore } from '@/store/store'
import { tripsStore } from '@/store/tripStore'

import {
  CheckCircleOutline,
  HighlightOff,
  Edit,
  Delete,
  Settings,
  DirectionsBus,
  Person,
  Groups,
} from '@mui/icons-material'
import { TableRow, TableCell, Typography, Box, Button } from '@mui/material'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import useAsyncWrapper from '@/hooks/useAsyncWrapper'
import { formatDate } from '@/utils/formatDate'

interface IProps {
  trip: ITrip
}

const RaceTripsTabelRow = ({ trip }: IProps) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false)

  const [open, setOpen] = useState(false)

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const openEditsMenu = Boolean(anchorEl)

  const { addMessage } = messageStore()

  const { getAllTrips, tripBusModal, tripDriversModal, editTrip, deleteTrip } = tripsStore()

  const editTripApiHandler = useAsyncWrapper(editTrip.editTripApiHandler, 'editTripApiHandler')

  const handleEditTripStatus = async (status: string) => {
    setIsLoading(true)

    const data = {
      editType: 'editMainTripStatus',
      status: status,
    }

    const { error: editTripBusError } = await editTripApiHandler([trip?.trip_id, data])

    if (editTripBusError) {
      setIsLoading(false)
      return
    }

    const { error: getAllTripsError } = await getAllTrips()

    if (getAllTripsError) {
      console.log(getAllTripsError)
    }

    setIsLoading(false)

    addMessage({
      severity: 'success',
      message: 'Поїздку успішно відредаговано',
      isOpen: true,
    })
  }

  const handleEditsMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleRaceTripSchedule = (trip: ITrip) => {
    navigate(`/travel/${trip?.trip_id}/schedule/`)
  }

  const handleRaceTripTariffs = (trip: ITrip) => {
    navigate(`/travel/trips/${trip?.trip_id}/tariffs/`)
  }

  const handleRaceTripSalesControl = (trip: ITrip) => {
    navigate(`/travel/trips/${trip?.trip_id}/sales-control`)
  }

  const handleOpenTransportInfo = (trip: ITrip) => {
    tripBusModal.handleOpenModal(trip)
  }

  const handleOpenDriverInfo = (trip: ITrip) => {
    tripDriversModal.handleOpenModal(trip)
  }

  const handleToRequests = (trip: ITrip) => {
    navigate(`/travel/${trip.trip_id}/requests`)
  }

  const handleDeleteTrip = (trip: ITrip) => {
    deleteTrip.handleOpenModal(trip)
  }

  interface ITransportTypes {
    microBus: string
    bus: string
    nodata: string

    [key: string]: any
  }

  return (
    <TableRow hover onClick={() => setOpen(!open)}>
      <TableCell align="left">
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{
            cursor: 'pointer',
          }}>
          {formatDate(trip?.departure_date)}
        </Typography>
      </TableCell>
      <TableCell align="left">
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{
            cursor: 'pointer',
          }}>
          {trip?.day}
        </Typography>
      </TableCell>
      <TableCell align="left">
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{
            cursor: 'pointer',
          }}>
          {trip?.departure_full_name}
        </Typography>
      </TableCell>
      <TableCell align="left">
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{
            cursor: 'pointer',
          }}>
          {trip?.destination_full_name}
        </Typography>
      </TableCell>
      <TableCell align="left">
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{
            cursor: 'pointer',
          }}>
          {trip?.car_name}
        </Typography>
      </TableCell>
      <TableCell align="left">
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{
            cursor: 'pointer',
          }}>
          {trip?.seats_count}
        </Typography>
      </TableCell>
      <TableCell align="left">
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{
            width: '160px',
            cursor: 'pointer',
            fontWeight: 'bold',
          }}>
          {trip?.occupied_seats}
        </Typography>
      </TableCell>
      <TableCell style={{ paddingBottom: 0, paddingTop: 0 }}>
        <Box sx={{ display: 'flex', gap: '10px' }}>
          {/* {trip?.status === 1 ? (
            <Button
              variant="contained"
              color="primary"
              size="small"
              className="cancelBtn"
              startIcon={<HighlightOff className="cancelBtn" />}
              onClick={() => handleEditTripStatus('inactive')}>
              {t('raceTripsPage.raceTripMenu.cancel')}
            </Button>
          ) : (
            <Button
              variant="contained"
              color="primary"
              size="small"
              className="activateBtn"
              startIcon={<CheckCircleOutline className="activateBtn" />}
              onClick={() => handleEditTripStatus('active')}>
              {t('raceTripsPage.raceTripMenu.activate')}
            </Button>
          )} */}
          <Button
            id="edits-button"
            variant="contained"
            color="primary"
            size="small"
            className="editsBtn"
            startIcon={<Edit className="editIcon" />}
            aria-controls={openEditsMenu ? 'edits-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={openEditsMenu ? 'true' : undefined}
            onClick={handleEditsMenuClick}>
            {t('raceTripsPage.raceTripMenu.edit')}
          </Button>
          <Button
            variant="contained"
            color="primary"
            size="small"
            className="scheduleBtn"
            startIcon={<Settings className="scheduleBtn" />}
            onClick={() => handleRaceTripSchedule(trip)}>
            {t('raceTripsPage.raceTripMenu.editsMenu.schedule')}
          </Button>
          <Button
            variant="contained"
            color="primary"
            size="small"
            className="tariffsBtn"
            startIcon={<Settings className="tariffsBtn" />}
            onClick={() => handleRaceTripTariffs(trip)}>
            {t('raceTripsPage.raceTripMenu.editsMenu.tariffs')}
          </Button>
          {trip?.status === 1 && (
            <Button
              variant="contained"
              color="primary"
              size="small"
              className="salesControlBtn"
              startIcon={<Settings className="salesControlBtn" />}
              onClick={() => handleRaceTripSalesControl(trip)}>
              {t('raceTripsPage.raceTripMenu.salesControl')}
            </Button>
          )}
          <Button
            variant="contained"
            color="primary"
            size="small"
            className="busBtn"
            startIcon={<DirectionsBus className="bus" />}
            onClick={() => handleOpenTransportInfo(trip)}>
            {t('raceTripsPage.raceTripMenu.bus')}
          </Button>
          <Button
            variant="contained"
            color="primary"
            size="small"
            className="driversBtn"
            startIcon={<Person className="person" />}
            onClick={() => handleOpenDriverInfo(trip)}>
            {t('raceTripsPage.raceTripMenu.drivers')}
          </Button>
          <Button
            variant="contained"
            color="primary"
            size="small"
            className="passengersListBtn"
            startIcon={<Groups className="groups" />}
            onClick={() => handleToRequests(trip)}>
            {t('raceTripsPage.raceTripMenu.passengersList')}
          </Button>
          <Button
            variant="contained"
            color="primary"
            size="small"
            className="deleteTripBtn"
            startIcon={<Delete className="deleteIcon" />}
            onClick={() => handleDeleteTrip(trip)}>
            {t('raceTripsPage.raceTripMenu.delete')}
          </Button>
        </Box>
      </TableCell>
    </TableRow>
  )
}

export default RaceTripsTabelRow
