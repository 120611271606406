import { create } from 'zustand'

import { api, REQ } from '@/api'
import { IMeta } from '@/interfaces/general'
import { CreateDeliveryDTO, DeliveryType, IDeliveryRequest } from '@/interfaces/deliveryRequests'

const { deliveryRequests_api } = REQ

interface IStore {
  isLoading: boolean
  deliveryRequests: IDeliveryRequest[]
  deliveryRequestsMeta: IMeta
  searchParams: Partial<IDeliveryRequest>
  setSearchParams: (searchParams: Partial<IDeliveryRequest>) => void
  getDeliveryRequests: ({
    origin_city,
    delivery_type,
    sender_phone,
    status,
  }: {
    origin_city?: string
    delivery_type?: DeliveryType
    sender_phone?: string
    status?: string
  }) => Promise<any>
  editModal: {
    isOpen: boolean
    deliveryRequest: IDeliveryRequest | null
    handleOpen: (deliveryRequest: IDeliveryRequest) => void
    handleClose: () => void
    editDeliveryRequest: (id: number, changes: Partial<IDeliveryRequest>) => Promise<any>
  }
  approveModal: {
    isOpen: boolean
    deliveryRequest: IDeliveryRequest | null
    handleOpen: (deliveryRequest: IDeliveryRequest) => void
    handleClose: () => void
  }
  declineModal: {
    isOpen: boolean
    deliveryRequest: IDeliveryRequest | null
    handleOpen: (deliveryRequest: IDeliveryRequest) => void
    handleClose: () => void
  }
  createModal: {
    isOpen: boolean
    handleOpen: () => void
    handleClose: () => void
    createDeliveryRequest: (data: CreateDeliveryDTO) => Promise<any>
  }
  previewModal: {
    isOpen: boolean
    deliveryRequest: IDeliveryRequest | null
    handleOpen: (deliveryRequest: IDeliveryRequest) => void
    handleClose: () => void
  }
}

export const deliveryRequestsStore = create<IStore>((set) => ({
  deliveryRequests: [],
  isLoading: false,
  deliveryRequestsMeta: {
    count: 50,
    page: 1,
    total: 0,
  },
  searchParams: {},
  setSearchParams: (searchParams) => {
    set((state) => ({ ...state, searchParams }))
  },
  pdfBlob: null,
  editModal: {
    isOpen: false,
    deliveryRequest: null,
    handleOpen: (deliveryRequest) => {
      set((state) => ({
        ...state,
        editModal: {
          ...state.editModal,
          isOpen: true,
          deliveryRequest,
        },
      }))
    },
    handleClose: () => {
      set((state) => ({
        ...state,
        editModal: {
          ...state.editModal,
          isOpen: false,
          deliveryRequest: null,
        },
      }))
    },
    editDeliveryRequest: async (id, changes) => {
      const response = await deliveryRequests_api.editDeliveryRequest(id, changes)

      const { status, data } = response

      if (!status || !data) {
        throw new Error('No status code or data returned from server.')
      }

      if (status !== 200) {
        throw new Error(data)
      } else {
        return response
      }
    },
  },

  approveModal: {
    isOpen: false,
    deliveryRequest: null,
    handleOpen: (deliveryRequest) => {
      set((state) => ({
        ...state,
        approveModal: {
          ...state.approveModal,
          isOpen: true,
          deliveryRequest: deliveryRequest,
        },
      }))
    },
    handleClose: () => {
      set((state) => ({
        ...state,
        approveModal: {
          ...state.approveModal,
          isOpen: false,
          deliveryRequest: null,
        },
      }))
    },
  },

  declineModal: {
    isOpen: false,
    deliveryRequest: null,
    handleOpen: (deliveryRequest) => {
      set((state) => ({
        ...state,
        declineModal: {
          ...state.declineModal,
          isOpen: true,
          deliveryRequest,
        },
      }))
    },
    handleClose: () => {
      set((state) => ({
        ...state,
        declineModal: {
          ...state.declineModal,
          isOpen: false,
          deliveryRequest: null,
        },
      }))
    },
  },

  createModal: {
    isOpen: false,
    handleOpen: () => {
      set((state) => ({
        ...state,
        createModal: {
          ...state.createModal,
          isOpen: true,
        },
      }))
    },
    handleClose: () => {
      set((state) => ({
        ...state,
        createModal: {
          ...state.createModal,
          isOpen: false,
        },
      }))
    },
    createDeliveryRequest: async (data) => {
      return await deliveryRequests_api.createDeliveryRequest(data)
    },
  },
  getDeliveryRequests: async ({
    origin_city,
    delivery_type,
    sender_phone,
    status,
  }: { origin_city?: string; delivery_type?: DeliveryType; sender_phone?: string; status?: string } = {}) => {
    set((state) => ({ ...state, isLoading: true }))
    const response = await deliveryRequests_api.getDeliveryRequests({
      origin_city,
      delivery_type,
      sender_phone,
      status,
    })

    set((state) => ({ ...state, isLoading: false }))

    const { status: deliveryStatus, data } = response

    if (!deliveryStatus || !data) {
      throw new Error('No status code or data returned from server.')
    }

    if (deliveryStatus !== 200) {
      throw new Error(data)
    } else {
      set((state) => ({
        ...state,
        deliveryRequests: data.orders,
      }))
      return response
    }
  },
  previewModal: {
    isOpen: false,
    deliveryRequest: null,
    handleOpen: (deliveryRequest) => {
      set((state) => ({
        ...state,
        previewModal: {
          ...state.previewModal,
          isOpen: true,
          deliveryRequest,
        },
      }))
    },
    handleClose: () => {
      set((state) => ({
        ...state,
        previewModal: {
          ...state.previewModal,
          isOpen: false,
          deliveryRequest: null,
        },
      }))
    },
  },
}))
