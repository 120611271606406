// ? interfaces
import { IDeliveryCountry } from '@/interfaces/country'

// ? components
import { TableRow, TableCell, Typography, Tooltip, IconButton } from '@mui/material'

// ? icons
import { Delete, Edit } from '@mui/icons-material'
import { deliveryCountryStore } from '@/store/deliveryCountryStore'

interface IProps {
  country: IDeliveryCountry
}

const DeliveryCountryTabelRow = ({ country }: IProps) => {
  const { country_id, country_name_en, country_name_ua } = country

  const { editDeliveryCountry, deleteDeliveryCountry } = deliveryCountryStore()

  const handleEditCountry = (country: IDeliveryCountry) => {
    editDeliveryCountry.handleOpenModal(country)
  }

  const handleDeleteCountry = (country: IDeliveryCountry) => {
    deleteDeliveryCountry.handleOpenModal(country)
  }

  return (
    <TableRow hover>
      <TableCell align="left">
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{
            cursor: 'pointer',
          }}>
          {country_id}
        </Typography>
      </TableCell>
      <TableCell align="left">
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{
            cursor: 'pointer',
          }}>
          {`EN: ${country_name_en}`}
        </Typography>
      </TableCell>
      <TableCell align="left">
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{
            cursor: 'pointer',
          }}>
          {`UA: ${country_name_ua}`}
        </Typography>
      </TableCell>
      <TableCell align="left">
        <Tooltip title="Відредагувати">
          <IconButton color="warning" onClick={() => handleEditCountry(country)}>
            <Edit />
          </IconButton>
        </Tooltip>
        <Tooltip title="Видалити">
          <IconButton color="error" onClick={() => handleDeleteCountry(country)}>
            <Delete />
          </IconButton>
        </Tooltip>
      </TableCell>
    </TableRow>
  )
}

export default DeliveryCountryTabelRow
