import React from 'react'
import ArticlesTabel from '@/components/Tabels/ArticlesTabel/ArticlesTabel'
import TravelWrapper from '@/components/Wrappers/TravelWrapper'

const Blog = () => {
  return (
    <TravelWrapper>
      <ArticlesTabel />
    </TravelWrapper>
  )
}

export default Blog
