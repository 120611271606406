import StopsTabel from '@/components/Tabels/StopsTabel/StopsTabel'
import TravelWrapper from '@/components/Wrappers/TravelWrapper'
import { Typography } from '@mui/material'

const Stops = () => {
  return (
    <TravelWrapper>
      {true ? (
        <StopsTabel />
      ) : (
        <Typography variant="h4" color="error">
          You don&apos;t have permission to view Stops
        </Typography>
      )}
    </TravelWrapper>
  )
}

export default Stops
