import { Divider, FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material'
import { CreateDeliveryFormValues } from './CreateDeliveryRequest'

export function DeliveryRequestToAbroad({
  formErrors,
  formValues,
  inputChangeHandler,
  selectChangeHandler,
  selectedRoute,
}: {
  formErrors: any
  formValues: CreateDeliveryFormValues
  inputChangeHandler: any
  selectChangeHandler: any
  selectedRoute: any
}) {
  return (
    <>
      <Grid item xs={12}>
        <FormControl error={!!formErrors?.origin_type} fullWidth variant="standard">
          <InputLabel>Спосіб відправки</InputLabel>
          <Select
            value={formValues.origin_type}
            onChange={selectChangeHandler}
            name="origin_type"
            label="Спосіб відправки">
            {selectedRoute?.origin_name_ua === 'Україна' && (
              <MenuItem key="nova_poshta" value="nova_poshta">
                Нова Пошта
              </MenuItem>
            )}
            {selectedRoute?.sender_types.includes('address') && (
              <MenuItem key="address" value="address">
                Адреса
              </MenuItem>
            )}
            {selectedRoute?.sender_departments.map((department: any) => {
              return (
                <MenuItem key={department.department_id} value={department.department_id}>
                  відділення {department.city}
                </MenuItem>
              )
            })}
          </Select>
          {formErrors?.origin_type && <FormHelperText>{formErrors?.origin_type}</FormHelperText>}
        </FormControl>
      </Grid>
      {formValues.origin_type === 'address' && (
        <>
          <Grid item xs={4}>
            <FormControl fullWidth variant="standard">
              <InputLabel shrink>Місто відправлення</InputLabel>
              <TextField
                name="origin_city"
                onChange={inputChangeHandler}
                fullWidth
                value={formValues.origin_city}
                variant="standard"
                sx={{
                  mt: '16px',
                }}
                error={!!formErrors?.origin_city}
                helperText={formErrors?.origin_city}
              />
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <FormControl fullWidth variant="standard">
              <InputLabel shrink>Вулиця відправлення</InputLabel>
              <TextField
                name="origin_address"
                onChange={inputChangeHandler}
                fullWidth
                value={formValues.origin_address}
                variant="standard"
                sx={{
                  mt: '16px',
                }}
                error={!!formErrors?.origin_address}
                helperText={formErrors?.origin_address}
              />
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <FormControl fullWidth variant="standard">
              <InputLabel shrink>Поштовий індекс відправлення</InputLabel>
              <TextField
                name="origin_zip"
                onChange={inputChangeHandler}
                fullWidth
                value={formValues.origin_zip_code}
                variant="standard"
                sx={{
                  mt: '16px',
                }}
                error={!!formErrors?.origin_zip}
                helperText={formErrors?.origin_zip}
              />
            </FormControl>
          </Grid>
        </>
      )}
      {formValues.origin_type === 'nova_poshta' && (
        <Grid item xs={12}>
          <FormControl fullWidth variant="standard">
            <InputLabel shrink>Трекінг-номер</InputLabel>
            <TextField
              name="ttn_number"
              onChange={inputChangeHandler}
              fullWidth
              value={formValues.ttn_number}
              variant="standard"
              sx={{
                mt: '16px',
              }}
              error={!!formErrors?.ttn_number}
              helperText={formErrors?.ttn_number}
            />
          </FormControl>
        </Grid>
      )}
      <Grid item xs={12}>
        <Divider
          sx={{
            my: 2,
          }}
        />
      </Grid>
      <Grid item xs={12}>
        {
          <FormControl error={!!formErrors?.destination_type} fullWidth variant="standard">
            <InputLabel>Спосіб доставки</InputLabel>
            <Select
              value={formValues.destination_type}
              onChange={selectChangeHandler}
              name="destination_type"
              label="Спосіб доставки">
              {selectedRoute?.destination_name_ua === 'Україна' && (
                <MenuItem key="nova_poshta" value="nova_poshta">
                  Нова Пошта
                </MenuItem>
              )}
              {selectedRoute?.delivery_types.includes('address') && (
                <MenuItem key="address" value="address">
                  Адреса
                </MenuItem>
              )}
              {selectedRoute?.delivery_departments.map((department: any) => {
                return (
                  <MenuItem key={department.department_id} value={department.department_id}>
                    відділення {department.city}
                  </MenuItem>
                )
              })}
            </Select>
            {formErrors?.destination_type && <FormHelperText>{formErrors?.destination_type}</FormHelperText>}
          </FormControl>
        }
      </Grid>
      {formValues.destination_type === 'address' && (
        <>
          <Grid item xs={4}>
            <FormControl fullWidth variant="standard">
              <InputLabel shrink>Місто доставки</InputLabel>
              <TextField
                name="delivery_city"
                onChange={inputChangeHandler}
                fullWidth
                value={formValues.delivery_city}
                variant="standard"
                sx={{
                  mt: '16px',
                }}
                error={!!formErrors?.delivery_city}
                helperText={formErrors?.delivery_city}
              />
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <FormControl fullWidth variant="standard">
              <InputLabel shrink>Вулиця доставки</InputLabel>
              <TextField
                name="delivery_address"
                onChange={inputChangeHandler}
                fullWidth
                value={formValues.delivery_address}
                variant="standard"
                sx={{
                  mt: '16px',
                }}
                error={!!formErrors?.delivery_address}
                helperText={formErrors?.delivery_address}
              />
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <FormControl fullWidth variant="standard">
              <InputLabel shrink>Поштовий індекс доставки</InputLabel>
              <TextField
                name="delivery_zip_code"
                onChange={inputChangeHandler}
                fullWidth
                value={formValues.delivery_zip_code}
                variant="standard"
                sx={{
                  mt: '16px',
                }}
                error={!!formErrors?.delivery_zip_code}
                helperText={formErrors?.delivery_zip_code}
              />
            </FormControl>
          </Grid>
        </>
      )}
      {formValues.destination_type === 'nova_poshta' && (
        <Grid item xs={12}>
          <FormControl fullWidth variant="standard">
            <InputLabel shrink>Трекінг-номер</InputLabel>
            <TextField
              name="ttn_number"
              onChange={inputChangeHandler}
              fullWidth
              value={formValues.ttn_number}
              variant="standard"
              sx={{
                mt: '16px',
              }}
              error={!!formErrors?.ttn_number}
              helperText={formErrors?.ttn_number}
            />
          </FormControl>
        </Grid>
      )}
      <Grid item xs={12}>
        <Divider
          sx={{
            my: 2,
          }}
        />
      </Grid>
      <Grid item xs={4}>
        <FormControl fullWidth variant="standard">
          <InputLabel shrink>Ім'я отримувача</InputLabel>
          <TextField
            name="receiver_name"
            onChange={inputChangeHandler}
            fullWidth
            value={formValues.receiver_name}
            variant="standard"
            sx={{
              mt: '16px',
            }}
            error={!!formErrors?.receiver_name}
            helperText={formErrors?.receiver_name}
          />
        </FormControl>
      </Grid>
      <Grid item xs={4}>
        <FormControl fullWidth variant="standard">
          <InputLabel shrink>Телефон отримувача</InputLabel>
          <TextField
            name="receiver_phone"
            onChange={inputChangeHandler}
            fullWidth
            value={formValues.receiver_phone}
            variant="standard"
            sx={{
              mt: '16px',
            }}
            error={!!formErrors?.receiver_phone}
            helperText={formErrors?.receiver_phone}
          />
        </FormControl>
      </Grid>
      <Grid item xs={4}>
        <FormControl fullWidth variant="standard">
          <InputLabel shrink>Пошта отримувача</InputLabel>
          <TextField
            name="receiver_email"
            onChange={inputChangeHandler}
            fullWidth
            value={formValues.receiver_email}
            variant="standard"
            sx={{
              mt: '16px',
            }}
            error={!!formErrors?.receiver_email}
            helperText={formErrors?.receiver_email}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <Divider
          sx={{
            my: 2,
          }}
        />
      </Grid>
      <Grid item xs={6}>
        <FormControl fullWidth variant="standard">
          <InputLabel shrink>Кількість</InputLabel>
          <TextField
            name="quantity"
            onChange={inputChangeHandler}
            fullWidth
            value={formValues.quantity}
            variant="standard"
            sx={{
              mt: '16px',
            }}
            error={!!formErrors?.quantity}
            helperText={formErrors?.quantity}
          />
        </FormControl>
      </Grid>
      <Grid item xs={6}>
        <FormControl fullWidth variant="standard">
          <InputLabel shrink>Вага</InputLabel>
          <TextField
            name="weight"
            onChange={inputChangeHandler}
            fullWidth
            value={formValues.weight}
            variant="standard"
            sx={{
              mt: '16px',
            }}
            error={!!formErrors?.weight}
            helperText={formErrors?.weight}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <Divider
          sx={{
            my: 2,
          }}
        />
      </Grid>
      <Grid item xs={4}>
        <FormControl error={!!formErrors?.isInsurance} fullWidth variant="standard">
          <InputLabel shrink>Страхування</InputLabel>
          <Select value={formValues.isInsurance} onChange={selectChangeHandler} name="isInsurance" label="Страхування">
            <MenuItem value={0}>Ні</MenuItem>
            <MenuItem value={1}>Так</MenuItem>
          </Select>
          {formErrors?.isInsurance && <FormHelperText>{formErrors?.isInsurance}</FormHelperText>}
        </FormControl>
      </Grid>
      {formValues.isInsurance ? (
        <Grid item xs={4}>
          <FormControl fullWidth variant="standard">
            <InputLabel shrink>Сума страхування</InputLabel>
            <TextField
              name="insurance_sum"
              onChange={inputChangeHandler}
              fullWidth
              type="number"
              value={formValues.insurance_sum}
              variant="standard"
              sx={{
                mt: '16px',
              }}
              error={!!formErrors?.insurance_sum}
              helperText={formErrors?.insurance_sum}
            />
          </FormControl>
        </Grid>
      ) : null}
      <Grid item xs={4}>
        <FormControl error={!!formErrors?.payment_method} fullWidth variant="standard">
          <InputLabel shrink>Спосіб оплати</InputLabel>
          <Select
            value={formValues.payment_method}
            onChange={selectChangeHandler}
            name="payment_method"
            label="Спосіб оплати">
            <MenuItem value="uah_card">Карта гривні</MenuItem>
            <MenuItem value="eur_card">Карта євро</MenuItem>
            <MenuItem value="cash_on_arrival">Готівка при отриманні</MenuItem>
            <MenuItem value="card_on_arrival">Картою при отриманні</MenuItem>
          </Select>
          {formErrors?.payment_method && <FormHelperText>{formErrors?.payment_method}</FormHelperText>}
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <Divider
          sx={{
            my: 2,
          }}
        />
      </Grid>
      <Grid item xs={6}>
        <FormControl error={!!formErrors?.discount_type} fullWidth variant="standard">
          <InputLabel shrink>Тип знижки</InputLabel>
          <Select
            value={formValues.discount_type}
            onChange={selectChangeHandler}
            name="discount_type"
            label="Тип знижки">
            <MenuItem value="student">Студент</MenuItem>
            <MenuItem value="promocode">Промокод</MenuItem>
            <MenuItem value="none">Без знижки</MenuItem>
          </Select>
          {formErrors?.discount_type && <FormHelperText>{formErrors?.discount_type}</FormHelperText>}
        </FormControl>
      </Grid>
      {formValues.discount_type === 'promocode' && (
        <Grid item xs={6}>
          <FormControl fullWidth variant="standard">
            <InputLabel shrink>Промокод</InputLabel>
            <TextField
              name="promocode"
              onChange={inputChangeHandler}
              fullWidth
              value={formValues.promocode}
              variant="standard"
              sx={{
                mt: '16px',
              }}
              error={!!formErrors?.promocode}
              helperText={formErrors?.promocode}
            />
          </FormControl>
        </Grid>
      )}
      {formValues.discount_type === 'student' && (
        <Grid item xs={6}>
          <FormControl fullWidth variant="standard">
            <InputLabel shrink>ESIC</InputLabel>
            <TextField
              name="esic"
              onChange={inputChangeHandler}
              fullWidth
              value={formValues.esic}
              variant="standard"
              sx={{
                mt: '16px',
              }}
              error={!!formErrors?.esic}
              helperText={formErrors?.esic}
            />
          </FormControl>
        </Grid>
      )}
      <Grid item xs={12}>
        <FormControl fullWidth variant="standard">
          <InputLabel shrink>Примітки</InputLabel>
          <TextField
            name="notes"
            onChange={inputChangeHandler}
            fullWidth
            value={formValues.notes}
            variant="standard"
            sx={{
              mt: '16px',
            }}
            error={!!formErrors?.notes}
            helperText={formErrors?.notes}
          />
        </FormControl>
      </Grid>
    </>
  )
}
